.textInputContainer {
  position: relative;
  width: 100%;
  height: 50px;
  min-height: 50px;
}

.iconFile {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translate(0px, -50%);
  color: var(--gray-600);
}

.input {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0px 1px;
  border: 1px solid #afafaf;
  border-radius: 12px;
  outline: none;
  font-size: 0.875rem;
  transition: 0.2s;
  color: var(--gray-700);
}

.input[type="file"] {
  padding: 0px 45px;
  padding-top: 15px;
}

.input:focus {
  border: 1px solid var(--green-700);
}

.input::placeholder {
  font-size: 0.875rem;
  color: var(--gray-400);
}

.label {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translate(0px, -50%);
  font-size: 1rem;
  color: var(--gray-700);
  pointer-events: none;
  transition: 0.2s;
  font-weight: semi-bold;
  line-height: 0.9rem;
}

.input:valid ~ .label {
  transform: translate(0px, -32px);
  font-size: 0.875rem;
  font-weight: 600;
  background: #ffffff;
}

.input:focus ~ .label {
  transform: translate(0px, -32px);
  color: var(--green-700);
  font-size: 1rem;
  font-weight: 600;
  background: #ffffff;
}

.input:placeholder-shown ~ .label {
  transform: translate(0px, -32px);
  font-size: 0.875rem;
  font-weight: 600;
  background: #ffffff;
}

.labelAbsolute {
  position: absolute;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--gray-700);
  top: -11px;
  left: 16px;
  background: #fff;
  transition: 0.2s;
}

.input:valid ~ .labelAbsolute {
  font-size: 0.875rem;
  font-weight: 600;
}

.input:focus ~ .labelAbsolute {
  color: var(--green-700);
  font-size: 1rem;
  font-weight: 600;
}

.input:placeholder-shown ~ .labelAbsolute {
  font-size: 0.875rem;
  font-weight: 600;
  background: #ffffff;
}

.textError {
  display: inline-block;
  text-align: left;
  margin-left: 1px;
  margin-top: 0px;
  font-size: 0.65rem;
  color: var(--red-900);
}

.inputError ~ .label {
  color: var(--red-900);
}

.container_error {
  display: flex;
  align-items: right;
  justify-content: left;
  padding-left: 2px;
}

.inputError {
  border: 1px solid red;
}

.viewIcon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0px, -50%);
  cursor: pointer;
  color: var(--gray-500);
  line-height: 0;
}

.viewIcon:hover {
  color: #000;
}
.textInputContainer {
  position: relative;
  /* ... other styles ... */
}

.inputButton {
  position: absolute;
  right: 10px; /* adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--serodonto-primary-color-green-900, #00523d) !important;
  border-radius: 56px;
  background: var(--serodonto-primary-color-green-100, #ade1da);
  display: flex;
  height: 32px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  cursor: pointer;
}
.input {
  padding-left: 16px;
  padding-right: 16px;
  /* Adjust this value based on your icon's width + the space you want */
  /* ... other styles ... */
}

.inputIcon {
  position: absolute;
  left: 13px;
  top: 52%;
  transform: translateY(-50%);
  z-index: 2; /* Ensure it appears on top */
}

.label,
.labelAbsolute {
  z-index: 1; /* Ensure the label appears beneath the icon */
}
