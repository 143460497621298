.containerStep2 {
  display: flex;
  width: 100%;
  justify-content: center;
}

.headerStep2 {
  display: flex;
  height: 5vh;
  gap: 15px;
}

.borderStyle {
  width: 100%;
  height: 1px;
  background: var(--gray-200);
}

.containerStep2 input {
  background: none;
}

.containerInputs {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.containerInputs > .wrapperInput {
  display: grid;
  grid-template-columns: 200px 200px;
  gap: 8px;
}

.formStep2 {
  position: absolute;
  margin-top: 60px;
  width: 88vh;
  height: auto;
}

.containerUserSenha {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.container_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  padding-bottom: 24px;
}

.buttonCancelar {
  width: 172px;
  height: 52px;
}

.buttonCancelar > button {
  width: 100%;
  height: 100%;
  color: #007e69;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #007e69;
}

.buttonCancelar > button:hover {
  color: #fff;
  border: 1px solid #fff;
  background-color: #007e69 !important;
}

.buttonCancelar > button:hover svg {
  color: #fff;
}

.buttonCancelar > button svg {
  width: 24px;
  height: 24px;
  color: #007e69;
  padding-top: 4px;
}

.buttonContinuar {
  width: 172px;
  height: 52px;
}

.buttonContinuar > button {
  width: 100%;
  height: 100%;
  color: #fff;
}

.buttonContinuar > button svg {
  width: 24px;
  height: 24px;
  padding-top: 4px;
}

@media (max-width: 1440px) {
  .formStep2 {
    margin-top: 7%;
  }
}

@media (max-width: 880px) {
  .iconClose {
    top: 0.5rem;
    right: 0.5rem;
    max-width: 25px;
    cursor: pointer;
    color: var(--gray-500);
  }

  .headerForm svg {
    display: none;
  }

  .headerStep2 {
    gap: 5px;
  }

  .headerStep2 > div {
    width: auto;
  }

  .headerStep2 > div p {
    font-size: 0.625rem;
    width: 100px;
  }

  .formStep2 {
    position: absolute;
    margin-top: 60px;
    width: 100%;
    height: 60vh;
    padding: 0 10px;
  }

  .formStep2 p {
    font-size: 0.875rem;
  }

  .containerInputs {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 10px;
  }

  .containerInputs > .wrapperInput {
    display: flex;
    gap: 8px;
  }

  .container_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 60px; */
    padding-bottom: 24px;
  }

  .container_buttons_loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .disabled {
    background-color: #afafaf !important;
  }

  .buttonCancelar,
  .buttonContinuar {
    /* width: 65px; */
    max-width: 135px;
    width: 100%;
    height: 62px;
  }

  .buttonCancelar > button,
  .buttonContinuar > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px !important;
  }

  .buttonCancelar > button svg {
    display: block;
    width: 16px;
    /* margin: -10px; */
  }

  .buttonContinuar > button svg {
    display: none;
  }

  .buttonCancelar > button span,
  .buttonContinuar > button span {
    /* display: none; */
  }
}
