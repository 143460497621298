.wrapperAcademy {
  min-height: calc(100vh - 210px);
  margin-bottom: 2rem;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerText {
  padding-top: 30px;
  color: var(--neutral-colors-gray-river-gray-river-800, #37404e);

  font-size: 2.25rem;
  font-weight: 700;
  line-height: normal;
}

.containerSubText {
  color: var(--neutral-colors-gray-river-gray-river-800, #37404e);
  text-align: center;
  padding-top: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

.buttonMore {
  align-items: center;
  background-color: #ebeef3;
  border: none;
}
.buttonMore:hover {
  border: 1px solid #007e69;
}

.containerAulas {
  padding-top: 22px;
  padding-right: 16px;
}
.containerAula {
  border: 1px solid var(--gray-200);
  border-radius: 10px;

  display: grid;
  grid-row: inherit;

  gap: 10px;
}

.title {
  display: flex;
  align-items: center;
/*   gap: 9px; */
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: space-between;
}

.titleNav {
  font-size: 1.5rem;
  font-weight: 600;
  padding-right: 40px;
}
.buttonHamburguer {
  cursor: pointer;
}

.ContainerButton {
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.ContainerButton > button {
  font-size: 1rem;
  min-width: 163px;
  max-height: 40px;
  padding: 0;
}

.containerButtons {
  display: flex;
  flex-direction: row;
  padding: 5px;
  gap: 20px;
}

.containerButtons > button {
  font-size: 1rem;
  min-width: 200px;
  max-height: 40px;
  padding: 0;
}
.timeBadge {
  position: absolute;
  top: 0;
  right: 0.7%;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  gap: 3px;
  justify-content: center;
  color: #008a00;
  font-weight: 600;
  border-radius: 0px 12px;
  background: var(--support-colors-sucess-green-100, #bdecc4);
}
.containerTitles {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 10px;
}

.containerTitle {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 20px; /* 111.111% */
}
.containerSubTitle {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  padding-bottom: 15px;
}
.containerSubTitleTeacher {
  padding-top: 10px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  padding-bottom: 15px;
}
.containerImg {
  /* padding-top: 1.25rem; */
  padding-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px 12px 0px 0px;
  /* width: 340px;
  height: 191px; */
}
.containercontentImg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.allContent {
  padding-left: 20px;
}

.TitleProgress {
  color: #000;
  font-family: Segoe UI;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.ContentProgress {
  align-items: center;
  justify-content: inherit;
  padding: 10px 0px;
  position: relative;

  display: flex;
  gap: 6px;
  flex-direction: row;
}

.TextGreen {
  color: var(--serodonto-primary-color-main-green-700, #007e69);

  font-size: 1rem;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
}
a {
  text-decoration: none;
}

.containerAula {
  width: 500px;
  gap: 10px;
}

.containerContentInfo {
  padding: 10px;
}

.containerAutherBox {
  padding-bottom: 10px;
  display: flex;
  gap: 8px;
}

.containerAutherText {
  color: #8e8e8e;

  font-size: 14px;
}
.profileContainer {
  display: flex;

  align-items: center;
  gap: 10px;
}

.ContainerMobile {
  display: none;
}
@media (max-width: 1730px) {
  .containerAula {
    width: 100%;
    /*width: 400px;*/
    gap: 10px;
  }
}
.noninteractive {
  opacity: 0.5;
}

@media (max-width: 950px) {
  .wrapperAcademy {
    /* height: calc(100vh - 150px); */
    margin-top: 80px;
  }

  .containerSubTitle {
    display: none !important;
  }
  .containerTitle {
    font-size: 1.25rem;
    padding-bottom: 10px;
  }

}

@media (max-width: 500px) {
  .ContainerMobile {
    display: block;
  }
  .ContainerPadding {
    display: none;
  }
}

/* @media only screen and (max-width: 1880px) and (min-width: 1790px){
  .timeBadge{
    margin-right: -379px !important;
  }

}

@media only screen and (max-width: 1789px) and (min-width: 1731px){
  .timeBadge{
    margin-right: -379px !important;
  }
  .swiper-wrapper{
    gap: 30px;
  }

} */

@media screen and (max-width: 1730px) {
  .timeBadge{
    right: 0;
  }
  
}