.containerSelectedImagens {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 88px);
  max-height: calc(100vh - 88px);
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px 10px;
  overflow: hidden;
}

/* .containerSelectedImagens::-webkit-scrollbar {
  width: 5px;
  background: #353535;
  padding-left: 4px;
  border-radius: 8px;
}

.containerSelectedImagens::-webkit-scrollbar-thumb {
  background-color: #2ebbaa;
  border-radius: 2px;
} */

.containerButtonleft {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 10px;
  top: calc(50% - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: #2ebbaa;
  border-radius: 100%;
  cursor: pointer;
  border: 0;
  outline: none;
}

.containerButtonleft svg {
  color: white;
  width: 20px;
}

.containerButtonleft:hover {
  background-color: #2e9b86;
}

.containerButtonRight {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 350px;
  top: calc(50% - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: #2ebbaa;
  border-radius: 100%;
  cursor: pointer;
  border: 0;
  outline: none;
}

.containerButtonRight svg {
  color: white;
  width: 20px;
}

.containerButtonRight:hover {
  background-color: #2e9b86;
}

.containerSelectedImagens img {
  width: calc(33.33% - 15px);
  height: 100%;
  max-width: 100%;
  max-height: 600px;
  object-fit: cover;
}

.singleImage {
  display: grid;
  grid-template-columns: 1fr;
  min-height: calc(100vh - 88px);
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.singleImage img {
  display: block;
  width: 100%;
  height: 100vh !important;
  object-fit: contain;
}

.container3d {
  position: absolute;
  left: -150px;
  width: 100%;
  height: calc(100vh - 108px) !important;
}

.container3d span {
  animation: loading 3s infinite linear;
}

@keyframes loading {
  0% {
    color: #fff;
  }
  50% {
    color: #000;
  }
  100% {
    color: #fff;
  }
}

.imageSelectedToView {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.containerImageRotate {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden !important;
}

.containerImageRotate > canvas {
  position: relative;
  top: 2% !important;
}
@media (max-width: 1280px) {
  .container3d {
    left: 0px;
  }
  .containerButtonRight {
    display: none !important;
  }
  .containerButtonleft {
    display: none !important;
  }
}
