.containerImage {
  //width: calc(100vw - 305px);
  width: auto;
  display: flex;
  position: relative;
  height: calc(100vh - 90px);
  padding: 10px 2px;
  overflow-x: hidden;
}

.canvas {
  width: 100%;
  height: auto;
}

.spanLoading {
  position: absolute;
  color: #d3d3d3;
  text-align: center;
  fontsize: 1.1rem;
  left: 50%;
  transform: translateX(-50%);
}

.information {
  position: absolute;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
  z-index: 2;
  padding: 12px 20px;
  border-radius: 12px;
  border: 1px solid #535353;
  background: #353535;
  box-shadow: 0px 2px 12px 0px #000;
  font-size: 0.75rem;
  font-weight: 600;
}

.textInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.textInfo svg {
  width: 20px;
  height: 20px;
}

.textInfo img {
  width: 20px;
  height: 20px;
}

.fadeEnter {
  opacity: 0;
}

.fadeEnterActive {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fadeExit {
  opacity: 1;
}

.fadeExitActive {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
