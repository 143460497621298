.mainContainer {
  padding: 0 1rem;
}

.userCard {
  border-radius: 24px;
  border: 1px solid #e4e4e4;
  background: #fff;
  padding: 1.5rem;
}
.cardRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profilePic {
  border-radius: 80px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.subTitle {
  color: var(
    --neutral-colors-gray-river-gray-river-500,
    var(--neutral-colors-gray-river-gray-river-500, #64748b)
  );
  leading-trim: both;
  text-edge: cap;
  /* Desktop/Segoe UI/12 px/Regular */
  font-family: Segoe UI Variable;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.name {
  color: var(
    --neutral-colors-gray-river-gray-river-900,
    var(--neutral-colors-gray-river-gray-river-900, #252c37)
  );
  leading-trim: both;
  text-edge: cap;
  /* Desktop/Segoe UI/18 px/Semibold */
  font-family: Segoe UI Variable;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text {
  padding: 10px;
  color: var(
    --neutral-colors-gray-gray-600,
    var(--neutral-colors-gray-gray-600, #666)
  );
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.15px;
}
.rating {
  font-size: 0.8rem;
}
.profileDetailsContainer {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.statsText {
  font-size: 0.875rem;
  color: #666666;
}
.statsContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.bubbleContainer {
  background-color: #def3f1;
  padding: 0.875rem;
  border-radius: 56px;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.bubbles {
  align-items: center;
  display: flex;
  gap: 0.875rem;
}