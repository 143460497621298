.textInputContainer {
  position: relative;
  width: 100%;
  height: 50px;
  min-height: 50px;
}

.input {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0px 1px;
  border: 1px solid #afafaf;
  border-radius: 12px;
  outline: none;
  font-size: 0.875rem;
  transition: 0.2s;
  color: var(--gray-700);
  padding: 0 16px;
}

.input[type="file"] {
  padding: 0px 45px;
  padding-top: 15px;
}

.input:focus {
  border: 1px solid var(--green-700);
}

.input::placeholder {
  font-size: 0.875rem;
  color: var(--gray-400);
}

.label {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translate(0px, -50%);
  font-size: 1rem;
  color: var(--gray-700);
  pointer-events: none;
  transition: 0.2s;
  font-weight: semi-bold;
  line-height: 0.9rem;
}

.input:valid ~ .label {
  transform: translate(0px, -32px);
  font-size: 0.875rem;
  font-weight: 600;
  background: #ffffff;
}

.input:focus ~ .label {
  transform: translate(0px, -32px);
  color: var(--green-700);
  font-size: 1rem;
  font-weight: 600;
  background: #ffffff;
}

.input:placeholder-shown ~ .label {
  transform: translate(0px, -32px);
  font-size: 0.875rem;
  font-weight: 600;
  background: #ffffff;
}

.labelAbsolute {
  position: absolute;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--gray-700);
  top: -11px;
  left: 16px;
  background: #fff;
  transition: 0.2s;
}

.input:valid ~ .labelAbsolute {
  font-size: 0.875rem;
  font-weight: 600;
}

.input:focus ~ .labelAbsolute {
  color: var(--green-700);
  font-size: 1rem;
  font-weight: 600;
}

.input:placeholder-shown ~ .labelAbsolute {
  font-size: 0.875rem;
  font-weight: 600;
  background: #ffffff;
}

.textError {
  display: inline-block;
  text-align: left;
  margin-left: 1px;
  margin-top: 0px;
  font-size: 0.65rem;
  color: var(--red-900);
}

.inputError ~ .label {
  color: var(--red-900);
}

.container_error {
  display: flex;
  align-items: right;
  justify-content: left;
  padding-left: 2px;
}

.inputError {
  border: 1px solid red;
}
