.header {
  position: relative;
}

.border {
  width: 100%;
  height: 0.5px;
  border: 1px solid #e4e4e4;
  margin: 24px 0;
}

.header > svg {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  color: var(--gray-500);
  cursor: pointer;
}

.header > svg:hover {
  color: var(--gray-600);
}

.header h2 {
  font-size: 1.25rem;
}

.header p {
  text-align: left;
  color: var(--gray-600);
}

.wrapper {
  margin-top: 16px;
}

.containerProfile p {
  color: var(--gray-river-400);
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
}

.profile {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  border-radius: 16px;
  border: 1px solid #e4e4e4;
  box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.2);
  padding: 14px;
}

.profile > div {
  display: flex;
  flex-direction: column;
}

.profile > div strong {
  font-size: 1.125rem;
  color: var(--gray-river-800);
}

.profile > div span {
  font-size: 0.75rem;
  color: var(--gray-river-400);
}

.containerImages {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
  border-bottom: 1px solid #ebeef3;
  padding-bottom: 24px;
}

.containerImages .imagem {
  width: 85px;
  height: 85px;
  border-radius: 12px;
  position: relative;
  background: #000;
}

.containerImages .imagem img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 12px;
  object-fit: contain;
}

.imagePfd {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 12px;
  border: 1px solid var(--gray-100);
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagePfd svg {
  width: 50%;
  height: 50%;
}

.errorImage {
  position: absolute;
  left: 100px;
  color: red;
  font-weight: 600;
  font-size: 0.625rem;
}

.containerImages .imagem .containerIconClose {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: var(--gray-600);
  padding: 3px;
  cursor: pointer;
}

.containerImages .imagem .containerIconClose svg {
  color: #fff;
}

.form {
  margin-top: 21px;
}

.containerForm {
  border-bottom: 1px solid #ebeef3;
  padding-bottom: 10px;
}

.container_input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 25px;
}

.container_inputsubject {
  padding-bottom: 22px;
}

.containerSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.2);
  border-radius: 16px;
  border: 1px solid #e4e4e4;
  padding: 10px 14px;
}

.containerSelect > div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.containerSelect > div .containerIcon {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray-50);
}
.containerSelect > div .containerContent strong {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.875rem;
  color: var(--gray-river-800);
}

.containerSelect > div .containerContent p {
  font-size: 0.625rem;
  color: var(--gray-river-400);
}

.containerButton {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 48px; */
}

.containerButton_copie {
  width: 178px;
  height: 40px;
}

.containerButton_copie > button {
  width: 100%;
  height: 100%;
}

.containerButton_submit {
  width: 178px;
  height: 40px;
}

.containerButton_submit > button {
  width: 100%;
  height: 100%;
}
