.parentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
}
/* .BgImg {
  opacity: 17%;
  background: url(../../../../public/teste.png);
  background-repeat: no-repeat;
  height: 100%;
  width: 700px;
} */
.wrapper {
  z-index: 4;
  background-color: #fff;
  border: 2px solid var(--gray-200);
  border-radius: 10px;
  padding: 24px;
  gap: 30px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1300px;
}

.title {
  padding-top: 30px;
  font-size: 1rem !important;
  font-weight: 600;
}

.subTitle {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: flex-start;
  gap: 3px;
  font-size: 0.8rem !important;
  font-weight: 400;
  line-height: 24px !important;
}

.subTitles {
  font-size: 0.7rem !important;
  font-weight: 400;
  line-height: 24px !important;
}
.subTitlesCheck {
  margin-right: 5px;
}
.container {
  /* width: 576px;
  height: 404px; */
  display: grid;
  place-content: center;
  width: 35%;
  font-size: 0.8rem !important;
}

.container_buttons {
  width: 100%;
  /*  height: 100%; */
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.container_buttons > button {
  min-width: 238px;
  max-height: 43px;
}

.containerVideo {
  width: 82%;
  /* height: 350px; */
  height: 480px;
  /* width: 900px; */
  overflow: hidden;
  border-radius: 12px;
}

.video {
  width: 100%;
  height: 100%;
}

@media (max-width: 1540px) {
  .wrapper {
    max-width: 1147px;
  }

  .containerVideo {
    height: 377px;
    width: 60%;
  }
  .container_buttons {
    margin-top: 10px;
  }
  .subTitles {
    font-size: 0.6rem !important;
    font-weight: 400;
    line-height: 24px !important;
  }
  .wrapper {
    padding: 14px;
  }
}

@media (max-width: 1220px) {
  .wrapper {
    max-width: 1000px;
  }

  .containerVideo {
    height: 327px;
  }

  .container_buttons {
    margin-top: 20px;
  }
}

@media (max-width: 1080px) {
  .wrapper {
    max-width: 900px;
  }

  .containerVideo {
    height: 293px;
  }

  .title {
    font-size: 0.7rem !important;
    padding-top: 15px;
  }

  .subTitles {
    line-height: 15px !important;
  }
}

@media (max-width: 970px) {
  .title {
    padding-top: 10px;
  }
}

@media (max-width: 912px) {
  .wrapper {
    flex-direction: column;
    max-width: 100%;
    margin-top: 100px;
  }

  .containerVideo {
    width: 748px;
    height: 420px;
  }

  .container p {
    font-size: 16px !important;
  }
}

@media (max-width: 880px) {
  .wrapper {
    flex-direction: column;
    max-width: 100%;
    margin-top: 100px;
  }

  .containerVideo {
    width: 500px;
    height: 281px;
  }

  .container p {
    font-size: 16px !important;
  }
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    max-width: 100%;
    margin-top: 100px;
  }

  .containerVideo {
    width: 500px;
  }

  .container p {
    font-size: 16px !important;
  }
}

@media (max-width: 540px) {
  .parentContainer {
    margin-top: 90px;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .wrapper {
    flex-direction: column;
    max-width: 95%;
    margin-top: 0px;
  }

  .containerVideo {
    width: 360px;
    height: 202px;
  }

  .container p {
    font-size: 12px !important;
    width: 350px !important;
  }
}

@media only screen and (max-width: 414px) and (min-width: 412px) {
  .wrapper {
    flex-direction: column;
    max-width: 95%;
    margin-top: -100px;
  }

  .containerVideo {
    width: 360px;
    height: 202px;
  }

  .container p {
    font-size: 12px !important;
    width: 350px !important;
  }
}

@media only screen and (max-width: 395px) and (min-width: 380px) {
  .wrapper {
    flex-direction: column;
    max-width: 95%;
    margin-top: -100px;
  }

  .containerVideo {
    width: 350px;
    height: 197px;
  }

  .container p {
    font-size: 12px !important;
    width: 340px !important;
  }
}

@media only screen and (max-width: 375px) and (min-width: 370px) {
  .wrapper {
    flex-direction: column;
    max-width: 95%;
    margin-top: 100px;
  }

  .containerVideo {
    width: 320px;
    height: 180px;
  }

  .container p {
    font-size: 12px !important;
    width: 320px !important;
  }
}

@media only screen and (max-width: 360px) and (min-width: 350px) {
  .wrapper {
    flex-direction: column;
    max-width: 95%;
    margin-top: 0px;
  }

  .containerVideo {
    width: 320px;
    height: 180px;
  }

  .container p {
    font-size: 12px !important;
    width: 300px !important;
  }
}

@media (max-width: 280px) {
  .wrapper {
    flex-direction: column;
    max-width: 95%;
    margin-top: 50px;
  }

  .containerVideo {
    max-width: 255px;
    max-height: 145px;
  }

  .container p {
    font-size: 11px !important;
    width: 225px !important;
  }
}
