.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.loading_container {
  height: 100%;
  width: 100%;
  padding-left: 90px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  justify-content: center;
}

.loading_spinne {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #007e69;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.loading_content {
  margin-top: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 880px) {

  .loading p {
    font-size: 0.75rem;
    text-align: center;
  }

  .loading_container {
    display: flex;
    padding-left: 0px;
    padding-bottom: 10px;
  }

  .loading_spinne {
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-left: 3px solid #007e69;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  .loading_content {
    margin-top: 0px;
  }
}
