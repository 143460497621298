.wrapper {
  margin-top: 24px;
}

.wrapper .tableExams {
  width: 100%;
  border-collapse: collapse;
}

.wrapper .tableExams th {
  width: 100%;
  background-color: var(--gray-50);
  padding: 1rem;
  text-align: left;
  font-size: 0.875rem;
  color: var(--gray-river-700);
}

.wrapper .tableExams th:first-child {
  /* text-align: center; */
  border-radius: 12px 0 0 12px;
}

.wrapper .tableExams th:last-child {
    border-radius: 0 12px 12px 0;
}

.wrapper .tableExams td {
  width: 100%;
  background-color: #fff;
  padding: 1rem 1rem;
  text-align: left;
  font-size: 0.875rem;
  color: var(--gray-700);
  border-bottom: 0.5px solid var(--gray-100);
}

.wrapper .tableExams td:first-child {
  /* text-align: center; */
  padding-left: 50px;
}

.tableExams .tableExams_thead tr {
  display: grid;
  grid-template-columns: 0.4fr 2fr 0.5fr;
}

.tableExams .tableExams_tbody tr {
  display: grid;
  grid-template-columns: 0.4fr 2fr 0.5fr;
}

@media(max-width: 684px) {
  .tableExams_thead th, 
  .tableExams_tbody td{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }

  .tableExams_tbody tr td{
    flex-wrap: wrap;
    padding-left: 15px !important;
    color: #666666
  }

  .tableExams_tbody tr td:nth-child(2) {
    color: #353535;
  }

  .tableExams_tbody tr td:nth-child(3) {
    padding-left: 0px !important;
  }
}