.checkbox_container {
  position: relative;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.checkbox_variants {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 9px;
}

.checkbox_parentcomponents {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: left;
  padding: 5px 0px;
  gap: 8px;
  width: 20px;
}

.text_checkbox {
  font-size: 1rem;
  color: var(--gray-900);
}

.containerError {
  margin-top: -5px;
}

@media (max-width: 880px) {
  .text_checkbox {
    font-size: 0.75rem;
  }
}
