.wrapper_input {
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 80px;
  padding: 8px 16px;
  display: flex;
  grid-area: search;
}
.searchContainer {
  width: 100%;
}

.textNoClinic {
  position: absolute !important;
  left: calc((100vw - 600px) - 39%);
  top: 40%;
  transform: translateY(-50%);
  color: #afafaf;
}

.searchIcon {
  z-index: 10;
}
.cardsContainerform {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}
.cardsContainerforms {
  width: 300px;
}
.searchInput {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 80px;
  padding: 0 16px;
  padding-left: 44px;
  position: absolute;
  outline: none;
  border: none;
  background-color: #efefef;
}
.containerInputs {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}
.selectOption {
  z-index: 10;
  border-radius: 80px;
  border: 0;
  outline: none;
}
.createBtn {
  padding: 0;
  background-color: #007e69;
  height: 40px;
  padding: 10px 16px;
  min-width: 200px;
  text-align: center;
}
.createBtnContainer {
  grid-area: create;
  display: flex;
  gap: 16px;
  justify-self: flex-end;
}
.createBtn:hover {
  background-color: #004438 !important;
  transition: all 0.2s;
}
.plusIcon {
  margin: 0;
  padding: 0;
  color: #fff;
}
.containerGrid {
  border: 1px solid #ced5de;
  border-radius: 16px;
  padding: 20px;
  margin-top: 7px;
  box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.2);
  width: 100%;
  height: 100%;
  grid-area: "card";
}
.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
}

.cardHeader img {
  width: auto;
  max-width: 110px;
  height: 35px;
}

.cardTitle {
  color: #353535;
  font-size: 20px;

  font-weight: 700;
}

.cardTitle span {
  color: #353535;
  font-size: 0.75rem;

  font-weight: 400;
  opacity: 0.6;
}

.cardImg {
  padding-top: 5px;
}

.cardSubtitle {
  color: var(--neutral-colors-gray-gray-600, #666);
}

.outerContainer {
  position: relative;
  margin: 20px;
  min-height: calc(100vh - 143px);
}

.containerSelect {
  display: grid;
  grid-template-areas:
    "search create"
    "card card";
  gap: 1rem;
  height: fit-content;
}

.playIcon {
  margin: 0 10px;
}

.selectContainer {
  display: flex;
  align-items: center;
}

.trash {
  cursor: pointer;
  padding: 5px;
}

.examsContainer {
  margin-top: 32px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.buttonContainer {
  padding-top: 0px;
}

.buttonContainer svg:hover {
  color: red;
}

.examsText {
  color: #7b889c;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.examsBadge {
  display: inline-block;
  padding: 4px 12px;
  font-weight: 600;
  border-radius: 12px;
  margin: 8px 8px 0 0;
}

.tomografiaBadge {
  color: #d72c69;
  background-color: #fddae6;
}

.raioxBadge {
  color: #078295;
  background-color: #bcf0f8;
}

.atmBadge {
  color: #1565c0;
  background-color: #e1e6ef;
}

.iconContainer,
.clockIconContainer {
  display: inline;
  margin-right: 8px;
}

.chartContainer {
  margin-top: 32px;
  border: 1px solid #ced5de;
  border-radius: 16px;
  box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.timeBadge {
  position: absolute;
  top: 0;
  left: 60%;
  padding: 6px 10px;
  color: #465364;
  font-weight: 600;
  border-radius: 0px 0px 8px 8px;
  background: var(--neutral-colors-gray-river-gray-river-50, #ebeef3);
  margin: 0 10px 10px 0;
}

.chartTitle {
  font-weight: 600;
  color: #00523d;
}

.chartTopContainer {
  margin: 15px;
  display: flex;
  column-gap: 8px;
}

.chartSubtitle {
  color: #353535;
}

.chartLineUpContainer {
  background-color: #def3f1;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.chartTopTextContainer {
  display: inline-block;
}

.graphContainer {
  display: flex;
  justify-content: center;
  column-gap: 40px;
  margin-top: 30px;
  width: 100%;
  min-width: 0;
}

.link {
  text-decoration: none;
}
.meioDeCaptacao {
  color: #151515;
}

.chartLabel {
  color: #151515;
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
  gap: 0 5px;
}

.meioDeCaptacaoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chartLabelPercentage {
  color: #009c86;
  font-weight: 600;
}

.chartAmount {
  font-weight: 700;
  color: #535353;
}

.dateAdded {
  color: #666666;
  font-weight: 600;
  margin: 20px 0 15px 15px;
}

.graphWrapper {
  width: 30%;
}

.cardsContainer {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 16px;
  margin-bottom: 100px;
}

.avatar {
  border-radius: 16px;
  position: relative;
  border: 0.5px solid #f8f8f8;
}

.avatar1 {
  margin-left: -3px;
}

.avatar2 {
  margin-left: -5px;
}

.patientsContainer {
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.avatars {
  display: flex;
  flex-direction: row;
  gap: -10px;
}

.chartType {
  color: #151515;
}

.button {
  display: flex !important;
  padding: 10px 32px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  border-radius: 56px !important;
  background: #007e69 !important;
  color: #fff !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "Segoe UI Variable" !important;
}

.buttonEvolCloud {
  display: flex !important;
  padding: 10px 32px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  border-radius: 56px !important;
  background: #fff !important;
  color: #000 !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "Segoe UI Variable" !important;
  border: 1px solid #0d6efd !important ;
  border: 1px solid #ced5de !important ;
  box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.2) !important ;
}

.buttonEvolCloud:hover {
  background: #fff !important;
  border: 1px solid #0d6efd !important ;
}

.buttonEvolCloud img {
  border-radius: 50%;
  width: 38px;
}

.buttonEvolCloud svg {
  color: #000;
}

.container_buttons_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default !important;
}

.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.containerFooter {
  position: absolute;
  bottom: -16px;
  left: 0;
  width: 100%;
}

@media (max-width: 1540px) {
  .containerGrid {
    width: 100%;
    height: 100%;
  }
  .cardTitle {
    font-size: 16px;
  }
  .cardSubtitle {
    font-size: 12px;
  }
  .examsText {
    font-size: 12px;
  }
  .cardImg {
    width: 83px;
    height: 43px;
  }
}
@media (max-width: 1300px) {
  .containerBox {
    display: none;
  }

  .textNoClinic {
    left: 50%;
    top: 40%;
    transform: translate(-50%, -40%);
  }

  .wrapper_input {
    width: 100%;
  }
  
  .cardsContainer {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 880px) {
  .outerContainer {
    margin: 20px;
    margin-top: 100px;
    min-height: calc(100vh - 105px) !important;
  }

  .createBtn {
    height: 100%;
    width: fit-content;
    min-width: auto;
    padding: 0.5rem;
  }
  .createBtnContainerdisplay {
    display: none;
  }
  .containerSelect {
    display: flex;
    flex-direction: column;
  }
  .inputsContainer {
    display: flex !important;
    gap: 0.5rem;
  }

  .buttonEvolCloud {
    width: 100px;
    height: 50px;
  }

  .buttonEvolCloud svg {
    display: none;
  }

  .buttonEvolCloud img {
    height: 42px !important;
  }

  .button {
    width: 90px;
    height: 50px;
  }

  .button svg {
    display: block !important;
    width: 20px !important;
    height: 20px !important;
  }

  .containerGrid {
    width: 89vw;
    height: 100%;
  }

  .examsText {
    font-size: 0.625rem;
  }

  .cardImg {
    width: 80px !important;
    height: 30px !important;
  }

  .containerFooter {
    padding-bottom: 10px;
  }
}

.inputsContainer {
  display: contents;
}
