.containerTextEditor {
  width: 794px; /* Largura da página A4 */
  width: auto; /* Largura da página A4 */
  height: 1165px; /* Altura da página A4 */
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.containerTextEditor .ql-container.ql-snow {
  min-height: 800px;
  padding-bottom: 50px;
  cursor: text;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-colors-gray-gray-200, #e4e4e4);
  border-top: 1px solid #e4e4e4 !important;
  overflow: hidden;
  position: relative;
}

.containerTextEditor .ql-editor {
  flex-grow: 1;
  min-height: 100%;
  padding: 16px;
  padding: 0px;
  margin: 0px;
  background-color: #fff;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.ql-editor::after {
  content: "";
  display: block;
  height: 100vh; /* Adiciona altura extra */
  pointer-events: none; /* Não interfere com os cliques */
}

.ql-toolbar {
  border: none !important;
  box-shadow: none; /* Remove a sombra da borda, se houver */
  position: relative;
  width: 100%;
}

.ql-editor .header {
  height: 60px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e4e4e4;
  position: sticky;
  top: 0;
  z-index: 10;
}

.ql-editor .footer {
  height: 60px;
  background-color: #031b4e;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  z-index: 10;
}

.ql-editor .content {
  flex-grow: 1;
  padding: 20px;
}
