.mainContainer {
  padding: 0 1rem;
}

.userCard {
  border-radius: 24px;
  border: 1px solid #e4e4e4;
  background: #fff;
  padding: 1.5rem;
}
.cardRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profilePic {
  border-radius: 24px;
  width: 8rem;
  height: 8rem;
  object-fit: contain;
}
.subTitle {
  font-weight: 700;
  color: #64748b;
  font-size: 1rem;
}
.name {
  font-size: 2rem;
}
.rating {
  font-size: 0.8rem;
}
.profileDetailsContainer {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.statsText {
  font-size: 0.875rem;
  color: #666666;
}
.statsContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.bubbleContainer {
  background-color: #def3f1;
  padding: 0.875rem;
  border-radius: 56px;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.bubbles {
  align-items: center;
  display: flex;
  gap: 0.875rem;
}
@media (max-width: 1000px) {
  .cardRow {
    flex-wrap: wrap;
    gap: 1rem;
  }
}
