.wrapper {
  margin-top: 16px;
}

.wrapper nav ul {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-content: space-between;
}

.wrapper nav ul li {
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid var(--gray-200);
  transition: 200ms;
  white-space: nowrap;
}

.wrapper nav ul li:hover {
  cursor: pointer;
  border-bottom: 2px solid var(--green-700);
}

.wrapper nav ul li.active {
  color: red;
  font-weight: bold;
  border-bottom: 2px solid var(--green-700);
}

@media (max-width: 700px) {
  .wrapper nav ul li.active {
    border-bottom: 2px solid var(--gray-200);
  }
}

.wrapper nav ul li span {
  color: var(--gray-400);
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  transition: 200ms;
  white-space: nowrap;
}

.wrapper nav ul li:hover span {
  color: var(--green-700);
}

.wrapper nav ul li.active span {
  color: var(--green-700);
}

.wrapper nav ul li span svg {
  width: 20px;
}

.active {
  color: green;
  font-weight: bold;
  border-bottom: 2px solid var(--green-700);
}

@media (max-width: 684px) {
  .wrapper nav ul li {
    width: 140px;
  }

  .wrapper nav {
    overflow-x: scroll;
  }

  .wrapper nav::-webkit-scrollbar {
    height: 0px; 
  }

  .wrapper nav li span {
    font-size: 14px;
  }
}
