.container {
  margin-top: 14px;
}

.container_amountOfCustomers {
  display: flex;
  justify-content: end;
  color: var(--gray-800);
  font-weight: 600;
  letter-spacing: 0.64px;
  margin-bottom: 16px;
  padding-right: 10px;
}

.container_table table {
  border-collapse: collapse;
}

.container_table table tr {
  cursor: pointer;
}

.container_table table th {
  width: 100%;
  background-color: var(--gray-50);
  padding: 1rem;
  text-align: left;
  font-size: 0.875rem;
  color: var(--gray-river-700);
}

.container_table table th:first-child {
  border-top-left-radius: 20px;
}

.container_table table th:last-child {
  border-top-right-radius: 20px;
}

.container_table table th:last-child {
  text-align: center;
}

.container_table table th > div {
  display: flex;
  align-items: left;
  gap: 4;
}

.container_table table td {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 8px 1rem;
  text-align: left;
  font-size: 0.875rem;
  color: var(--gray-700);
  border-bottom: 0.5px solid var(--gray-100);
}

.container table td:last-child {
  justify-content: center;
}

.container_table table td:last-child {
  text-align: center;
  border-right: 0.5px solid var(--gray-50);
}

.container_table table td > div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.titleClass {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 4px 8px;
  border-radius: 24px;
  font-size: 0.75rem;
  font-weight: 600;
}

.atendido {
  background: var(--green-50);
  color: var(--green-600);
}
.entregue {
  background: #b0e2f1;
  color: #054c57;
}
.emAndamento {
  background: #b0e2f1;
  color: #054c57;
}

.agendado {
  background: #f8c393;
  color: #854d0b;
}

.cancelado {
  background: #f2b5c1;
  color: #930739;
}

.alta {
  background: #ffecb3;
  color: #ff6f00;
}

.container_table table {
  width: 100%;
}

.container_table table th,
td {
  width: auto !important;
}

.container_table table tbody tr,
.container_table table thead tr {
  display: grid;
  grid-template-columns: 1.5fr 1fr 0.6fr 0.7fr 0.7fr 0.7fr 0.7fr 0.6fr;
}

.container_table table tbody a {
  text-decoration: none;
}
.upDownIconsContainer {
  display: flex;
  flex-direction: column;
  margin-left: 7px;
  justify-content: center;
}
.upDownIcons {
  cursor: pointer;
}

@media (max-width: 1584px) {
  .container_table table tbody tr,
  .container_table table thead tr {
    grid-template-columns: 1.6fr 1fr 0.5fr 1.2fr 0.6fr 0.6fr 0.6fr 0.6fr;
  }
}

@media (max-width: 1545px) {
  .container_table table tbody tr,
  .container_table table thead tr {
    grid-template-columns: 20% 15% 10% 14% 11% 10% 12% 8%;
  }

  .container_table table tbody tr td {
    font-size: 0.8rem;
  }

  .container_table table thead tr th {
    font-size: 0.8rem;
  }

  .container_table table thead tr th:last-child {
    text-indent: -9999px;
  }
}

@media (max-width: 1410px) {
  .container_table table tbody tr td {
    font-size: 0.75rem;
  }

  .container_table table td:nth-child(1) {
    font-size: 0.7rem;
  }

  .container_table table th {
    font-size: 0.78rem;
  }

  /* .container_table table tbody tr {
    grid-template-columns: 1.4fr 1.1fr 0.6fr 1.2fr 0.7fr 0.7fr 0.7fr 0.7fr;
  }

    .container_table table thead tr {
    grid-template-columns: 1.4fr 1.1fr 0.6fr 1.2fr 0.7fr 0.7fr 0.7fr 0.7fr;
  } */
}

@media (max-width: 1340px) {
  .container_table table thead tr th:last-child {
    text-indent: 0px;
  }

  .container_table table tbody tr,
  .container_table table thead tr {
    grid-template-columns: 20% 15% 10% 14% 11% 10% 10% 10%;
  }
}

@media (max-width: 1250px) {
  .container_table table thead tr th:last-child {
    text-indent: -9999px;
  }

  .container_table table tbody tr,
  .container_table table thead tr {
    grid-template-columns: 20% 15% 10% 14% 11% 10% 12% 8%;
  }
}

@media (min-width: 500px) and (max-device-width: 1024px) {
  .container {
    max-width: 100vw !important;
  }
  
  .container_table table {
    width: 100%;
    border-collapse: collapse;
  }

  .container_table table thead th,
  .container_table table tbody td {
    padding: 8px;
    text-align: left;
  }

  .container_table table th:nth-child(1),
  .container_table table td:nth-child(1) {
    width: 25%;
  }

  .container_table table th:nth-child(4),
  .container_table table td:nth-child(4) {
    width: 25%;
  }
}

@media (max-width: 500px) {
  .container {
    max-width: 100vw !important;
    padding-bottom: 3rem;
  }

  .container_table th:nth-child(3),
  .container_table th:nth-child(4),
  .container_table th:nth-child(5),
  .container_table th:nth-child(6),
  .container_table th:nth-child(7),
  .container_table th:nth-child(8) {
    display: none;
  }

  .container_table tr td:nth-child(3),
  .container_table tr td:nth-child(4),
  .container_table tr td:nth-child(5),
  .container_table tr td:nth-child(6),
  .container_table tr td:nth-child(7),
  .container_table tr td:nth-child(8) {
    display: none;
  }

  .container_table table tbody tr,
  .container_table table thead tr {
    grid-template-columns: 1.4fr 0.8fr !important;
  }

  .container_table table thead tr th:first-child {
    border-top-left-radius: 20px;
  }

  .container_table table thead tr th:nth-child(2) {
    border-top-right-radius: 20px;
  }

  .container_table table tbody tr td,
  .container_table table thead tr th {
    font-size: 13px !important;
  }

  .container_table table tbody tr td {
    font-weight: 500;
  }
}

@media (max-width: 280px) {
  .container_table table tbody tr,
  .container_table table thead tr {
    grid-template-columns: 1fr 0.2fr !important;
  }

  .container_amountOfCustomers {
    padding-right: 30px;
  }
}
