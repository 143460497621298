.asideViewImage {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 305px;
  height: 100vh;
  /* height: auto; */
  border-left: 1px solid var(--gray-700);
  padding: 16px;
  padding-bottom: 2rem;
  overflow-y: scroll;

  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  background: #000;
}

.asideViewImage::-webkit-scrollbar {
  width: 6px;
  background-color: none;
}

.asideViewImage::-webkit-scrollbar-thumb {
  background-color: #2ebbaa;
  border-radius: 5px;
}

.asideViewImageCollapse {
  width: 0px;
  transition: width ease-out 300ms;
  border-right: 1px solid #ebecf0;
}

.asideClose {
  transform: translate(-120%);
  padding-top: 10px;
}

.showvisualizar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.containerIcons {
  position: absolute;
  top: 88px;
  left: 0%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 999;
}

.containerIcons svg {
  color: #fff;
}

.ButtonXMark {
  width: 30px;
  height: 30px;
  background: var(--red-900);
  border-radius: 56px;
  cursor: pointer;
}

.containerIcons .buttonChevronRight {
  width: 30px;
  height: 30px;
  background: var(--green-700);
  border-radius: 56px;
  padding: 5px;
  cursor: pointer;
  border: 0;
}

.containerIcons .buttonArrowPath {
  width: 30px;
  height: 30px;
  background: var(--blue-900);
  border-radius: 56px;
  padding: 5px;
  cursor: pointer;
  border: 0;
}

.containerIcons .buttonArrowPath:not(:disabled):hover {
  opacity: 0.9;
  cursor: pointer;
}

.containerOption {
  margin-top: 30px;
}

.containerOptionTridimensional {
  margin-top: 30px;
}

.containerOption p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 0.875rem;
  padding: 5px 0;
  cursor: pointer;
  margin-bottom: 35px;
  text-transform: uppercase;
}

.containerOptionTridimensional p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 0.875rem;
  padding: 5px 0;
  cursor: pointer;
  margin-bottom: 35px;
  text-transform: uppercase;
}

.containerInputOpcoesBasicas {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.containerInputOpcoesBasicas span {
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  aling-items: center;
  justify-content: space-between;
}

.containerInputOpcoesBasicas span span {
  color: white;
}

.containerInputOpcoesBasicas:first-child {
  margin-bottom: 35px;
}

.containerInputOpcoesBasicas input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  width: 100%;
  height: 0.5em;
  position: relative;
  border-radius: 5px;
  background-color: var(--green-50);
  outline: none;
}

.containerInputTridimensional {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.containerInputTridimensional span {
  font-size: 0.875rem;
  font-weight: 600;
}

.containerInputTridimensional:first-child {
  margin-bottom: 5px;
}

.containerInputTridimensional input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  width: 100%;
  height: 0.5em;
  position: relative;
  border-radius: 5px;
  background-color: var(--green-50);
  outline: none;
  margin-bottom: 35px;
}

.containerButtons {
  display: flex;
  gap: 16px;
  margin: 30px 0;
}

.containerButton {
  height: 40px;
  font-size: 12px;
}

.containerButton > button {
  min-width: 120px;
  height: 40px;
  background: var(--green-700);
  border-radius: 56px;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #fff;
  cursor: pointer;
}

.containerButton:first-child > button svg {
  transform: rotate(90deg);
}

.containerButton > button:hover {
  background: var(--green-800);
}

.containerImages {
  margin-top: 24px;
  max-height: calc(100vh - 480px);
  overflow-y: scrool;
}

.containerImages p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 0.875rem;
  padding: 5px 0;
  cursor: pointer;
  margin-bottom: 35px;
  text-transform: uppercase;
}

.containerImagens {
  position: relative;
  /* margin-bottom: calc(100vh - 552px); */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow-y: scroll;
  max-height: calc(100vh - 552px);
  z-index: 1px;
}

.containerImagens::-webkit-scrollbar {
  width: 5px;
  background: #353535;
  padding-left: 4px;
  border-radius: 8px;
}

.containerImagens::-webkit-scrollbar-thumb {
  background-color: #2ebbaa;
  border-radius: 2px;
}

.containerImage {
  position: relative;
  border-radius: 16px;
  padding: 2px;
  background: #000;
}

.containerImage:hover {
  cursor: pointer;
}

.containerImage_active {
  background-color: var(--green-700);
}

.containerImage img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 16px;
  object-fit: cover;
}

.containerImages3d {
  position: relative;
  margin-top: 20px;
}

.inputCheckbox {
  position: absolute;
  border: 1px solid #fff;
  width: 16px;
  height: 16px;
  top: 20px;
  left: 20px;
  border-radius: 10px;
  clip-path: circle(62%);
  z-index: 99;
}

.containerToggles {
  display: flex;
  gap: 16px;
  height: 24px;
  margin-bottom: 25px;
}

.containerToggles p {
  margin-top: 6px;
}

.containerToggles strong {
  font-size: 0.75rem;
}
.button:not([disabled]):hover {
  opacity: 0.9;
}

.buttonGreen {
  background: var(--green-800);
  border: 0;
}

.button svg {
  width: 20px;
  height: 20px;
}

.containerOnOff {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.containerOnOff .buttonTrash {
  width: 44px;
  height: 22px;
  border-radius: 56px;
  border: none;
  background: var(--green-800);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.containerOnOff .buttonTrash:hover {
  background: var(--green-900);
}

.containerOnOff strong {
  font-size: 0.875rem;
}

.containerExibir {
  margin-top: 40px;
}

.containerExibir p:hover {
  opacity: 0.9;
  text-decoration: underline;
}

.containerExibir p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;
  cursor: pointer;
}

.containerOption {
  margin-top: 30px;
}

.containerOption p:hover {
  opacity: 0.9;
  text-decoration: underline;
}

.containerOption p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;
  cursor: pointer;
}
.asideCefX {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 305px;
  height: 100vh;
  /* height: auto; */
  border-left: 1px solid var(--gray-700);
  padding: 16px;
  padding-left: 20px;
  padding-bottom: 2rem;
  overflow-y: scroll;

  position: absolute;
  top: 0;
  right: 0;
  z-index: 999.1;
  background: #000;
  transition: width ease-out 150ms;
}

.asideCefX::-webkit-scrollbar {
  width: 6px;
  background-color: none;
}

.asideCefX::-webkit-scrollbar-thumb {
  background-color: #2ebbaa;
  border-radius: 5px;
}

.asideCefX_collapse {
  /* display: none;
    width: 0px;
    position: absolute;
    right: 250px;
    transition: width ease-out 300ms; */

  width: 0;
  overflow: hidden;
  transition: width ease-out 150ms;
}

.asideCefX_collapse.asideCefX {
  width: 0;
}

.asideCefX_collapse .constainer_content {
  display: none;
  width: 0;
  overflow: hidden;
  transition: width ease-out 100ms;
}

.constainer_content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.asideClose {
  transform: translate(-120%);
  padding-top: 10px;
}

.containerIcons {
  position: absolute;
  top: 72px;
  right: 290px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 999;
}

.containerIcons_collapse {
  position: absolute;
  top: 75px;
  right: 0px;
  padding-right: 1px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 999;
}

.ButtonXMark {
  width: 28px;
  height: 28px;
  background: var(--red-900);
  border-radius: 56px;
  cursor: pointer;
}

.ButtonXMark:hover {
  opacity: 0.9;
}

.buttonChevronRight {
  width: 27px;
  height: 27px;
  background: var(--green-700);
  border-radius: 56px;
  padding: 5px;
  cursor: pointer;
  transition: transform 300ms;
}

.buttonChevronRight svg {
  cursor: pointer;
}

.buttonArrowPath {
  width: 27px;
  height: 27px;
  background: var(--blue-900);
  border-radius: 56px;
  padding: 5px;
  cursor: pointer;
}

/* .containerIcons .buttonArrowPath:hover {
    opacity: 0.9;
  } */

.buttonQuestion {
  width: 27px;
  height: 27px;
  background: var(--gray-600);
  border-radius: 56px;
  padding: 5px;
  cursor: pointer;
}

.containerButtonsGeneratePointsAndSave {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 50px;
}

.button {
  border: 0;
  height: 40px;
  border-radius: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background: none;
  border: 2px solid var(--gray-800);
  color: #fff;
  cursor: pointer;
}

.button:disabled,
.button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.button:not([disabled]):hover {
  opacity: 0.9;
}

.buttonGreen {
  background: var(--green-800);
  border: 0;
}

.button svg {
  width: 20px;
  height: 20px;
}

.containerOnOff {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.containerOnOff .buttonTrash {
  width: 44px;
  height: 22px;
  border-radius: 56px;
  border: none;
  background: var(--green-800);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.containerOnOff .buttonTrash:hover {
  background: var(--green-900);
}

.containerOnOff strong {
  font-size: 0.875rem;
}

.containerExibir {
  margin-top: 40px;
}

.containerExibir p:hover {
  opacity: 0.9;
  text-decoration: underline;
}

.containerExibir p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;
  cursor: pointer;
}

.containerOption {
  margin-top: 30px;
}

.containerOption p:hover {
  opacity: 0.9;
  text-decoration: underline;
}

.containerOption p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;
  cursor: pointer;
}

.containerInputOpcoesBasicas {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.containerInputOpcoesBasicas span {
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  aling-items: center;
  justify-content: space-between;
}

.containerInputOpcoesBasicas span span {
  color: white;
}

.containerInputOpcoesBasicas {
  margin-bottom: 35px;
}

.containerInputOpcoesBasicas:last-child {
  margin-bottom: 5px;
}

.containerInputOpcoesBasicas input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  width: 100%;
  height: 0.5em;
  position: relative;
  border-radius: 5px;
  background-color: var(--green-50);
  outline: none;
}

.containerAnalyzes {
  margin-top: 30px;
}

.containerAnalyzes p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;
  cursor: pointer;
}

.containerAnalyzes p:hover {
  opacity: 0.9;
  text-decoration: underline;
}

.containerAnalyzes .analyzes {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.containerAnalyzes .analyzes .container_analyzes_data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}

.containerAnalyzes .analyzes .analyzes_data {
  border: 2px solid var(--gray-800);
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  background-color: #000;
  color: #fff;
}

.containerAnalyzes .containerButtonAnalyzes {
  position: relative;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
}

.containerAnalyzes .containerButtonAnalyzes .buttonAnalyzes {
  width: 166px;
  height: 32px;
  border-radius: 56px;
  background: var(--green-100);
  color: var(--green-700);
  font-weight: 600;
  cursor: pointer;
  border: 0;
}

.containerAnalyzes .containerButtonAnalyzes .buttonAnalyzes:hover {
  opacity: 0.9;
}

.containerNoResults span {
  font-size: 0.75rem;
  color: var(--gray-500);
}

.containerPoint {
  margin-top: 35px;
}

.containerPoint p {
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.containerPoint p:hover {
  opacity: 0.9;
  text-decoration: underline;
}

.points {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
}

.containerStructures {
  margin-top: 39px;
  margin-bottom: 52px;
}

.containerStructures p {
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.containerStructures p:hover {
  opacity: 0.9;
  text-decoration: underline;
}

.containerImagensCefx p {
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.containerImagensCefx p:hover {
  opacity: 0.9;
  text-decoration: underline;
}

.containerCheckbox {
  margin-top: 10px;
  height: 300px;
  overflow-y: scroll;
}

.containerSearchResult {
  width: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding: 12px 0px;
  gap: 12px;
}

.containerCheckbox::-webkit-scrollbar {
  width: 5px;
  background: #353535;
  padding-left: 4px;
  border-radius: 8px;
}

.containerCheckbox::-webkit-scrollbar-thumb {
  background-color: #2ebbaa;
  border-radius: 2px;
}

.containerCheckbox {
  scrollbar-width: thin;
  scrollbar-color: #2ebbaa transparent;
}

.containerSearchResult > div {
  display: flex;
  align-items: center;
  gap: 12px;
  background: var(--gray-800);
  border-radius: 12px;
  padding: 12px 16px;
  margin-right: 4px;
}

.inputCheckbox {
  width: 15px;
  height: 15px;
}

.point {
  position: relative;
  width: 100%;
  height: 23px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.point span {
  font-size: 0.75rem;
}

.pointSelected {
  width: auto;
  min-width: 230px;
  height: 148px;
  padding: 20px;
}

.pointSelected header {
  width: 100%;
  display: flex;
  gap: 55px;
}

.pointSelected header > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pointSelected header > div span {
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.pointSelected header > div > .pointTooltip {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff6f00;
  font-size: 10px;
  color: #fff;
}

.pointSelected .description {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.pointSelected .description p {
  font-size: 0.875rem;
}

.table {
  width: 100%;
  border: 1px solid blue;
}
.table tr {
  border: 1px solid blue;
}

.containerImagescefx {
  width: 100%;
  height: 300px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  gap: 5px;
  padding-bottom: 10px;
  padding-right: 5px;
  overflow-y: scroll;
}

.containerImagescefx::-webkit-scrollbar {
  width: 5px;
  background: #353535;
  padding-left: 4px;
  border-radius: 8px;
}

.containerImagescefx::-webkit-scrollbar-thumb {
  background-color: #2ebbaa;
  border-radius: 2px;
}

.containerImagescefx {
  scrollbar-width: thin;
  scrollbar-color: #2ebbaa transparent;
}

.containerImagescefx > div {
  cursor: pointer;
  border-radius: 5px;
}

.containerImagescefx > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.container_buttons_loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container_buttons_loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

.containerLineProcess {
  position: relative;
  padding-top: 12px;
  font-size: 0.875rem;
  margin-bottom: 5px;
}

.containerLineProcess p {
  position: absolute;
  right: 6px;
  top: 0px;
  animation: loading 3s infinite linear;
}

.containerLineProcess span {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
}

.triangle {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 50px solid #fff;
}

@keyframes loading {
  0% {
    color: #fff;
  }
  50% {
    color: #000;
  }
  100% {
    color: #fff;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 880px) {
  .asideViewImage {
    display: none;
  }
}
