.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerImage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerImage img {
  width: 100px;
}

h1 {
  color: var(--green-900);
  font-size: 3rem;
}

.link {
    display:block;
    width: 300px;
    height: 60px;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: var(--green-700);
    cursor: pointer;
    color: #fff;

}

.link:hover {
    background: var(--green-800);
}
