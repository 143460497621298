.iconClose {
  position: absolute;
  right: 20px;
  top: 24px;
  width: 18px;
  cursor: pointer;
  color: var(--gray-400);
}

.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.iconClose:hover {
  color: var(--gray-500);
}

.header {
  padding: 24px 20px;
  background-color: #fff;
  color: var(--gray-800);
  border-radius: 12px 12px 0 0 ;
}

.header h2 {
  color: var(--gray-800);
}

.header_content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
  justify-content: space-between;
}

.header_content p {
  color: var(--gray-900);
}

.header_content p.not {
  color: var(--green-700);
  font-weight: 600;
  text-align: right;
  cursor: pointer;
}

.header_content p.not:hover {
  color: var(--green-800);
}

.containerVideo {
    flex: 1;
    padding: 16px;
}

.containerVideo .video {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

