.wrapper {
  width: 100%;
  height: 88px;
  display: flex;
  align-items: center;
  padding: 16px 32px;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-700);
  padding-right: 400px;
}

.containerLogo {
  display: flex;
  align-items: center;
  gap: 40px;
}

.containerIconHand {
  width: 36px;
  height: 56px;
}

.containerIconHand > button {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.containerDoc {
  width: 36px;
  height: 56px;
}

.containerDoc > button {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: #151515;
}

.containerPoints {
  width: 36px;
  height: 56px;
}

.containerPoints > button {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: #151515;
}

.containerStructure {
  width: 36px;
  height: 56px;
}

.containerStructure > button {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: #151515;
}

.containerTracing {
  width: 36px;
  height: 56px;
}

.containerTracing > button {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: #151515;
}

.containerTable {
  width: 36px;
  height: 56px;
}

.containerTable > button {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: #151515;
}

.container_onOffAndButton {
  display: flex;
  align-items: center;
  gap: 40px;
}

.containerOnOff {
  display: flex;
  align-items: center;
  gap: 16px;
}

.containerOnOff strong {
  font-size: 0.75rem;
}

.containerButton_download {
  width: 108px;
  height: 40px;
}

.containerButton_download > button {
  width: 100%;
  height: 100%;
}
.patientStatus {
  cursor: pointer;
  color: aliceblue;
  position: absolute;
  top: 26px;
  right: 6px;
  width: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 4px 12px;
}
