.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.containerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 90px;
  gap: 3rem;
}
.containerImg {
  display: flex;
  padding-top: 48px;
  padding-bottom: 48px;
  align-items: center;
  justify-content: center;
}
.coverPhoto {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}
.containerText {
  padding-top: 30px;
  color: var(--neutral-colors-gray-river-gray-river-800, #37404e);

  font-size: 2.25rem;
  font-weight: 700;
  line-height: normal;
}

.containerSubText {
  color: var(--neutral-colors-gray-river-gray-river-800, #37404e);
  text-align: center;
  padding-top: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

.buttonMore {
  align-items: center;
  background-color: #ebeef3;
  border: none;
}
.buttonMore:hover {
  border: 1px solid #007e69;
}

.containerAulas {
  padding-top: 22px;
  padding-right: 16px;
}
.containerAula {
  width: 100%;
  border: 1px solid var(--gray-200);
  border-radius: 10px;
  padding-top: 0;
  padding-right: 16px;
  display: flex;
  width: 432px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
}

.title {
  display: flex;
  align-items: center;
  gap: 9px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.titleNav {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: normal;
}
.buttonHamburguer {
  cursor: pointer;
}

.ContainerButton {
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.ContainerButton > button {
  font-size: 1rem;
  min-width: 163px;
  max-height: 40px;
  padding: 0;
}

.containerButtons {
  display: flex;
  flex-direction: row;
  padding: 5px;
  gap: 20px;
}

.containerButtons > button {
  font-size: 1rem;
  min-width: 200px;
  max-height: 40px;
  padding: 0;
}

.containerTitle {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 20px; /* 111.111% */
}
.containerSubTitle {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.5px;
}
.containerImg {
  padding-top: 1.25rem;
  padding-bottom: 0;
}

.ContainerPadding {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.allContent {
  padding-left: 20px;
}

.courseTitle {
  font-size: 1.75rem;
}
.categoriesContainer {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.badgeContainer {
  display: flex;
  gap: 0.625rem;
}
.headerContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-bottom: 2px solid #e4e4e4;
  margin-bottom: 4rem;
  padding: 0 1rem 1rem 1rem;
}
.imageContainer {
  position: relative;
  width: 20%;
}
.descContainer {
  display: flex;
  gap: 1rem;
}
.imageFlexContainer {
  width: min-content;
  height: min-content;
}
.editContainer {
  display: flex;
  align-self: center;
  background-color: #007e69;
  width: fit-content;
  padding: 0.375rem;
  border-radius: 32px;
  cursor: pointer;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}
.courseDescContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.outerContainer {
  display: flex;
  flex-direction: column;
}
.horLine {
  border-top: 1px solid #efefef;
  padding-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.submitBtn {
  min-width: fit-content;
  padding: 0 4rem;
}
.submitContainer {
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
/* .classesContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.5rem;
} */
.addClassBtn {
  margin-left: 1rem;
}
.draggableList {
  list-style-type: none;
}
.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
