.boxContainer {
  border: 1px solid #afafaf;
  border-radius: 12px;
  border-left: 6px solid #007e69;
  width: 100%;
  padding: 0.5rem 2rem 1rem 2rem;
  background-color: #fff;
}

.heading {
  color: #7b889c;
  font-weight: 700;
  cursor: pointer;
  width: fit-content;
}
.outerContainer {
  display: flex;
}
.expandedContainer {
  display: flex;
  margin: 0 1.75rem;
}
.dndIcon {
  cursor: pointer;
}
.selectionContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}
.label {
  color: #535353;
  font-weight: 600;
  letter-spacing: 0.64px;
  position: absolute;
  margin-left: 1.5rem;
  margin-top: -0.625rem;
  background-color: white;
  z-index: 10;
}
.input {
  border-radius: 12px;
  border: 1px solid #afafaf;
  padding: 1.25rem 1rem;
  width: 100%;
}
.input::placeholder {
  color: #535353;
  font-weight: 600;
  display: flex;
}
.categories::placeholder {
  color: #afafaf;
  font-weight: normal;
}
.inputsIconContainer {
  position: relative;
  height: 100%;
}
.inputsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 1rem;
}
.textarea {
  resize: vertical;
  max-height: 8rem;
}
.textarea::placeholder {
  color: #afafaf;
  font-weight: normal;
}
.horLine {
  border-top: 1px solid #efefef;
  padding-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.paperClip {
  position: absolute;
  left: 1rem;
  top: 35%;
  color: #afafaf;
}
.iconInput {
  padding-left: 2.75rem;
}
.submitBtn {
  min-width: fit-content;
  padding: 0 3rem;
}
.submitContainer {
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.checkBoxContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.errorMsg {
  margin: 1rem 0;
  color: #f62256;
  font-weight: 600;
}
.boldTitle {
  font-weight: 600;
}
.collapseContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.editIcon {
  cursor: pointer;
}
.classesContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.5rem;
}
.draggableList {
  list-style-type: none;
}
