.checkbox_container {
  position: relative;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.checkbox_variants {
  display: flex;
  align-items: center;
}

.checkbox_parentcomponents {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: left;
  padding: 5px 0px;
  gap: 8px;
  width: 20px;
}

.text_checkbox {
  padding-left: 5px;
  font-size: 0.75rem;
  color: var(--gray-900);
}
