.container {
  position: relative;
  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.iconX {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 20px;
  height: 20px;
  color: var(--gray-400);
  cursor: pointer;
}

.iconX:hover {
  color: #c80000;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 16px;
  color: #fff;
}

.header h2 {
  font-size: 1.125rem;
}

.header p {
  font-size: 0.875rem;
  color: var(--gray-200);
  margin-top: 12px;
  text-align: center;
}

.containerImg {
  width: 60px;
  height: 60px;
}

.containerButtons {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 30px;
}

.containerButtons > button {
  padding: 16px 0;
  border-radius: 24px;
  color: #fff;
  outline: none;
  border: 0;
  cursor: pointer;
}

.containerButtons > button:first-child {
  background: none;
  border: 1px solid #e4e4e4;
}

.containerButtons > button:hover {
  background: #e4e4e4;
  border: 1px solid transparent;
  color: var(--gray-900);
}

.containerButtons > button:last-child {
  background: #007e69;
}

.containerButtons > button:last-child:hover:not([disabled]) {
  opacity: 0.8;
  color: #fff;
}

.container_buttons_loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
