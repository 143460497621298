.containerStep1 {
  display: flex;
  width: 100%;
  height: 70vh;
  justify-content: center;
}

.containerInput {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.containerStep1 input {
  background: none;
}

.borderStyle {
  width: 100%;
  height: 1px;
  background: var(--gray-200);
}

.containerStep1 input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.containerCpf {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.container_buttons_loading {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid blue;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.headerStep1 {
  display: flex;
  height: 5vh;
  gap: 15px;
}

.headerStep1 > div {
  width: 200px;
}

.formStep1 {
  position: absolute;
  margin-top: 60px;
  width: 88vh;
  height: 60vh;
}

.formStep1 p {
  font-size: 0.875rem;
}

.containerUserSenha {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.container_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  padding-bottom: 24px;
}

.buttonCancelar {
  width: 172px;
  height: 52px;
}

.buttonCancelar > button {
  width: 100%;
  height: 100%;
  color: #8e8e8e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonCancelar > button svg {
  width: 22px;
  height: 22px;
  padding-top: 4px;
}

.buttonContinuar {
  width: 172px;
  height: 52px;
}

.buttonContinuar > button {
  width: 100%;
  height: 100%;
  color: #fff;
}

.buttonContinuar > button svg {
  width: 24px;
  height: 24px;
  padding-top: 4px;
}

.buttonToJump {
  max-width: 75%;
  margin: 0 auto;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

@media (max-width: 1440px) {
  .formStep1 {
    margin-top: 7%;
  }
}

@media (max-width: 880px) {
  .iconClose {
    top: 0.5rem;
    right: 0.5rem;
    max-width: 25px;
    cursor: pointer;
    color: var(--gray-500);
  }

  .headerForm svg {
    display: none;
  }

  .headerStep1 {
    gap: 5px;
  }

  .headerStep1 > div {
    width: auto;
  }

  .headerStep1 > div p {
    font-size: 0.625rem;
    width: 100px;
  }

  .formStep1 {
    position: absolute;
    margin-top: 60px;
    width: 100%;
    height: 60vh;
    padding: 0 10px;
  }

  .containerInput {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .container_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 60px; */
    padding-bottom: 24px;
  }

  .buttonCancelar,
  .buttonContinuar {
    /* width: 65px; */
    max-width: 135px;
    width: 100%;
    height: 62px;
  }

  .buttonCancelar > button,
  .buttonContinuar > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px !important;
  }

  .buttonCancelar > button svg {
    display: block;
    width: 16px;
  }

  .buttonContinuar > button svg {
    display: none;
  }

  .buttonCancelar > button > span,
  .buttonContinuar > button > span {
    /* display: none; */
  }
}
