.modalOpenPdf {
  height: 100%;
  display: flex;
  aling-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px 0 1rem;
}

.modalOpenPdf p {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
}

.modalOpenPdf > .iconClose {
  position: absolute;
  right: 16px;
  top: 10px;
  width: 20px;
  cursor: pointer;
}

.modalOpenPdf > .iconClose:hover {
  opacity: 0.6;
  color: red;
}

.containerIcon {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  gap: 16px;
  overflow-x: scroll;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 10px;
}

.containerIcon::-webkit-scrollbar {
  height: 8px;
  background-color: none;
}

.containerIcon::-webkit-scrollbar-thumb {
  background-color: #2ebbaa;
  border-radius: 3px;
}

.containerImagePdf {
  position: relative;
  border-radius: 6px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.containerIcon img {
  max-width: 160px;
  height: 175px;
  cursor: pointer;
  border-radius: 6px;
}

/* .containerIcon img:hover {
  opacity: 0.9;
  border: 1px solid var(--green-200);
} */

.loading_container {
  margin-left: -220px;
  padding-top: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading_spinne {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #007e69;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  /* margin-top: -200px; */
}

.loading_container p {
  font-size: 0.875rem;
  margin-top: 10px;
  color: var(--gray-600);
  font-weight: 600;
}

.containerImage_active {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.11) 37.19%,
    rgba(0, 0, 0, 0) 80%
  );
  border-radius: 6px;
  cursor: pointer;
  transition: background 1s;
}

.containerImagePdf:hover .containerImage_active {
  display: block;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
