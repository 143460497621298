.containerError {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.containerImage {
  position: absolute;
  top: 16px;
  left: 16px;
}

.containerError > button {
  padding: 10px 80px;
  border: 0;
  outline: none;
  border-radius: 16px;
  margin-top: 1rem;
  font-size: 0.875rem;
  cursor: pointer;
  background: var(--gray-200);
  color: var(--gray-900);
}

.containerError > button:hover {
  background: var(--gray-300);
  color: #fff;
}

@media (max-width: 1024px) {
  .containerImage {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  h1 {
    font-size: 1.7rem;
    text-align: center;
  }
}
