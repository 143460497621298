.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-radius: 24px;
  border: 1px solid var(--gray-200);
  box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.2);
}

.containerIconClose {
  position: absolute !important;
  top: 16px !important;
  right: 26px !important;
  cursor: pointer !important;
  width: 30px !important;
  height: 30px !important;

  border-radius: 7px !important;
  background: #d3d3d3 !important;
  padding: 3px 4px 4px 4px !important;

  margin-left: auto !important;
  display: flex !important;
  align-content: center !important;
  justify-content: center !important;
  color: #37404e !important;
}

.containerIconClose:hover svg {
  color: red !important;
}

.patientStatusNull {
  background: var(--gray-400);
  color: #fff;
}

.containerAvatar {
  display: flex;
  align-items: center;
  gap: 24px;
}

.containerAvatar_image {
  width: 80px;
  box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.2);
  border-radius: 24px;
  border: 1px solid var(--gray-200);
}

.containerAvatar_image img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 24px;
  object-fit: cover;
}

.containerAvatar_content p {
  text-align: left;
}

.containerAvatar_content .id {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--gray-river-300);
}

.containerAvatar_content h2 {
  font-size: 2rem;
  color: var(--gray-river-800);
}

.containerAvatar_content strong {
  font-size: 1rem;
  font-weight: 700;
  color: var(--gray-river-500);
}

.containerContent {
  position: relative;
  display: flex;
  gap: 72px;
}

.containerContent > div {
  position: relative;
  padding: 0 24px;
}

.containerContent > div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: var(--gray-300);
}

.containerContent_description span {
  color: var(--gray-river-400);
  font-size: 0.75rem;
  font-weight: 700;
}

.containerContent_description p {
  color: var(--gray-river-800);
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
}

.containerContent_description:last-child {
  margin-top: 24px;
}

.containerIcons {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 24px;
}

.containerIcons_icon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--green-600);
}

.containerIcons_icon svg {
  width: 18px;
  color: #fff;
  cursor: pointer;
}

.patientStatus2 {
  width: 117px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 4px 10px;
  border-radius: 22px;
  background: #e3f7e7;
  color: #006a31;
  font-family: Segoe UI Variable;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.62px;
}

.patientStatusNull2 {
  background: var(--gray-400);
  color: #fff;
}

@media (max-width: 1366px) {
  .containerAvatar_content h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 880px) {
  .wrapper {
    min-width: 90vw !important;
  }

  .containerAvatar_content h2 {
    font-size: 1.3rem;
  }

  .containerIcons {
    gap: 5px;
    margin-left: -40px;
  }

  .containerAvatar_image {
    width: 135px;
  }

  .containerContent {
    gap: 20px;
  }
}

@media (max-width: 684px) {
  .containerAvatar_image {
    width: 80px;
  }

  .wrapper {
    min-width: 352px;
  }

  .containerAvatar {
    gap: 12px;
  }

  .containerAvatar_image {
    border-radius: 12px;
  }

  .containerAvatar_image img {
    border-radius: 12px;
  }

  .containerAvatar_content h2 {
    font-size: 16px;
  }

  .containerAvatar_content strong {
    font-size: 14px;
  }

  .containerIcons {
    margin-left: 0px;
  }
}

@media (max-width: 300px) {
  .wrapper {
    width: auto;
    min-width: auto;
  }

  .containerAvatar_image img {
    width: auto;
  }

  .containerAvatar_content h2 {
    font-size: 14px;
  }

  .containerAvatar_content strong {
    font-size: 12px;
  }
}
