.login {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wrapper {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 132px;
  /* padding: 46px 132px; */
}

.image {
  width: 250px;
  height: 90px;
  object-fit: contain;
}

.container_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.container_title {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 25px;
}

.container_title h1 {
  font-size: 1.5rem;
  color: var(--gray-900);
}

.container_title p {
  font-size: 1rem;
  margin-top: 8px;
  color: var(--gray-700);
  width: 382px;
}

.container_recoverPassword {
  position: relative;
  display: flex;
  justify-content: end;
  margin-top: 8px;
}

.container_recoverPassword span {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--green-700);
  cursor: pointer;
}

.containerCheckbox {
  width: 382px;
}

.container_buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.container_buttons_loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container_inputs {
  display: flex;
  flex-direction: column;
  gap: 34px;
}

.container_inputs input {
  background: none;
}

.container_createYourAccount span {
  display: inline-block;
  margin-right: 5px;
  color: var(--gray-700);
}

.container_createYourAccount a {
  text-decoration: none;
  color: var(--green-700);
  font-weight: 600;
}

.containerError {
  margin-top: 16px;
  padding: 8px;
  border-radius: 12px;
  background: #ffc8b9;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 12px;
  color: #000;
  animation: growAnimation 300ms forwards;
}

@keyframes growAnimation {
  0% {
    width: 100%;
    padding: 0px;
    opacity: 0;
  }
  100% {
    width: 100%;
    padding: 8px;
    opacity: 1;
  }
}

@media (max-width: 1440px) {
  .container_title {
    margin-top: 15px;
    margin-bottom: 40px;
  }

  .container_form {
    padding: 0px 25px;
  }

  .container_buttons {
    gap: 8px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .wrapper_form {
    padding: 0;
  }
}

@media (max-width: 1024px) {
  .image {
    width: 170px;
    height: auto;
    object-fit: contain;
  }

  .container_title {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .container_title p {
    width: 100%;
    font-size: 0.8rem;
  }

  .login {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .wrapper {
    display: block;
    gap: 0;
    padding: 46px 0;
  }

  .container_form {
    width: 100%;
    display: block;
  }

  .BoxVideoInstitutional {
    display: none;
  }

  .containerCheckbox {
    width: auto;
  }
}
