.wrapper {
  position: relative;
  width: 100%;
  text-align: center;
}

.containerTitle {
  margin: 0 auto;
  width: 90%;
}

.containerTitleTooltip {
  padding: 8px;
}

.containerTitleTooltip p {
  display: block;
  color: var(--gray-700);
  text-align: center;
}

.wrapper .svgTooltip {
  position: absolute;
  top: -10px;
  right: -5px;
  width: 18px;
  color: var(--gray-400);
}
.wrapper .svgTooltip:hover {
  opacity: 0.8;
}

 {
  position: absolute;
  top: -16px;
  right: -5px;
  width: 18px;
  color: var(--gray-400);
}

.containerTitle h2 {
  font-size: 1.125rem;
  animation: loading 3s infinite linear;
}

.containerTitle p {
  font-size: 0.875rem;
}

@keyframes loading {
  0% {
    color: #fff;
  }
  50% {
    color: var(--gray-700);
  }
  100% {
    color: #fff;
  }
}

.containerLine {
  margin-top: 16px;
}

.containerLine p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 700;
  padding: 0 5px;
}
