.wrapper {
  width: 100%;
  width: 445px;
  height: 550px;
  border: 1px solid var(--gray-200);
  border-radius: 10px;
}

@media (min-width: 1366px) {
  .wrapper {
    width: 445px;
    height: 600px;
  }
}

.container_video {
  width: 100%;
  height: 249px;
  margin-bottom: 24px;
}

.video {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
}

.content {
  justify-content: center;
  align-items: center;
  padding: 0 24px 24px;
}

.content h2 {
  font-size: 1.25rem;
  color: var(--gray-800);
}

.content p {
  margin-top: 24px;
  text-align: justify;
  leading-trim: both;
  text-edge: cap;
  color: var(--gray-800);
}

.containerbutton {
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  margin-top: 28px;
  margin-left: 67px;
  padding: 12px 72px;
  width: 228px;
  border-radius: 56px;
  background: var(--serodonto-primary-color-green-100, #ade1da);
  color: #007e69;
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  transition: background 150ms;
}

.button:hover {
  background: var(--serodonto-primary-color-green-100, #5dbbae);
}
.more {
  display: inline;
  color: var(--serodonto-primary-color-main-green-700, #007e69);
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}
@media (max-width: 1540px) {
  .button {
    margin-left: 77px;
    width: 218px;
  }
}
