.selected_row,
.selected_row td,
.selected_row td > div {
  background: rgba(222, 243, 241) !important;
}

input[type="checkbox"] {
  color: #fff;
  accent-color: #007e69;
  border-radius: 24px;
}