.selectionContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}
.label {
  color: #535353;
  font-weight: 600;
  letter-spacing: 0.64px;
  position: absolute;
  margin-left: 1.5rem;
  margin-top: -0.625rem;
  background-color: white;
  z-index: 10;
}
.inputsIconContainer {
  position: relative;
  height: 100%;
}
.input {
  border-radius: 12px;
  border: 1px solid #afafaf;
  padding: 1.25rem 1rem;
  width: 100%;
}
.input::placeholder {
  color: #535353;
  font-weight: 600;
  display: flex;
}
.textarea {
  resize: vertical;
  max-height: 8rem;
}
.textarea::placeholder {
  color: #afafaf;
  font-weight: normal;
}
.errorMsg {
  margin: 1rem 0;
  color: #f62256;
  font-weight: 600;
}
.paperClip {
  position: absolute;
  left: 1rem;
  top: 35%;
  color: #afafaf;
}
.iconInput {
  padding-left: 2.75rem;
}
.categories::placeholder {
  color: #afafaf;
  font-weight: normal;
}
