.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.containerOnOff {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
}

.containerImages {
  max-height: calc(100vh - 260px) !important;
  min-height: calc(100vh - 260px) !important;
  overflow-y: scrool;
}

.containerImages p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 0.875rem;
  padding: 5px 0;
  cursor: pointer;
  text-transform: uppercase;
}

.containerImagens {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow-y: auto;
  max-height: calc(100vh - 350px) !important;
  min-height: calc(100vh - 350px) !important;
  z-index: 1;
  padding-bottom: 1rem;
}

.containerImagens::-webkit-scrollbar {
  width: 4px;
  background-color: var(--gray-600);
  border-radius: 5px;
}

.containerImagens::-webkit-scrollbar-thumb {
  background-color: #2ebbaa;
  border-radius: 5px;
}

.containerImage {
  position: relative;
  border-radius: 16px;
  padding: 2.5px;
  background: var(--gray-700);
}

.containerImage:hover {
  cursor: pointer;
}

.containerImage_active {
  background-color: var(--green-300);
}

.containerImage img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 16px;
  object-fit: cover;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 64px;
  height: 64px;
  margin-top: -32px;
  margin-left: -32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader div {
  width: 9px;
  height: 9px;
  background-color: var(--green-200);
  border-radius: 50%;
  margin: 4px;
  animation: loader-animation 1.2s infinite ease-in-out;
}

.loader div:nth-child(1) {
  animation-delay: -0.24s;
}
.loader div:nth-child(2) {
  animation-delay: -0.12s;
}
.loader div:nth-child(3) {
  animation-delay: 0s;
}
.loader div:nth-child(4) {
  animation-delay: 0.12s;
}

@keyframes loader-animation {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.linearGradient {
  text-align: center;
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0)
  );
  display: flex;
  padding-left: 14px;
  padding-bottom: 14px;
  align-items: flex-end;
  font-size: 0.875rem;
  border-radius: 16px;
}

.linearGradient span {
  position: absolute;
  width: 100%;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  color: var(--gray-100);
  font-weight: 600;
  font-size: 0.75rem;
}

.WrapperNotResult {
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
  font-size: 0.75rem;
  color: var(--gray-500);
  text-align: center;
}

@media (max-width: 880px) {
  .img {
    max-width: 90px;
    max-height: 90px;
  }
}
