.modal {
  width: 30%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  padding: 24px;
}
.title {
  font-weight: 600;
}
.description {
  margin: 0.9rem 0 2rem 0;
  color: #666666;
}
.titleXContainer {
  display: flex;
  justify-content: space-between;
}
.XIcon {
  cursor: pointer;
}
.input {
  border-radius: 12px;
  border: 1px solid #afafaf;
  padding: 1.25rem 1rem;
  width: 100%;
  color: #535353;
  font-family: SegoeUi;
  font-size: 1rem;
}
.input::placeholder {
  color: #535353;
  font-weight: 600;
  display: flex;
}

.input:focus-within {
  outline: 2px solid #afafaf;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 25vh;
}
.textarea {
  resize: vertical;
  max-height: 8rem;
}
.btnContainer {
  border-top: 1px solid #e4e4e4;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
}
.btn {
  flex-shrink: 1;
  min-width: fit-content;
  width: 50%;
}
.label {
  color: #535353;
  font-weight: 600;
  letter-spacing: 0.64px;
  position: absolute;
  margin-left: 1.5rem;
  margin-top: -0.625rem;
  background-color: white;
  z-index: 10;
}
.categories::placeholder,
.coverContainer::placeholder {
  color: #afafaf;
  font-weight: normal;
}
.helperText {
  color: #535353;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}
.selectionContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}
.coverContainer {
  border: 1px dashed #afafaf;
  padding-left: 2.75rem;
}
.inputsIconContainer {
  position: relative;
  height: 100%;
}
.searchIcon {
  position: absolute;
  right: 1rem;
  top: 35%;
}
.arrowUpIcon {
  position: absolute;
  left: 1rem;
  top: 35%;
  color: #afafaf;
}
.channelContainer {
  border: 1px dashed #afafaf;
}
.channelContainer::placeholder {
  color: #535353;
}
.errorMsg {
  color: #f62256;
  font-weight: 600;
}
.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
