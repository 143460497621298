.wrapper {
  height: 100%;
  margin-top: 35px;
}

.wrapper nav ul {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
}

.wrapper nav ul li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding: 8px 12px;
}

.wrapper nav ul li svg {
  color: #fff;
}

.wrapper nav ul li.linkActive {
  display: flex;
  align-items: center;
  gap: 2px;
  border-radius: 14px 14px 0px 0px;
  background: var(--gray-800);
}

.wrapper nav ul li.linkActive span {
  font-size: 0.875rem;
  font-weight: 600;
}

.wrapper nav ul li svg {
  width: 24px;
  height: 24px;
  opacity: 0.6;
}

.wrapper_renderContent {
  height: calc(100vh - 230px);
  height: 95%;
  overflow-y: hidden;
  background: var(--gray-800);
  border-radius: 12px 12px 0px 0px;
}

.renderContent {
  height: auto;
  padding: 16px;
  background: var(--gray-800);
  border-radius: 12px 12px 0px 0px;
}

@media (max-width: 880px) {
  .wrapper_renderContent {
    border-radius: 0px;
  }
}
