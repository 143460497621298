.wrapper {
  width: 100%;
  height: 88px;
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-700);
  padding-right: 400px;
}

.containerLogo {
  display: flex;
  align-items: center;
  gap: 5px;
}

@media (max-width: 1400px) {
  .containerLogo {
    gap: 9px;
  }
}

.groups {
  width: 100%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--gray-800);
  border-radius: 12px;
}

.containerIconLogo {
  padding: 0 5px;
}

.containerIconLogo > img {
  width: 40px;
}

.containerIcon {
  width: 45px;
  height: 45px;
  background: transparent;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1400px) {
  .groups {
    padding: 5px 6px;
  }

  .containerIcon {
    width: 40px;
    height: 40px;
  }
}

.containerIcon > button {
  min-width: 100%;
  min-height: 100%;
  border-radius: 12px;
  border: none;
  cursor: pointer;
}

.containerIcon > button {
  background: transparent;
}

.containerIcon > button:hover:not([disabled]) {
  background: #535353 !important;
}

.containerIcon > button:disabled svg {
  opacity: 0.2;
  cursor: not-allowed;
}

.containerIcon svg {
  width: 26px !important;
  height: 26px !important;
  color: #fff;
}

.buttonSelected {
  background: #fff !important;
}

.buttonSelected img {
  filter: invert(1) brightness(0) saturate(100%);
}

.container_onOffAndButton {
  display: flex;
  align-items: center;
  gap: 20px;
}

.buttonPatientProfile {
  width: 108px;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 20px;
  background: var(--green-800);
  color: #fff;
  font-size: 0.875rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.buttonPatientProfile svg {
  width: 18px;
  height: 18px;
}

.buttonPatientProfile:hover {
  background: var(--green-900);
}

.containerOnOff {
  display: flex;
  align-items: center;
  gap: 16px;
}

.containerOnOff strong {
  font-size: 0.75rem;
}

.containerButton_download {
  width: 108px;
  height: 40px;
}

.containerButton_download > button {
  width: 100%;
  height: 100%;
  font-size: 0.875rem;
}

.containerDoc:hover {
  opacity: 0.8;
}

.containerDoc button div > svg {
  width: 26px;
  height: 26px;
}

.container_buttons_loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

.buttonIa > button {
  width: 53px;
  height: 53px;
  background: var(--gray-800);
  border-radius: 12px;
  border: none;
  color: #fff;
  cursor: pointer;
}

.buttonIa > button:hover {
  background: #fff;
  color: #000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1367px) {
  .wrapper {
    padding-right: 350px;
  }

  .container_onOffAndButton {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .buttonPatientProfile {
    max-width: 90px;
    max-height: 40px;
    font-size: 0.75rem;
  }
}
