.wrapperListOfModels {
  position: relative;
  padding: 16px;
  padding-bottom: 60px;
  min-height: calc(100vh - 110px);
  overflow: hidden;
}

.input {
  min-width: 356px;
}

.containerHeader {
  display: flex;
  align-items: center;
  gap: 20px;
}

.containerButton > button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  border-radius: 20px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "Segoe UI Variable" !important;
  font-weight: 600 !important;
  height: 100% !important;
  color: #007e69 !important;
  background-color: transparent !important;
  border: 1px solid var(--green-700) !important;
  transition: 100ms !important;
  cursor: pointer !important;
  padding: 8px 20px !important;
}

.containerButton > button:hover {
  background-color: var(--green-900) !important;
  border: 1px solid transparent;
  color: #fff !important;
}

.loadingMessage {
  max-height: calc(100vh - 220px);
}

.containerTextUserFalse {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.1rem;
  color: #afafaf;
}

.modelsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
  margin-top: 20px;
}

.modelCard {
  width: 220px;
  height: 240px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  /* overflow: hidden; */
  position: relative;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modelContent {
  width: 100%;
  height: 90%;
  transform: scale(1);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box !important;
}

.modelName {
  position: relative !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  text-align: center;
  font-size: 14px;
  color: #353535;
  background-color: #f8f8f8;
  width: 100%;
  font-weight: 600;
  border-radius: 0 0 16px 16px;
}

.modelName svg {
  cursor: pointer;
  z-index: 2;
}

.modelName svg:hover {
  color: #00ac98;
}

.modelName span {
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

/* Sobrescrevendo estilos do HTML injetado */
.modelContent img {
  max-width: 40px !important;
  height: 20px !important;
  position: absolute !important;
}

.modelContent .footer img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.modelContent h2,
.modelContent p,
.modelContent h1 {
  margin: 0;
  padding: 0;
  font-size: 10px !important;
  text-align: center;
}

.containerFooter {
  position: absolute;
  bottom: -16px;
  left: 0;
  width: 100%;
  background: #fff;
}

.overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 90px);
  z-index: 1;
}

.optionCard {
  position: absolute;
  bottom: -65px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 172px;
  width: 100%;
  z-index: 2;
  background-color: white;
  border-radius: 0px 0px 16px 16px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 10px;
}

.optionCard > div {
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 5px;
  cursor: pointer;
}

.optionCard div:last-child {
  border-radius: 0px 0px 16px 16px;
}

.optionCard > div:hover {
  background: var(--neutral-colors-gray-gray-50, #f8f8f8);
}

.optionCard svg {
  fill: #64748b;
}

@media (max-width: 880px) {
  .containerHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .input {
    min-width: 100%;
  }

  .containerHeader .containerButton {
    order: -1;
    margin-left: auto;
  }

  .wrapperListOfModels {
    margin: 20px;
    margin-top: 100px;
    min-height: calc(100vh - 105px) !important;
    overflow: visible;
    padding-bottom: 100px;
  }

  .loadingMessage {
    height: calc(100vh - 265px);
  }

  .modelsContainer {
    align: center;
    justify-content: center;
  }

  .containerFooter {
    padding-bottom: 10px;
  }
}
