.aside {
  width: 280px;
  min-height: 100vh;
  height: auto;
  background-color: #fff;
  padding: 16px 0;
  transition: width ease-out 200ms;
  border-right: 1px solid #ebecf0;
  padding-bottom: 0px;
  position: fixed;
  z-index: 9999;
}

.aside_collapse {
  width: 72px;
  min-height: 100vh;
  height: auto;
  min-height: 100vh;
  background-color: #fff;
  padding: 28px 0;
  transition: width ease-out 200ms;
  border-right: 1px solid #ebecf0;
  position: fixed;
  z-index: 9999;
}

.aside nav {
  width: 100%;
}

.aside nav ul {
  list-style: none;
  padding: 0;
}

.containerHeaderCollapse {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 0 27px;

  /* width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  padding: 19px 16px;
  border-bottom: 1px solid var(--gray-200);
  background: white;
  position: fixed;
  top: 0;
  z-index: 999;
  margin-bottom: 4rem;
  border: 2px solid red; */
}

.containerHeaderCollapse svg {
  width: 22px;
  color: #298e79;

  /* padding-top: 2px; */
}

.containerHeaderCollapse img {
  width: 44px;
  height: 44px;

  /* position: absolute;
  top: 50% !important;
  transform: translateY(-50%);
  right: 10px !important;
  width: 44px;
  height: 44px; */
}

.buttonHamburguer {
  cursor: pointer;
}

.containerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0 19px;
}

.containerHeader > a {
  text-decoration: none;
}

.containerHeader div {
  display: flex;
  align-items: center;
  gap: 9px;
}

.containerHeader strong {
  text-transform: uppercase;
  color: #151515;
  font-weight: 700;
  font-size: 0.75rem;
}

.containerHeader svg {
  width: 22px;
  color: #298e79;
}

.sectionTitle {
  font-size: 0.75rem;
  font-weight: 600;
  color: #b0b9c6;
  margin: 0;
  margin-bottom: 15px;
  padding: 0 19px;
  text-transform: uppercase;
}

.li {
  position: relative;
  height: 100%;
  margin-bottom: 32px;

  cursor: pointer;
  padding-right: 20px;
  color: #57667a;
  font-size: 0.875rem;
}

.containerTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 11px;
  margin-left: 8px;
  transition: all ease-out 100ms;
}

.containerTitle_open {
  background-color: #e0f3f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 11px;
  margin-left: 8px;
  transition: all ease-out 100ms;
}

.containerIcon {
  display: flex;
  align-items: center;
  gap: 2px;
}

.containerIcon_open {
  display: flex;
  align-items: center;
  gap: 2px;
  color: #298e79;
}

.containerChildren {
  display: none;
  position: relative;
  height: 100%;
}

.childBorder {
  position: absolute;
  width: 1px;
  height: calc(100% - 18px);
  top: 0;
  left: 0;
  border-left: 2px solid #cfdaec;
}

.containerChildren > a {
  position: relative;
  color: #57667a;
  text-decoration: none;
}

.containerChildren > a:hover {
  color: #1565c0;
}

.containerChildren_open {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 18px;
  margin-left: 28px;
  padding-left: 20px;
}

.containerIconCollapse {
  position: relative;
  height: 48px;
  display: flex;
  gap: 10px;
  align-items: center;
  align-items: center;
  padding-right: 50px;
  margin-top: 17px;
  padding: 0 27px;
  transition: background ease-out 150ms;
  border-radius: 12px;
  cursor: pointer;
  background: transparent;
}

.containerIconCollapse_showLinkCollapse {
  position: relative;
  height: 48px;
  display: flex;
  gap: 10px;
  align-items: center;
  align-items: center;
  padding-right: 50px;
  margin-top: 17px;
  padding: 0 27px;
  transition: background ease-out 150ms;
  border-radius: 12px;
  cursor: pointer;
  background: transparent;
  background-color: var(--green-50);
}

.containerIconCollapse:hover {
  background-color: var(--green-50);
  border-radius: 12px;
}

.containerIconCollapse svg {
  width: 20px;
  height: 20px;
}

.containerLinkCollapse {
  position: absolute;
  display: block;
  width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  right: -200px;
  padding: 0;
  background-color: #fafafa;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  z-index: 100;
}

.containerLinkCollapse > a {
  display: block;
  position: relative;
  color: #57667a;
  text-decoration: none;
  font-size: 1rem;
  padding: 10px 15px;
  transition: background 100ms;
}

.containerLinkCollapse > a:hover {
}

.containerLinkCollapse_showLinkCollapse {
  display: none;
}

.container_createYourAccounts {
  width: 100%;
  padding-top: 7px;
  display: flex;
  /* align-items: center; */
  /* margin-left: 19px; */
  gap: 2px;
  color: rgb(87, 102, 122);
}

.container_createYourAccounts svg {
  color: rgb(87, 102, 122);
}

.footerCollapse {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
}

.footerCollapse p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  gap: 5px;
  color: var(--gray-500);
}

.footerCollapse .containerLogos {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  gap: 1rem;
  padding-bottom: 10px;
}

.footerCollapse .containerLogos > img {
  width: 80px;
}

@media (max-width: 880px) {
  .containerLinkCollapse_showLinkCollapse {
    display: block;
  }

  @keyframes headerAnimation {
    0% {
      width: 0;
      opacity: 0;
    }
    50% {
      width: 10000pc;
      opacity: 0.8;
    }
    100% {
      width: 10000pc;
      opacity: 1;
    }
  }

  .aside {
    position: relative;
    z-index: 999;
    width: 10000pc;
    animation: headerAnimation 1s;
    /* border: 2px solid red; */
    padding-top: 10px;
  }

  .aside_collapse {
    visibility: hidden;
    width: 0;
  }

  .containerIconCollapse_showLinkCollapse {
    border-radius: 0;
  }
}

@media print {
  .aside,
  .aside_collapse {
    display: none !important;
  }
}

@media (max-width: 880px) {
  .containerHeaderCollapse {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 16px 16px;
    border-bottom: 1px solid var(--gray-200);
    background: white;
    position: fixed;
    top: 0;
    z-index: 999;
  }

  .containerHeaderCollapse svg {
    position: absolute;
    top: 58% !important;
    transform: translateY(-50%);
    left: 15px;
    width: 22px;
    color: red;
  }

  .containerHeaderCollapse img {
    position: absolute;
    top: 58% !important;
    transform: translateY(-50%);
    right: 20px !important;
    width: 180px !important;
    height: 34px !important;
  }

  .aside nav {
    width: 100%;
    padding-top: 3rem;
  }

  .container_createYourAccounts {
    width: auto;
    gap: 9px;
    font-size: 16px;
  }
  .containerHeaderCollapse img {
    position: absolute;
    top: 0;
    right: 20px;
    width: auto;
    height: 44px;
  }
}
