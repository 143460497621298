.react_modal_Send_Request {
  max-width: 800px;
  width: 100%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  border: none;
  outline: none;
  padding: 24px;
}

.xIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 3%;
  left: 95%;
  cursor: pointer;
}

.xIconWrapper svg {
  display: flex;
  width: 20px;
  height: 20px;
  color: #8e8e8e;
}

.xIconWrapper svg:hover {
  color: #000000;
  transform: scale(1.1);
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 40px;
  color: #57667a;
  font-size: 14px;
}

.textContainer p {
  text-align: left !important;
}

.textContainer h2 {
  color: #151515;
  font-size: 18px;
}

.wrapper_input {
  position: relative;
  width: 100%;
  padding-bottom: 24px;
  display: grid;
}

.label {
  color: #535353;
  font-weight: 600;
  letter-spacing: 0.64px;
  position: absolute;
  margin-left: 1.5rem;
  margin-top: -0.625rem;
  background-color: white;
  z-index: 10;
}

.label span {
  color: #8e8e8e;
}

.requestName,
.requestDescription {
  width: 100%;
  border-radius: 12px;
  border: 1px solid #afafaf;
  background-color: #fff;
  padding: 1.25rem 1rem;
  width: 100%;
  color: #535353;
  font-family: "SegoeUi";
  font-size: 1rem;
}

.requestName:focus-within {
  border: 1px solid #afafaf;
  box-shadow: none;
  outline: none;
}

.buttonsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 16px;
  margin-top: 32px;
}

.containerButtonSave,
.containerButtonCancel {
  width: 100%;
  max-width: 175px;
}
.containerButtonSave > button,
.containerButtonCancel > button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  border-radius: 20px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "Segoe UI Variable" !important;
  font-weight: 600 !important;
  height: 100% !important;
  transition: 200ms !important;
  cursor: pointer !important;
  padding: 10px 20px !important;
  width: 100% !important;
}

.containerButtonSave > button {
  background: var(--serodonto-primary-color-green-800, #006e5a) !important;
  color: #fff !important;
  border: none;
}

.containerButtonSave > button:hover {
  background-color: var(--colors-green800) !important;
  opacity: 0.9 !important;
}

.containerButtonCancel > button {
  border: 1px solid var(--neutral-colors-gray-gray-500, #8e8e8e) !important;
  background: var(--neutral-colors-white, #fff) !important;
  color: var(--neutral-colors-gray-gray-500, #8e8e8e) !important;
}

.containerButtonCancel > button:hover {
  background-color: var(--neutral-colors-gray-gray-500, #8e8e8e) !important;
  color: #fff !important;
}

.error {
  color: red;
}
