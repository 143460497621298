.container {
    width: 100%;
}

.textButtons {
    display: grid;
}

.textButton {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 21px 20px;
    border-bottom: 0.6px solid #E4E4E4;
    cursor: pointer;
}

.textButton:hover {
    background-color: #E4E4E4;
}

.Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.textContent {
    display: flex;
    align-items: center;
    gap: 4px;
}

.textButton h3 {
    font-size: 14px;
    font-weight: 600;
    color: #919DAF;
}

.textButton p {
    font-size: 16px;
    font-weight: 600;
    color: #8E8E8E;
}

footer {
    height: 70px;
}