.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.containerInputOpcoesBasicas {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.containerInputOpcoesBasicas span {
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  aling-items: center;
  justify-content: space-between;
}

.containerInputOpcoesBasicas span span {
  color: white;
}

.containerInputOpcoesBasicas:first-child {
  margin-bottom: 35px;
}

.containerInputOpcoesBasicas input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  width: 100%;
  height: 0.5em;
  position: relative;
  border-radius: 5px;
  background-color: var(--green-50);
  outline: none;
}

.containerButtons {
  display: flex;
  gap: 16px;
  margin: 30px 0;
}

.containerButtons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin: 30px 0;
}

.containerButton {
  height: 40px;
  font-size: 12px;
}

.containerButton > button {
  min-width: 145px;
  height: 40px;
  background: var(--green-700);
  border-radius: 56px;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #fff;
  cursor: pointer;
}

.containerButton:first-child > button svg {
  transform: rotate(90deg);
}

.containerButton > button:hover {
  background: var(--green-800);
}

.containerButton3dPosition {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;
  margin: 30px 0;
}

.containerButton3dPosition > button svg {
  width: 20px !important;
  color: var(--gray-900);
}

.containerButton3dPosition > button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray-50);
  height: 45px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  border: 0;
  transition: all 300ms;
}

.containerButton3dPosition > button:hover {
  background: var(--green-800);
  color: #fff;
}

@media (max-width: 880px) {
  .containerButtons button {
    display: none;
  }
}
