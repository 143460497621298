.container {
  padding: 10px;
  display: grid;
  justify-content: center;
}

.containerIMG {
  padding-top: 10px;
  width: 100%;
  height: 300px;
}

.video {
  width: 100%;
  height: 100%;
}
.containerButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.containerButton > button {
  font-size: 1rem;
  min-width: 106px;
  max-height: 40px;
  padding: 0;
  margin-top: 10px;
}

.buttons {
  display: flex;
  width: 124.235px;
  justify-content: center;
  align-items: center;
  gap: 7.765px;
  flex-shrink: 0;
  color: #919daf;
  background-color: #ffffff;
  border-radius: 15.529px;
  margin-top: 10px;
}
.containertexton {
  color: #007e69;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
}
.containerInfoTeacher {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 89px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #e4e4e4;
}
.containerSelect {
  display: flex;
  justify-content: space-between;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
}
.containerLine {
  height: 2px;
  flex-shrink: 0;
  align-self: stretch;
  background: var(--serodonto-primary-color-main-green-700, #007e69);
  margin-bottom: 20px;
}

.containerTitle {
  padding-top: 20px;
  color: var(--neutral-colors-gray-gray-600, #666);
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}
.buttonMore {
  color: #007e69;
  background-color: #ffffff;
  border: 1px solid #007e69;
}

.buttonMore:hover {
  background-color: #ffffff;
  border: 1px solid #007e69;
}
.containerAnimationOn {
  color: var(
    --serodonto-primary-color-main-green-700,
    var(--serodonto-primary-color-main-green-700, #007e69)
  );
  width: 140px;
  cursor: pointer;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.containerAnimationOff {
  width: 140px;
  cursor: pointer;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: var(
    --neutral-colors-gray-gray-500,
    var(--neutral-colors-gray-gray-500, #8e8e8e)
  );
  text-align: center;

  font-size: 16px;

  font-weight: 400;
}
.containerTraco {
  border: none;
  border-top: 1px dashed #666;
  color: #333;
  overflow: visible;
  text-align: center;
  height: 5px;
  width: 39%; /* Defina a largura desejada aqui */
}
.containerTracoText {
  display: flex;
  flex-direction: row;
  padding-right: 15px;
  padding-left: 15px;
}
.containerInfoExtra {
  margin-right: 10px;
  padding: 16px;
  align-items: center;
  gap: 89px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #e4e4e4;
  width: 400px;
  height: 90px;
  flex-shrink: 0;
  justify-content: center;
}
.containerInfoExtraContent {
  align-items: center;

  display: flex;
  justify-content: space-between;
}

.containerSubtitle {
  padding-top: 20px;
  color: #535353;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.5px;
  padding-bottom: 20px;
}
.containerHeaderCollapse {
  padding: 30px;
}

@media(max-width: 800px) {
  .containerHeaderTitle {
    padding: 30px;
  }
}

@media (max-width: 540px) {
  .containerIMG {
    width: 435px;

    height: 250px;
  }
}
@media (max-width: 440px) {
  .containerIMG {
    width: 385px;
    height: 229px;
  }
}

@media (max-width: 380px) {
  .containerHeaderTitle {
    padding-left: 9px;
    min-width: 320px;
  }
  .containerIMG {
    width: 316px;

    height: 192px;
  }
}
