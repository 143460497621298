.containerExamsRequest {
  width: 100%;
  height: 100%;
  padding: 16px;
  display: grid;
  grid-template-areas: "exam model";
  grid-template-columns: 55% 1fr;
  gap: 2rem;
  padding-bottom: 2rem;
  /* grid-template-rows: 420px 420px 300px 40px; */
}

.wrapperComponentsEditor {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerExam {
  grid-area: exam;
  min-height: 100vh;
  height: auto;
}

.containerModel {
  grid-area: model;
  width: 100%;
  border-bottom: 1px solid var(--gray-300);
  padding-bottom: 2rem;
}

.containerInputs {
  width: 100%;
  grid-area: inputs;
}

.containerStyle {
  width: 100%;
  grid-area: style;
}

.containerFooter {
  width: 100%;
  grid-area: footer;
}

.errorText {
  color: red;
  padding: 16px;
}

@media (max-width: 1450px) {
  .containerInputs,
  .containerModel {
    padding: 16px;
  }
}

@media (max-width: 1024px) {
  .containerExamsRequest {
    grid-template-columns: 50% 50%;
    grid-template-areas:
      "model inputs"
      "exam exam"
      "style style"
      "footer footer";
    grid-template-rows: 250px 1150px 245px 40px;
  }

  .containerStyle {
    justify-self: center;
  }

  .containerInputs,
  .containerModel {
    width: 100%;
  }
}

@media print {
  .containerStyle,
  .containerInputs,
  .containerFooter,
  .containerModel {
    display: none;
  }

  .containerExam {
    margin: 0 auto;
  }

  .containerExamsRequest {
    grid-template-columns: 1fr;
    place-content: center;
    gap: 0px;
  }
}
