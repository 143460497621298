.containerGeral {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.iconClose {
  position: absolute;
  top: 1rem;
  right: 1rem;
  max-width: 35px;
  cursor: pointer;
  color: var(--gray-500);
}

.containerImagem {
  width: 40%;
  border-radius: 0 12% 0 12%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerImagem img {
  max-width: 75%;
  display: block;
}

.containerForm {
  width: 60%;
  height: 100vh;
}

.headerForm {
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 3%;
}

.headerForm p {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 10px;
}

.container_buttons {
  display: flex;
  flex-direction: row;
  gap: 40vh;
  justify-content: center;
  margin-top: 10%;
}

.buttonCancelar {
  width: 20vh;
  height: 5vh;
}

.buttonCancelar button {
  width: 100%;
  height: 100%;
}

.buttonContinuar {
  width: 20vh;
  height: 5vh;
}

.buttonContinuar button {
  width: 100%;
  height: 100%;
}

.loading_container {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading_container p {
  text-align: center;
  font-size: 0.875rem;
  margin-top: 10px;
  color: var(--gray-600);
  font-weight: 600;
}

.loading_spinne {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #007e69;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.containerImage {
  position: absolute;
  top: 16px;
  left: 16px;
}

@media (max-width: 1440px) {
  .containerImagem {
    display: none;
  }
  .iconClose {
    top: 0.5rem;
    right: 0.5rem;
    max-width: 25px;
    cursor: pointer;
    color: var(--gray-500);
  }

  .headerForm {
    margin-top: 2.5rem;
  }

  .headerForm svg {
    display: none;
  }
  .containerGeral {
    display: block;
  }
  .containerForm {
    width: 100%;
    height: 100vh;
  }
}

@media (max-width: 880px) {
  .iconClose {
    top: -1.2rem;
    right: 0.5rem;
    max-width: 25px;
    cursor: pointer;
    color: var(--gray-500);
  }

  .headerForm {
    margin-top: 2.5rem;
  }

  .headerForm svg {
    display: none;
  }
  .containerGeral {
    display: block;
  }

  .loading_container {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .loading_container p {
    font-size: 0.75rem;
    text-align: center;
  }

  .loading_spinne {
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-left: 3px solid #007e69;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
}
