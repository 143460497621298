.header {
  position: relative;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 12px;
  padding: 24px 20px;
  border-bottom: 1px solid var(--gray-100);
  -webkit-box-shadow: 0px 0px 20px 3px rgba(1, 1, 1, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(1, 1, 1, 0.2);
}

.containerIconClose {
  position: absolute;
  top: 16px;
  right: 16px;
}

.header h2 {
  font-size: 1.25rem;
  color: #fff;
}

.header svg {
  cursor: pointer;
  color: #fff;
  width: 20px;
}

.header svg:hover {
  opacity: 0.8;
}

.containerCalibrarIcon {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #666;
  cursor: default;
}

.containerCalibrarIcon svg {
  cursor: default;
}

.containerCalibrarIcon svg:hover {
  color: #fff;
}

.containerIconDragAndDrop {
  position: absolute;
  top: 5px;
  left: 50%;
}

.containerSelect p {
  margin-top: 8px;
  font-size: 0.75rem;
  color: var(--gray-200);
}

/* table */
.tableContainer {
  max-height: calc(100vh - 220px);
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 2px;
  margin-right: 2px;
  margin-top: 2px;
}

.tableContainer::-webkit-scrollbar {
  width: 6px;
  background-color: none;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: var(--gray-200);
  border-radius: 5px;
}

.tableModalOfFactors {
  width: 100%;
  height: 90% !important;
  margin-top: 16px;
  border-collapse: collapse;
}

.tableModalOfFactors th {
  width: 100%;
  background: var(--gray-700);
  border-bottom: 1px solid #666;
  padding-bottom: 1rem;
  text-align: center;
  font-size: 0.875rem;
  color: #fff;
}

.tableModalOfFactors td {
  width: 100%;
  background: var(--gray-700);
  text-align: center;
  padding: 1rem 0;
  font-size: 0.875rem;
  color: #fff;
  border-bottom: 0.5px solid var(--gray-100);
}

.tableModalOfFactors thead tr {
  display: grid;
  grid-template-columns: 1fr repeat(2, 0.7fr) 1fr 0.7fr 0.8fr;
}

.tableModalOfFactors tbody tr {
  display: grid;
  grid-template-columns: 1fr repeat(2, 0.7fr) 1fr 0.7fr 0.8fr;
  cursor: pointer;
}

.react_modal_overlay_2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.loading_container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading_spinne {
  border: 3px solid #fff;
  border-left: 3px solid #007e69;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-top: 20%;
}

.loading_container p {
  font-size: 0.875rem;
  margin-top: 10px;
  color: var(--gray-200);
  font-weight: 600;
  border: 1px solid transparent;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
