.containerOpcoes {
  height: auto;
  min-height: auto;
  max-height: calc(100vh - 270px);
  overflow-y: auto;
  padding-right: 10px;
}

.containerOpcoes::-webkit-scrollbar {
  width: 4px;
  background-color: var(--gray-600);
  border-radius: 5px;
}

.containerOpcoes::-webkit-scrollbar-thumb {
  background-color: #2ebbaa;
  border-radius: 5px;
}

.containerOpcoes p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.containerInputOpcoesBasicas {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.containerInputOpcoesBasicas span {
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  aling-items: center;
  justify-content: space-between;
}

.containerInputOpcoesBasicas span span {
  color: white;
}

.containerInputOpcoesBasicas {
  margin-bottom: 25px;
}

.containerInputOpcoesBasicas:last-child {
  margin-bottom: 5px;
}

.containerInputOpcoesBasicas input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  width: 100%;
  height: 0.5em;
  position: relative;
  border-radius: 5px;
  background-color: var(--green-50);
  outline: none;
}

.containerOnOff {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.containerOnOff .buttonTrash {
  width: 44px;
  height: 22px;
  border-radius: 56px;
  border: none;
  background: var(--green-800);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.containerOnOff .buttonTrash:hover {
  background: var(--green-900);
}

.containerOnOff strong {
  font-size: 0.875rem;
}
