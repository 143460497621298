.container {
  position: relative;
  padding: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.iconClose {
  position: absolute;
  top: 18px;
  right: 16px;
  width: 20px;
  height: 20px;
  color: #8e8e8e;
  cursor: pointer;
}

.iconClose:hover {
  opacity: 0.7;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success {
  display: flex;
  width: 56px;
  height: 56px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 28px;
  border: 8px solid #e3f7e7;
  background: #bdecc4;
  margin-bottom: 16px;
}

.fault {
  display: flex;
  width: 56px;
  height: 56px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 28px;
  border: 8px solid var(--gray-river-50);
  background: var(--gray-river-100);
  margin-bottom: 16px;
}

.error {
  display: flex;
  width: 56px;
  height: 56px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 28px;
  border: 8px solid var(--red-50);
  background: var(--red-100);
  margin-bottom: 16px;
}

.header .success svg {
  color: var(--green-600);
}

.header .fault svg {
  color: var(--gray-river-600);
}

.header .error svg {
  color: var(--red-900);
}

.header strong {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--gray-900);
}

.header p {
  font-size: 0.875rem;
  color: var(--gray-river-600);
  margin-top: 0.75rem;
  text-align: center;
}

.buttonContainer {
  width: 100%;
  display: flex;
  gap: 8px;
  margin-top: 2rem;
  height: 48px;
  border-radius: 56px;
  padding-bottom: 24px;
}

.buttonContainer > button {
  border-radius: 56px;
  outline: none;
  border: 0;
  cursor: pointer;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 24px;

  font-weight: 600;
  color: var(--gray-600);
}

.buttonContainer > button:hover {
  opacity: 0.8;
}

.button {
  min-width: 100%;
}

.buttonSucess {
  background: var(--gray-100);
  color: var(--gray-river-700);
}

.buttonSucess:hover {
  background: var(--gray-200) !important;
}

.backButton,
.cancelButton {
  min-width: 172px;
  max-height: 48px;
}

.backButton {
  border: 1px solid var(--gray-500);
  color: var(--gray-500);
  background: transparent;
}

.backButton:hover {
  border: 1px solid var(--gray-500);
  color: var(--gray-500);
  background: transparent;
}

.cancelButton {
  border: none;
  color: var(--red-900);
  background: var(--red-50);
}

.cancelButton:hover {
  background: var(--red-100) !important;
}

@media (max-width: 640px) {
  .header strong {
    font-size: 1rem;
  }

  .header p {
    font-size: 0.7rem;
    text-align: center;
    color: var(--gray-river-600);
    margin-top: 0.75rem;
  }
}
