.containerNoResults span {
  display: block;
  font-size: 0.75rem;
  color: var(--gray-500);
  text-align: center;
}

.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.containerImages {
  max-height: calc(100vh - 290px) !important;
  min-height: calc(100vh - 290px) !important;
  overflow-y: scrool;
}

.containerImagens {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow-y: auto;
  max-height: calc(100vh - 277px);
  z-index: 1px;
  padding-bottom: 1rem;
}

.containerImagens::-webkit-scrollbar {
  width: 4px;
  background-color: var(--gray-600);
  border-radius: 5px;
}

.containerImagens::-webkit-scrollbar-thumb {
  background-color: #2ebbaa;
  border-radius: 5px;
}

.containerImage {
  position: relative;
  border-radius: 16px;
  padding: 2px;
  background: #000;
}

.containerImage:hover {
  cursor: pointer;
}

.containerImage_active {
  background-color: var(--green-700);
}

.containerImage img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 16px;
  object-fit: cover;
}
