.asideCefX {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 340px;
  height: 100vh;
  border-left: 1px solid var(--gray-700);

  position: absolute;
  top: 0;
  right: 0;
  z-index: 999.1;
  background: #000;
  transition: width ease-out 150ms;
}

.tiposDePontos {
  width: 100%;
  position: relative;
  margin-top: auto;
  z-index: 2px;
  height: auto;
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  gap: 18px;
  background: var(--gray-800);
  justify-content: center;
  padding-left: 16px;
}

.tiposDePontos .wrapper_tiposDePontos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.tiposDePontos .wrapper_tiposDePontos span {
  font-size: 0.875rem;
}

.point {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
}
