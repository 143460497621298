.wrapper_input {
  position: relative;
  width: 20%;
  height: 40px;
  border-radius: 80px;
  padding: 8px 16px;
  display: flex;
}
.searchIcon {
  z-index: 10;
}
.searchInput {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 80px;
  padding: 0 16px;
  padding-left: 44px;
  position: absolute;
  outline: none;
  border: none;
  background-color: #efefef;
}
.inputsContainer {
  margin: 10px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: nowrap;
}
.selectOption {
  z-index: 10;
  border-radius: 80px;
  border: 0;
  outline: none;
  width: 15%;
}
.selectContainer {
  display: flex;
  align-items: center;
}
.playIcon {
  margin: 0 10px;
}
.cardsContainer {
  display: flex;
  margin: 10px;
  gap: 10px;
}
.cardContainer {
  border-radius: 16px;
  background-color: #efefef;
  padding: 16px;
  width: 100%;
}
.cardTitleContainer {
  display: flex;
  justify-content: space-between;
}
.amountsContainer {
  display: flex;
  gap: 16px;
}
.amount {
  color: #007e69;
  font-weight: 600;
}
.value {
  color: #353535;
  margin: 10px 0 16px 0;
}
.subcardContainer {
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  margin: 8px 0;
}
.subcardRed {
  border-left: 4px solid #fc3117;
}
.subcardBlue {
  border-left: 4px solid #1e88e5;
}
.subcardTitleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.subcardMenuSelect {
  cursor: pointer;
}

.subcardBadgeContainer {
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 5px;
  margin: 16px 0;
  padding: 8px;
  background-color: #ebeef3;
  border-radius: 24px;
}
.subcardBadgeName {
  color: #37404e;
}
.subcardAvatar {
  border-radius: 16px;
}
.subcardStatusBadgeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px 4px 7px;
  width: fit-content;
  border-radius: 24px;
  gap: 5px;
}
.subcardStatusBadgeTitle {
  font-weight: 600;
}
.subcardStatusBadgeMQ {
  color: #c80000;
}
.subcardStatusBadgeMorno {
  color: #006a00;
}
.subcardStatusBadgeCMQ {
  background-color: #ffc8b9;
}
.subcardStatusBadgeCMorno {
  background-color: #bdecc4;
}
.draggableList {
  list-style-type: none;
}
