.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  border-radius: 24px;
  border: 1px solid var(--gray-200);
  box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.2);
  margin: 10px;
  gap: 70px;
}

.containerAvatar_content h2 {
  font-size: 2rem;
  color: var(--gray-river-800);
}

.containerContent {
  position: relative;
  display: flex;
  gap: 70px;
}

.containerContent > div {
  position: relative;
  padding-left: 70px;
}

.containerContent > div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: var(--gray-300);
}

.containerContent_description span {
  color: var(--gray-river-400);
  font-size: 0.75rem;
  font-weight: 700;
}

.containerContent_description p {
  color: var(--gray-river-800);
  font-size: 1rem;
  font-weight: 600;
}

.containerContent_description:last-child {
  margin-top: 24px;
}

@media (max-width: 1366px) {
  .containerAvatar_content h2 {
    font-size: 1.5rem;
  }
}
.timeBadge {
  position: absolute;

  top: 0;
  right: 2%;
  padding: 6px 10px;
  color: #465364;
  font-weight: 600;
  border-radius: 0px 0px 8px 8px;
  background: var(--neutral-colors-gray-river-gray-river-50, #ebeef3);
  margin: 0 10px 10px 0;
}
.iconContainer,
.clockIconContainer {
  display: inline;
  margin-right: 8px;
}
.patientsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}
.avatars {
  display: flex;
  flex-direction: row;
  gap: -10px;
}
.avatar {
  border-radius: 16px;
  position: relative;
  border: 0.5px solid #f8f8f8;
}
.avatar1 {
  margin-left: -3px;
}
.avatar2 {
  margin-left: -5px;
}
.cardSubtitle {
  color: #666666;
  font-weight: 600;
}
.examsContainer {
  margin-top: 32px;
}
.examsText {
  color: #7b889c;
  font-weight: 700;
}
.examsBadge {
  display: flex;
  flex: 1;
  max-width: fit-content;
  padding: 4px 12px;
  font-weight: 600;
  border-radius: 12px;
}
.tomografiaBadge {
  color: #d72c69;
  background-color: #fddae6;
  flex-wrap: nowrap;
}
.raioxBadge {
  color: #078295;
  background-color: #bcf0f8;
}
.atmBadge {
  color: #1565c0;
  background-color: #e1e6ef;
}
.chartContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chartTopContainer {
  margin: 5px;
  display: flex;
  column-gap: 10px;
}
.chartLineUpContainer {
  background-color: #def3f1;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.chartTitle {
  font-weight: 600;
  color: #00523d;
}
.chartSubtitle {
  color: #353535;
}
.chartTopTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  position: relative;
  top: -5px;
}
.graphWrapper {
  width: 40%;
}
.graphContainer {
  display: flex;
  align-items: center;
  column-gap: 30px;
  margin-top: 20px;
  width: 100%;
  min-width: 0;
}
.meioDeCaptacao {
  color: #151515;
  margin-bottom: 20px;
}
.meioDeCaptacaoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.chartLabel {
  color: #151515;
  margin: 5px 0;
  display: flex;
  justify-content: flex-start;
  gap: 0 5px;
}
.chartAmount {
  font-weight: 700;
  color: #535353;
}
.chartLabelPercentage {
  color: #009c86;
  font-weight: 600;
}
.chartType {
  color: #151515;
}
.barChartContainer {
  display: flex;
}
.barChart {
  width: 30%;
}
.labelContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
.labelTitle {
  font-weight: 600;
}
.patientsAmount {
  color: #666666;
}
.createdAtContainer {
  position: absolute;
  bottom: 5%;
  right: 3%;
  color: #666666;
}
.topBarContainer {
  display: flex;
  justify-content: space-between;
  margin: 16px;
  flex-wrap: wrap;
}
.sendMessageBtn {
  padding: 10px 16px;
  background-color: #007e69;
  border: 0;
  border-radius: 56px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}
.sendMessageBtn:hover {
  background-color: #006252 !important;
  transition: all 0.2s;
}
.wrapper_input {
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 80px;
  padding: 8px 16px;
  display: flex;
  flex-shrink: 0;
}
.searchIcon {
  z-index: 10;
}
.searchInput {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 80px;
  padding: 0 16px;
  padding-left: 44px;
  position: absolute;
  outline: none;
  border: none;
  background-color: #efefef;
}
.selectOption {
  z-index: 10;
  border-radius: 80px;
  border: 0;
  outline: none;
}
.topBarLeftContainer {
  display: flex;
  gap: 20px;
}
.selectContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.container_table table {
  border-collapse: collapse;
}
.container_table {
  margin: 10px;
}

.container_table table th {
  width: 100%;
  background-color: var(--gray-50);
  padding: 1rem;
  text-align: left;
  font-size: 0.875rem;
  color: var(--gray-river-700);
  display: flex;
  justify-content: flex-start;
}

.container_table table th:first-of-type {
  padding: 1rem;
}

.container_table table th:last-child {
  text-align: center;
}

.container_table table th > div {
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 4px;
}

.container_table table td {
  width: 100%;
  background-color: #fff;
  padding: 1rem;
  text-align: left;
  font-size: 0.875rem;
  color: var(--gray-700);
  border-bottom: 0.5px solid var(--gray-100);
  display: flex;
  justify-content: flex-start;
}

.container_table table td:last-child {
  text-align: center;
}

.container_table table tr:has(.selectedTd) {
  background-color: pink;
}

@media (max-width: 1366px) {
  .container_table table td {
    font-size: 0.75rem;
  }
}

.container_table table td > div {
  display: flex;
  align-items: center;
  gap: 4px;
}
.container_table table {
  width: 100%;
}

.container_table table th,
td {
  width: auto !important;
}

.patientsTable thead tr {
  display: grid;
  grid-template-columns: 0.2fr repeat(8, 1fr);
}

.patientsTable tbody tr {
  display: grid;
  grid-template-columns: 0.2fr repeat(8, 1fr);
}

.patientsTable tbody a {
  text-decoration: none;
}
.examsTDContainer {
  flex-wrap: wrap;
}
.atmSecondaryBadge {
  background-color: #f2dfee;
  color: #b83d9e;
}
.warningBadge {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  font-weight: 600;
  border-radius: 12px;
  background-color: #ffc8b9;
  color: #c80000;
  justify-content: center;
  flex-shrink: 0;
}
.warningBadgeContainer {
  display: flex;
  margin-right: 5px;
}

.examsBadgeMargin {
  margin: 8px 8px 0 0;
}
.titleName {
  color: #535353;
  font-weight: 600;
}
.barChartLabelsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 1450px) {
  .createdAtContainer {
    font-size: 0.7rem;
  }
  .timeBadge {
    font-size: 0.7rem;
  }
  .container_table table td {
    padding: 8px;
  }

  .container_table table tr td:nth-child(4) {
    padding-left: 40px;
  }
}
