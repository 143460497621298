.wrapperHeader {
  padding: 0 16px;
}

.header {
  position: relative;
  background: rgba(44, 44, 45, 0.5);
  padding: 0px 4px;
  border-radius: 16px;
  margin-top: 16px;
  height: 65px;
  display: flex;
  align-items: center;
}

.iconClose {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.iconClose:hover {
  opacity: 0.8;
}

.header > div {
  display: flex;
  align-items: center;
  gap: 16px;
}

.containerLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.header > div img {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  object-fit: cover;
}

.header span {
  font-size: 0.875rem;
  opacity: 0.8;
  display: block;
  text-align: center;
  animation: loading 4s infinite linear;
}

.containerInfoPacient {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  /* border: 1px solid red; */
}

.containerIconClose {
  cursor: pointer !important;
  width: 30px !important;
  height: 30px !important;

  border-radius: 7px !important;
  opacity: 0px !important;
  background: #535353 !important;
  padding: 3px 4px 4px 4px !important;

  display: flex !important;
  align-items: end !important;
  justify-items: end !important;
  opacity: 1 !important;
}

.containerIconClose:hover svg {
  color: red !important;
}

@keyframes loading {
  0% {
    color: #fff;
  }
  50% {
    color: #666;
  }
  100% {
    color: #fff;
  }
}

.containerInfo {
  width: 185px;
}

.containerInfo h2 {
  font-size: 1rem;
}

.containerInfo strong {
  font-size: 0.75rem;
  color: var(--gray-300);
}

@media (max-width: 880px) {
  .containerIconClose {
    display: none !important;
  }
}
