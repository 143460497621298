.wrapper {
  margin-top: 24px;
  padding-bottom: 42px;
}

.containerButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.containerButtons_select_show {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: end;
  gap: 16px;
  animation: fadeInAndScaleUp 300ms;
}

@keyframes fadeInAndScaleUp {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.containerButton_print > button .containerButton_delete > button,
.containerButton_download > button,
.containerButton_share > button,
.containerButton_addImage > button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  border-radius: 20px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "Segoe UI Variable" !important;
  font-weight: 600 !important;
}

.containerButton_delete {
  width: 150px;
  height: 40px;
}

.containerButton_delete > button {
  width: 100%;
  height: 100%;
  border: 1px solid var(--red-900);
  color: var(--red-900) !important;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  border-radius: 20px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "Segoe UI Variable" !important;
  font-weight: 600 !important;
}

.containerButton_delete > button:hover {
  background: var(--red-900) !important;
  border: o;
  color: #fff !important;
}

.containerButton_download {
  width: 150px;
  height: 40px;
}

.containerButton_download > button {
  width: 100%;
  height: 100%;
  border: 1px solid var(--blue-800);
  color: var(--blue-800) !important;
}

.containerButton_download > button:hover {
  border: 0;
  color: #fff;
  background: var(--blue-800) !important;
}

.containerButton_print {
  width: 150px;
  height: 40px;
}

.containerButton_print > button {
  width: 100%;
  height: 100%;
  border: 1px solid var(--green-700);
  color: var(--green-700) !important;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  border-radius: 20px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "Segoe UI Variable" !important;
  font-weight: 600 !important;
}

.containerButton_print > button:hover {
  background: var(--green-900) !important;
  border: 0;
  color: #fff !important;
}

.containerButton_share {
  width: 195px;
  height: 40px;
}

.containerButton_share > button {
  width: 100%;
  height: 100%;
  background-color: var(--green-700);
  color: #fff !important;
}

.containerButton_share > button:hover {
  background-color: var(--green-900) !important;
  color: #fff !important;
}

.containerButton_addImage {
  width: 235px;
  height: 40px;
}

.containerButton_addImage > button {
  width: 100%;
  height: 100%;
  color: #007e69 !important;
  background-color: transparent;
  border: 1px solid var(--green-700);
  transition: 200ms;
}

.containerButton_addImage > button:hover {
  background-color: var(--green-900) !important;
  border: none;
  color: #fff !important;
}

.wrapperImg {
  margin-top: 24px;
}

.wrapper_imagens {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
}

.wrapper_active {
  padding: 12px;
  background: red;
}

.wrapperImage {
  position: relative;
  max-width: 200px;
  height: 200px;
  max-height: 225px;
  border-radius: 16px;
  border: 1px solid var(--gray-200);
  background: #fff;
  cursor: pointer;
}

.wrapperImage:hover {
  border: 1px solid var(--gray-400);
  transition: all 0.5s ease;
}

.containerImage_active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.67) 0%,
    rgba(0, 0, 0, 0.11) 37.19%,
    rgba(0, 0, 0, 0) 80%
  );
  border-radius: 16px;
}

.containerImage {
  height: 136px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 432px !important;
  max-height: 320px !important; */
}

.containerImage img {
  width: 80px !important;
  height: 80px !important;
  display: block;
  border-radius: 16px;
  /* object-fit: contain; */
}

.inputCheckbox {
  position: absolute;
  border: 1px solid #fff;
  width: 22px;
  height: 22px;
  top: 20px;
  left: 20px;
  border-radius: 8px;
  clip-path: circle(62%);
}

.containerContentImage {
  padding: 8px 5px;
  border-top: 1px solid var(--gray-200);
  text-align: center;
  font-size: 1rem;
}

.containerContentImage strong {
  color: var(--gray-800);
}

.containerContentImage p {
  font-size: 0.75rem;
  color: var(--gray-500);
}

.container_buttons_loading {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100% !important;
  height: 100% !important;
  font-size: 0.75rem;
}

.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid blue;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 684px) {
  .containerButton_addImage,
  .containerButton_delete,
  .containerButton_download,
  .containerButton_share,
  .containerButton_print {
    max-width: 40px !important;
    max-height: 40px !important;
  }

  .buttonMobile {
    /*     display: flex;
    align-items: center;
    justify-content: center; */
    padding: 1px !important;
    border-radius: 56px !important;
    font-size: 20px !important;
    min-width: 0px !important;
  }

  .containerImage {
    width: 168px;
    height: 150px !important;
  }

  .containerImage img {
    object-fit: contain !important;
  }

  .imageFloatingText {
    backdrop-filter: blur(0.7px);
  }
}

@media (max-width: 550px) {
  .wrapper_imagens {
    grid-template-columns: 1fr 1fr;
  }
  .containerImage {
    width: 100%;
    height: 80%;
  }

  .containerContentImage {
    min-height: 100px;
    padding: 4px 22px 7px 14px;
    font-size: 14px;
  }
}

@media (max-width: 280px) {
  .containerTop,
  .containerButtons_select_show,
  .containerButtons {
    gap: 5px;
  }

  .containerContentImage {
    font-size: 12px;
  }
}
