.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 24px 10px;
}

.header svg {
  position: absolute;
  top: 18px;
  right: 16px;
  width: 20px;
  height: 20px;
  color: #8e8e8e;
  cursor: pointer;
}

.container_content {
  text-align: center;
}

.container_content h2 {
  font-size: 1.125rem;
}

.container_content p {
  font-size: 0.875rem;
  color: #57667A;
  margin-top: 5px;
}

.container_input {
  padding: 24px 24px 24px;
}

@media (max-width: 720px) {
  .container_input {
    padding-bottom: 30px;
  }
}

.container_button {
  padding: 0 24px;
  padding-bottom: 24px;
}

.container_buttons_loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
