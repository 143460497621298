.iconClose {
  position: absolute;
  top: 40px;
  right: 16px;
  width: 25px;
  height: 25px;
  color: #ffff;
  cursor: pointer;
  z-index: 10;
}

.header {
  position: absolute;
  top: -50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  left: 50%;
  transform: translateX(-50%);
  right: 16px;
}

.header > button {
  width: 30px;
  height: 30px;
  background: var(--green-600);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  font-size: 14px;
  border: none;
  border-radius: 6px;
}

.header > button:hover {
  background: var(--green-700);
}

.header > button svg {
  width: 16px;
}

.main {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  aling-items: center;
  justify-content: center;
}

.imageSelected {
  width: 100vw;
  height: calc(100vh - 290px);
  position: relative;
  top: 42%;
  transform: translateY(-42%);
  left: 0;
}

.imageSelected img {
  width: 100vw;
  height: calc(100vh - 290px);
  display: block;
  object-fit: contain;
}

.modal {
  margin-top: auto;
  padding-bottom: 35px;
}

.imageCarrosel {
  max-width: 152px;
  height: 105px;
}

.imageCarrosel img {
  width: 152px;
  height: 105px;
  display: block;
  object-fit: cover;
  border-radius: 12px;
  border: 2px solid #000;
}

.selectedImage {
  border: 2px solid #fff !important;
}
