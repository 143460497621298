.container {
  min-height: calc(100vh - 250px);
  margin-top: 10px;
}

.container_amountOfCustomers {
  display: flex;
  justify-content: end;
  color: var(--gray-800);
  font-weight: 600;
  letter-spacing: 0.64px;
  margin-bottom: 16px;
  padding-right: 10px;
}

.container_table table {
  border-collapse: collapse;
}

.container_table table th {
  width: 100%;
  background-color: var(--gray-50);
  padding: 1rem;
  text-align: left;
  font-size: 0.875rem;
  color: var(--gray-river-700);
}

.container_table th {
  width: 100%;
  background-color: var(--gray-50);
  padding: 1rem;
  text-align: left;
  font-size: 0.875rem;
  color: var(--gray-river-700);
}

.container_table table th:last-child {
  text-align: center;
}

.container_table table th > div {
  display: flex;
  align-items: left;
  gap: 4;
}

.container_table table td {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 16px;
  text-align: left;
  font-size: 0.875rem;
  color: var(--gray-700);
  border-bottom: 0.5px solid var(--gray-100);
}

.container table td:last-child {
  justify-content: center;
}

.container_table table td:last-child {
  text-align: center;
}

.container_table table td > div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.titleClass {
  display: flex;
  align-items: center;
  gap: 2px;
  border-radius: 24px;
  font-size: 0.75rem;
  font-weight: 600;
}

.atendido {
  background: var(--green-50);
  color: #008a00 !important;
}
.entregue {
  background: #b0e2f1;
  color: #008a00 !important;
}

.container_table table {
  width: 100% !important;
}

.container_table table th,
td {
  width: 100% !important;
}

.container_table table tbody tr,
.container_table table thead tr {
  display: grid;
  /* grid-template-columns: 0.3fr 4fr 0.5fr 0.7fr 0.4fr 0.4fr; */
  grid-template-columns: 0.3fr 3.5fr 1fr 1fr 1fr;
}

.container_table table tbody td:last-child,
.container_table table thead th:last-child,
.container_table table tbody td:nth-child(5),
.container_table table thead th:nth-child(5) {
  justify-content: center;
}

.container_table table thead th:nth-child(5) {
  display: flex;
  justify-content: center;
}

.container_table table tbody td > svg {
  cursor: pointer;
}

.container_table table tbody td > svg:hover {
  transform: scale(1.1);
}

.noSymbolIcon:hover {
  color: #e01f0c;
}

.thContent {
  display: flex;
  align-items: center;
  gap: 5px;
}

.container_table table tbody a {
  text-decoration: none;
}
.upDownIconsContainer {
  display: flex;
  flex-direction: column;
  margin-left: 7px;
  justify-content: center;
}
.upDownIcons {
  cursor: pointer;
}

.situationCard {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-weight: 600;
  border-radius: 100px;
  background-color: #f8f8f8;
  padding: 6px 12px;
}

.situationSuccess {
  color: #008a00;
}

.situationNoSuccess {
  color: #e01f0c;
}

@media (max-width: 1584px) {
  .container_table table tbody tr,
  .container_table table thead tr {
    /* grid-template-columns: 0.3fr 3.5fr 0.5fr 0.7fr 0.4fr 0.4fr; */
    grid-template-columns: 0.3fr 3.5fr 1fr 1fr 1fr;
  }
}

@media (max-width: 1500px) {
  .container_table table tbody tr,
  .container_table table thead tr {
    /* grid-template-columns: 0.3fr 3.5fr 0.5fr 0.7fr 0.4fr 0.4fr; */
    grid-template-columns: 0.3fr 3.5fr 1fr 1fr 1fr;
  }

  .container_table table tbody tr td {
    font-size: 0.8rem;
  }
}

@media (max-width: 1410px) {
  .container_table table tbody tr td {
    font-size: 0.75rem;
  }

  .container_table table td:nth-child(1) {
    font-size: 0.7rem;
  }

  .container_table table th {
    font-size: 0.78rem;
  }

  .container_table table tbody tr {
    /* grid-template-columns: 0.3fr 3.5fr 0.5fr 0.7fr 0.4fr 0.4fr; */
    grid-template-columns: 0.3fr 3.5fr 1fr 1fr 1fr;
  }

  /*   .container_table table thead tr {
    grid-template-columns: 1.8fr 1.3fr .1fr 1.3fr .3fr .3fr .3fr;
  } */
}

@media (max-width: 1182px) {
  .container_table table tbody tr,
  .container_table table thead tr {
    /* grid-template-columns: 0.3fr 2fr 0.5fr 0.8fr 0.4fr 0.4fr; */
    grid-template-columns: 0.3fr 3.5fr 1fr 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .container_table table tbody tr,
  .container_table table thead tr {
    /* grid-template-columns: 0.3fr 1fr 0.4fr 0.7fr 0.2fr 0.2fr; */
    grid-template-columns: 0.3fr 3.5fr 1fr 1fr 1fr;
  }
}

@media (max-width: 500px) {
  .container_table table {
    max-width: 100vw !important;
    padding-bottom: 3rem;
    border: 1px solid red;
    margin-top: 100px;
  }

  .container_table table tbody tr,
  .container_table table thead tr {
    /* grid-template-columns: 0.3fr 3.5fr 0.5fr 0.7fr 0.4fr 0.4fr; */
    grid-template-columns: 0.1fr 1fr 1fr 0.5fr;
  }

  .container_table th:nth-child(2) {
    display: none;
  }

  .container_table tr td:nth-child(2) {
    display: none;
  }

  /* .container_table table tbody tr,
  .container_table table thead tr {
    grid-template-columns: 1.4fr 0.8fr !important;
  } */
}

@media (max-width: 280px) {
  .container_table table tbody tr,
  .container_table table thead tr {
    grid-template-columns: 1fr 0.2fr !important;
  }

  .container_amountOfCustomers {
    padding-right: 30px;
  }
}
