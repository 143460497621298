.header {
  position: relative;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 12px;
  padding: 24px 20px;
  border-bottom: 1px solid var(--gray-100);
  -webkit-box-shadow: 0px 0px 20px 3px rgba(1, 1, 1, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(1, 1, 1, 0.2);
}

.containerIconClose {
  position: absolute;
  top: 16px;
  right: 16px;
}

.header h2 {
  font-size: 1.25rem;
  color: #fff;
}

.header svg {
  cursor: pointer;
  color: #fff;
  width: 20px;
}

.header svg:hover {
  opacity: 0.8;
}

.containerHelpIcon {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #666;
}

.containerHelpIcon svg {
  cursor: default;
}

.containerIconDragAndDrop {
  position: absolute;
  top: 5px;
  left: 50%;
}

.containerSelect p {
  margin-top: 8px;
  font-size: 0.75rem;
  color: var(--gray-200);
}

.containerContent {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  height: 80%;
}

.containerContent > img {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: container;
}

.footer {
  display: block;
  height: auto;
  margin-top: auto;
  align-self: end;
}

.footer_info {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 5px;
  background: var(--gray-200);
  color: var(--gray-800);
  border-radius: 0 0 12px 12px;
  border: 1px solid var(--gray-500);
}

.footer .point {
  width: 10px;
  height: 10px;
  background: var(--red-900);
  border-radius: 56px;
}

.footer p {
  font-size: 1.125rem;
  font-weight: 600;
}

.react_modal_overlay_2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.textoSemPontoMarcado {
  text-align: center;
  font-weight: bold;
  font-size: 1.125rem;
  border: 1px solid transparent;
  margin-top: -30px;
}
