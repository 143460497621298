.asideView {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 340px;
  height: 100vh;
  border-left: 1px solid var(--gray-700);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999.1;
  background: #000;
  transition: width ease-out 150ms;
}

.containerIcons {
  position: absolute;
  top: 75px;
  left: -15px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 999;
}

.containerIcons svg {
  color: #fff;
}

.ButtonXMark {
  width: 25px;
  height: 25px;
  background: var(--red-900);
  border-radius: 56px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerIcons .buttonChevronRight {
  width: 25px;
  height: 25px;
  background: var(--green-700);
  border-radius: 56px;
  padding: 5px;
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerIcons .buttonArrowPath {
  width: 25px;
  height: 25px;
  background: var(--blue-900);
  border-radius: 56px;
  padding: 5px;
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerIcons .buttonArrowPath:not(:disabled):hover {
  opacity: 0.9;
  cursor: pointer;
}

.asideClose {
  transform: translate(60%, 8%);
  padding-top: 10px;
}

@media (max-width: 880px) {
  .asideView {
    width: 300px;
    max-height: 100vh;
  }
}
