.mainContainer {
  position: absolute;
  top: 5.25rem;
  right: 1%;
  padding: 24px 20px;
  border-radius: 24px;
  border: 1px solid var(--gray-200);
  background: #fff;
  box-shadow: 0px 2px 24px -8px rgba(142, 137, 165, 0.2);
  z-index: 999;
}
.heading1 {
  font-weight: 600;
  color: #252c37;
  font-size: 1.2rem !important;
}
.profilePic {
  border-radius: 80px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.topContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.subheading {
  color: #64748b;
  font-size: 0.8rem !important;
}
.profileDetailsContainer {
  margin: 0.5rem 0;
  text-align: center;
}
.bodyContainer {
  margin-top: 2rem;
}
.settings {
  text-align: left !important;
  color: #7b889c;
  font-weight: 700;
  font-size: 0.875rem !important;
}
.selectionsContainer {
  display: flex;
  flex-direction: column;
}
.cardContainer {
  border-radius: 16px;
  display: flex;
  gap: 50px;
  padding: 14px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 0.1rem 0;
}
.cardContainer:hover {
  background-color: #f8f8f8;
  transition: all 0.3s;
}
.cardMainText {
  text-align: left !important;
  font-size: 0.875rem !important;
  font-weight: 600;
  color: #37404e;
}
.cardSubText {
  color: #7b889c;
  font-size: 0.75rem !important;
}
.iconContainer {
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 56px;
  width: 32px;
  height: 32px;
}
.cardLeftContainer {
  border-radius: 16px;
  display: flex;
  gap: 10px;

  align-items: center;
}
.cardLeftContainers {
  border-radius: 16px;

  gap: 10px;

  align-items: center;
}

