.containerStep3 {
  display: flex;
  width: 100%;
  height: 50vh;
  justify-content: center;
}

.textInputContainer {
  position: relative;
  height: 50px;
  min-height: 50px;
  width: 100%;
}

.containerStep3 input {
  background: none;
}

.borderStyle {
  width: 100%;
  height: 1px;
  background: var(--gray-200);
  margin-top: 12px;
}

.label {
  position: absolute;
  left: 16px;
  top: 0%;
  background-color: white;
  transform: translate(0px, -50%);
  color: var(--gray-700);
  pointer-events: none;
  transition: 0.2s;
  font-weight: 600;
  line-height: 0.9rem;
  z-index: 2;
}

.headerStep3 {
  display: flex;
  height: 5vh;
  gap: 15px;
}

.formStep3 {
  position: absolute;
  margin-top: 5%;
  width: 88vh;
  height: 60vh;
}

.containerInputs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.containerRegistrationAndCode {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.containerRegistrationAndCode p {
  height: 32px;
  background: #def3f1;
  color: #00523d;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
}

.containerUserSenha {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.buttonAdd {
  width: 50px;
  height: 50px;
}

.buttonAdd > button {
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonAdd > button svg {
  width: 31px;
  color: #fff;
}

.container_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 24px;
}

.buttonCancelar {
  width: 172px;
  height: 52px;
}

.buttonCancelar > button {
  width: 100%;
  height: 100%;
  color: #007e69;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #007e69;
}

.buttonCancelar > button:hover {
  color: #fff;
  border: 1px solid #fff;
  background-color: #007e69 !important;
}

.buttonCancelar > button:hover svg {
  color: #fff;
}

.buttonCancelar > button svg {
  width: 24px;
  height: 24px;
  padding-top: 4px;
}

.buttonContinuar {
  width: 172px;
  height: 52px;
}

.buttonContinuar > button {
  width: 100%;
  height: 100%;
  color: #fff;
}

.buttonContinuar > button svg {
  width: 24px;
  height: 24px;
  padding-top: 4px;
}

.container_buttons_loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

.disabled {
  background-color: #afafaf !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 1440px) {
  .formStep3 {
    margin-top: 7%;
  }
}

@media (max-width: 880px) {
  .iconClose {
    top: 0.5rem;
    right: 0.5rem;
    max-width: 25px;
    cursor: pointer;
    color: var(--gray-500);
  }

  .container_buttons_loading {
    width: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading_spinne {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-left: 2px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }

  .headerForm svg {
    display: none;
  }

  .headerStep3 {
    gap: 5px;
  }

  .headerStep3 > div {
    width: auto;
  }

  .headerStep3 > div p {
    font-size: 0.625rem;
    width: 100px;
  }

  .formStep3 {
    position: absolute;
    margin-top: 60px;
    width: 100%;
    height: 60vh;
    padding: 0 10px;
  }

  .formStep3 p {
    font-size: 0.875rem;
  }

  .containerInputs {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 26px;
  }

  .containerInputs > .wrapperInput {
    display: flex;
    gap: 8px;
  }

  .container_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding-bottom: 24px;
  }

  .buttonCancelar,
  .buttonContinuar {
    /* width: 65px; */
    max-width: 135px;
    width: 100%;
    height: 62px;
  }

  .buttonCancelar > button,
  .buttonContinuar > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px !important;
  }

  .buttonCancelar > button svg,
  .buttonContinuar > button svg {
    display: block;
    width: 16px;
    /* margin: -10px; */
  }

  .buttonCancelar > button span,
  .buttonContinuar > button span {
    /* display: none; */
  }
}
