:root {
  --green-50: #e0f3f1;
  --green-100: #b2e0da;
  --green-200: #7fcec3;
  --green-300: #49baaa;
  --green-400: #34ab98;
  --green-500: #2e9b86;
  --green-600: #298e79;
  --green-700: #237d69;
  --green-800: #1e6d5a;
  --green-900: #13513d;

  --sucess-green-100: #bdecc4;
  --sucess-green-800: #008a00;

  --gray-50: #f8f8f8;
  --gray-100: #efefef;
  --gray-200: #e4e4e4;
  --gray-300: #d3d3d3;
  --gray-400: #afafaf;
  --gray-500: #8e8e8e;
  --gray-600: #666;
  --gray-700: #535353;
  --gray-800: #353535;

  --gray-river-50: #ebeef3;
  --gray-river-300: #919daf;
  --gray-river-400: #7b889c;
  --gray-river-500: #64748b;
  --gray-river-600: #57667a;
  --gray-river-700: #465364;
  --gray-river-800: #37404e;
  --gray-river-900: #252c37;

  --red-50: #fde7e6;
  --red-100: #ffc8b9;
  --red-900: #c80000;

  --blue-800: #1565c0;
  --blue-900: #0d47a1;
}

/* swipper carrosel imagens visualizador quando estiver no mobile */


.swiper-button-next,
.swiper-button-prev {
  background: rgba(0, 0, 0, 0.48);
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 14px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* fade biblioteca CSSTransition */

CSSTransition .fade-enter {
  height: 0;
  opacity: 0;
}

.fade-enter-active {
  height: auto;
  opacity: 1;
  transition: height 1000ms, opacity 1000ms;
}

.fade-exit {
  height: auto;
  opacity: 1;
}

.fade-exit-active {
  height: 0;
  opacity: 0;
  transition: height 1000ms, opacity 1000ms;
}

.hidden {
  display: none;
}

/* Estilizando a imagem dentro do PinchZoom */
.pinch-zoom-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pinch-zoom-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.react-tooltip-lite {
  background: #333;
  color: white;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "SegoeUi";
  src: url("../fonts/SegoeUi/SegoeUIVF.ttf");
  font-weight: normal;
  font-style: normal;
}

body,
html {
  background-color: #fff;
  font-family: "SegoeUi", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

body::-webkit-scrollbar {
  width: 11px;
  background-color: none;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--gray-300);
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--gray-400);
}

@media (max-width: 880px) {
  body::-webkit-scrollbar {
    width: 0;
  }
}

body,
input,
textarea,
button {
  font: 400 1rem "SegoeUi", sans-serif;
}

/* Class */

.react-switch {
  vertical-align: middle;
  margin-left: 0px;
}

.patient-info-container {
  margin-top: 100px !important; /* Ajuste o valor conforme necessário para o espaçamento correto */
  border: 1px solid blue;
}

/* Modal */
.react_modal_overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100%;
  z-index: 1000;
  border: none;
}

.react_modal_overlay_noBackground {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
  width: 100vw;
  height: 100%;
  z-index: 1000;
  border: none;
}

.react_modal_overlayIa {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vw;
  z-index: 1000;
  border: none;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.no-overlay {
  background: none;
}

.react-modal-ia,
.react-modal-processIa {
  z-index: 1001 !important;
}

.react_modal_overlayPdf {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100%;
  z-index: 100000;
  border: none;
}

.react_modal_overlay-viewImageMobile {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.93);
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.react-modal-videopPopup {
  width: calc(80vw - 300px);
  height: calc(100vh - 126px);
  background: #efefef;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  outline: none;
  border: none;
}

.react-modal-shareProfile_image {
  width: 800px;
  height: calc(100vh - 120px);
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  border: none;
  outline: none;
  padding: 0;
  overflow: hidden;
}

.react-modal-shareProfile_image .modal-content {
  height: calc(100vh - 120px);
  padding: 32px 24px 24px;
  overflow-y: auto;
  scroll-behavior: initial;
}

.react-modal-shareProfile_image .modal-content::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.react-modal-shareProfile_image .modal-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.react-modal-shareProfile_image .modal-content::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.react-modal-shareProfile {
  width: 800px;
  height: 660px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  border: none;
  outline: none;
  padding: 16px;
  overflow: hidden;
}
.react-modal-shareProfiles {
  width: 800px;
  height: 577px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  border: none;
  outline: none;
  padding: 16px;
  overflow: hidden;
}

.react-modal-VerifyEmail {
  width: 400px;
  height: 340px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  border: none;
  outline: none;
}

.dropdown-heading {
  min-height: 50px !important;
  max-height: auto !important;
  background-color: #fff !important;
  border: 1px solid #afafaf !important;
  border-radius: 12px !important;
  padding-left: 16px !important;
  outline: none !important;
}

.dropdown-container[aria-expand="true"] .dropdown-heading,
.dropdown-container:focus-within .dropdown-heading {
  border: 2px solid #237d69 !important;
  box-shadow: 0 0 1px #237d69 !important;
}

.dropdown-container,
.dropdown-container:hover,
.dropdown-container:focus,
.dropdown-container:active,
.dropdown-container[aria-expand="true"] {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-content {
  z-index: 2 !important;
}

@media (max-width: 600px) {
  .dropdown-container,
  .dropdown-heading {
    height: 100px;
  }
}

@media (max-width: 768px) {
  .react-modal-content {
    width: 70%;
  }

  .react-modal-VerifyEmail {
    width: 95%;
  }
}

.react-modal-registerBriefing {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  height: 825px;
  background: #ffff;
  padding: 24px;
  border-radius: 16px;
}

.react-modal-ErrorOrSucess {
  width: 400px;
  /* height: 260px; */
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  box-shadow: 2px 2px 16px -4px rgba(34, 34, 34, 0.2);
  outline: none;
  border: none;
}

@media (max-width: 640px) {
  .react-modal-ErrorOrSucess {
    width: calc(100vw - 20px);
    height: 270px;
  }
}

.react-modal-exit {
  width: 400px;
  height: 280px;
  background: #535353;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  border: none;
  box-shadow: 2px 2px 16px -4px rgba(34, 34, 34, 0.2);
  outline: none;
  border: none;
}

.react-modalOpen-viewBriefing {
  position: absolute;
  width: calc(100vw - 136px);
  min-height: 100vh;
  height: auto;
  right: 0;
  top: 0;
  background-color: #fff;
  border-radius: 4px;
  animation: modalOpenAnimation 0.3s ease-in-out forwards;
  opacity: 0;
  outline: none;
}

.react-modalOpen-viewBriefing:focus {
  outline: none;
  box-shadow: none;
}

@keyframes modalOpenAnimation {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.react-modal-ia {
  display: flex;
  flex-direction: column;
  width: 504px;
  height: 222px;
  background: var(--gray-700);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  z-index: 1000;
  color: #fff;
  animation: slideDown 0.5s ease-out;
}

@keyframes slideDown {
  from {
    top: -50%;
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.react-modal-processIa {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  height: 165px;
  background: var(--gray-700);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  padding: 32px 24px;
  z-index: 999;
  color: #fff;
  outline: none;
  animation: slideDown 0.4s ease-out;
}

@keyframes slideDown {
  from {
    top: -50%;
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

.react-modal-AddAnalyze {
  width: 689px;
  height: calc(100vh - 100px);
  background: var(--gray-700);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  z-index: 1000;
  color: #fff;
}

.react-modal-AddAnalyze .modalAddAnalyze-content {
  height: calc(628px - 180px);
  overflow-y: auto;
  scroll-behavior: initial;
}

.react-modal-AddAnalyze .modalAddAnalyze-content::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.react-modal-AddAnalyze .modalAddAnalyze-content::-webkit-scrollbar-thumb {
  background-color: #2ebbaa;
  border-radius: 10px;
}

.react-modal-AddAnalyze .modalAddAnalyze-content::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.react-modal-Analyze {
  width: 552px;
  height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
  background: var(--gray-700);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  z-index: 1000;
  color: #fff;
}

.react-modal-Analyze .modalAddAnalyze-content {
  height: calc(700px - 180px);
  overflow-y: auto;
  scroll-behavior: initial;
}

.react-modal-Analyze .modalAddAnalyze-content::-webkit-scrollbar {
  width: 5px;
  background-color: #8e8e8e;
  border-radius: 10px;
}

.react-modal-Analyze .modalAddAnalyze-content::-webkit-scrollbar-thumb {
  background-color: #2ebbaa;
  border-radius: 10px;
}

.react-modal-Analyze .modalAddAnalyze-content::-webkit-scrollbar-track {
  background-color: #8e8e8e;
  border-radius: 10px;
}

.react-modal-help {
  width: 552px;
  height: calc(100vh - 100px);
  max-height: 678px;
  background: var(--gray-700);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  z-index: 1000;
  color: #fff;
}

.react-modal-pdf {
  width: 420px;
  /* height: calc(100vh - 80px); */
  height: 320px;
  background: #fff;
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
}

.react-modal-report {
  width: 1100px;
  height: calc(100vh - 110px);
  background: var(--gray-700);
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  overflow: hidden;
}

.react-modal-reportOpened {
  width: 912px;
  height: calc(100vh - 20px);
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
}

/* Estilizações para o calendário */
.react-datepicker-popper {
  z-index: 9999;
}

.custom-calendar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #fff;
  color: #333;
  border-radius: 8px;
  border: 0.5px solid #efefef;
  box-shadow: 2px 2px 16px -4px rgba(34, 34, 34, 0.2);
  font: 400 1rem "SegoeUi", sans-serif;
  z-index: 9999;
}

.react-datepicker__month-container {
  order: 1;
}

/* Estilizações para o dia da semana */
.custom-calendar .react-datepicker__day-name {
  color: #353535;
}

/* Estilizações para os números dos dias */
.custom-calendar .react-datepicker__day {
  color: #353535;
}

/* Estilizações para os números dos dias do mês anterior e do próximo mês */
.custom-calendar .react-datepicker__day--outside-month {
  color: #353535;
}

/* Estilizações para o dia selecionado */
.custom-calendar .react-datepicker__day--selected {
  background-color: #007e69;
  color: #fff;
  border-radius: 8px;
}

/* Estilizações para o dia atual */
.custom-calendar .react-datepicker__day--today {
  font-weight: bold;
  background-color: #007e69;
  color: #fff;
  border-radius: 8px;
}

.custom-calendar .react-datepicker__header {
  background: #fff;
}

/* animaçao do sidebar viewer2d */
.asideView-enter {
  opacity: 0;
  transform: translateX(100%);
}

.asideView-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.asideView-exit {
  opacity: 1;
}

.asideView-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms, transform 300ms;
}

.containerIcons-exit {
  opacity: 1;
}

.containerIcons-exit-active {
  opacity: 0;
  transition: opacity 0ms;
}
