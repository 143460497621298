.containerStructures {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding-right: 12px;
  padding-bottom: 100px;
}

.containerStructures::-webkit-scrollbar {
  width: 7px;
  background-color: var(--gray-600);
  border-radius: 5px;
}

.containerStructures::-webkit-scrollbar-thumb {
  background-color: var(--gray-200);
  border-radius: 5px;
}

.containerStructures p {
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.containerSearchResult {
  width: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  gap: 12px;
}

.containerCheckbox {
  max-height: calc(100vh - 510px) !important;
  min-height: calc(100vh - 510px) !important;
  overflow-y: scroll;
}

.containerCheckbox::-webkit-scrollbar {
  width: 4px;
  background-color: var(--gray-600);
  border-radius: 5px;
}

.containerCheckbox::-webkit-scrollbar-thumb {
  background-color: var(--gray-200);
  border-radius: 5px;
}

.containerSearchResult > div {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  padding: 4px 16px;
  margin-right: 4px;
}

.inputCheckbox {
  width: 15px;
  height: 15px;
}

.textNoResult {
  font-size: 10px !important;
  color: var(--gray-500);
}

.containerSettings {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--gray-600);
}

.containerSettings > p {
  text-align: left;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

.containerSettings .containerOnOff {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
}

.containerSettings .containerOnOff button {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: var(--gray-700);
  color: #fff;
  outline: none;
  border: 0;
  cursor: pointer;
}

.containerSettings .containerOnOff button:hover:not([disabled]) {
  background-color: var(--gray-600);
}

.containerSettings .containerOnOff button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.containerSettings .containerOnOff div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.containerButtonsPoints {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  height: 52px;
  background: var(--gray-700);
  padding: 2px;
  border-radius: 12px;
}

.containerButtonsPoints > button {
  border-radius: 12px;
  font-size: 0.75rem;
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
}

.containerButtonsPoints .buttonActive {
  color: #000;
  background: var(--gray-50);
}

.containerButtonsPoints button:not(.buttonActive):hover {
  opacity: 0.8;
  cursor: pointer;
}

.containerInputsRange {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid var(--gray-600);
}

.containerInputOpcoesBasicas {
  display: flex;
  flex-direction: column;
}

.containerInputOpcoesBasicas > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.containerInputOpcoesBasicas span {
  display: block;
  margin-bottom: 12px;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  aling-items: center;
  justify-content: space-between;
}

.containerInputOpcoesBasicas span span {
  color: white;
}

.containerInputOpcoesBasicas {
  margin-bottom: 10px;
}

.containerInputOpcoesBasicas:last-child {
  margin-bottom: 5px;
}

.containerInputOpcoesBasicas input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  width: 100%;
  height: 0.5em;
  position: relative;
  border-radius: 5px;
  background-color: var(--gray-700);
  outline: none;
}

.containerInputOpcoesBasicas > input::-webkit-slider-thumb {
  background: #fff !important;
}
