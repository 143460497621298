.header {
  display: flex;
  align-items: center;
  padding: 24px 20px;
  gap: 12px;
  border-bottom: 1px solid var(--gray-100);
  -webkit-box-shadow: 0px 0px 20px 3px rgba(1, 1, 1, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(1, 1, 1, 0.2);
}

.header h2 {
  font-size: 1.25rem;
  color: #fff;
}

.header svg {
  cursor: pointer;
  color: var(--gray-400);
}

.header svg:hover {
  color: var(--gray-500);
}

.containerHelpIcon {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #666;
}

.containerIconClose {
  position: absolute;
  top: 16px;
  right: 16px;
}

.containerSelect p {
  font-size: 0.875rem;
  color: var(--gray-400);
}

.containerIconDragAndDrop {
  position: absolute;
  top: 5px;
  left: 50%;
}

.textNoResult {
  font-size: 0.875rem;
  color: var(--gray-500);
  text-align: center;
}

.wrapper {
  padding: 16px 20px 30px;
}

.containerCheckbox {
  margin-top: 32px;
}

.containerSearchResult {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  gap: 12px;
}

.containerSearchResult span {
  font-size: 0.75rem;
  color: #fff;
}

.inputCheckbox {
  width: 15px;
  height: 15px;
}

.inputCheckbox:checked {
  background-color: green;
}

.wrapperAnalyze {
  max-height: calc(100vh - 280px);
  overflow: auto;
  padding-top: 20px;
}

.wrapperAnalyze::-webkit-scrollbar {
  width: 4px;
  background-color: var(--gray-600);
  border-radius: 5px;
}

.wrapperAnalyze::-webkit-scrollbar-thumb {
  background-color: var(--gray-200);
  border-radius: 5px;
}
