.login {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
}

.wrapper {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 132px;
  /* padding: 46px 132px; */
}
.BoxVideoInstitutionalMobile {
  display: none;
}

.image {
  width: 250px;
  height: 90px;
  object-fit: contain;
}

.container_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.container_title {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 30px;
}

.container_title h1 {
  font-size: 1.5rem;
  color: var(--gray-600);
}

.container_title p {
  font-size: 1rem;
  margin-top: 8px;
  color: var(--gray-700);
  width: 382px;
}

.container_recoverPassword {
  position: relative;
  display: flex;
  justify-content: end;
  margin-top: 5px;
}

.BoxFooterrespnsive {
  display: none;
}

.container_recoverPassword span {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--green-700);
  cursor: pointer;
}

.containerCheckbox {
  width: 382px;
}

.container_buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
  margin-bottom: 16px;
}

.container_buttons_loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container_inputs {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.container_inputs input {
  background: none;
}

.container_createYourAccount span {
  display: inline-block;
  margin-right: 5px;
  color: var(--gray-700);
}

.container_createYourAccount a {
  text-decoration: none;
  color: var(--green-700);
  font-weight: 700;
}

.container_redirectOldSite {
  margin-top: 15px;
}

.container_redirectOldSite a {
  text-decoration: none;
  color: var(--green-700);
  font-weight: 600;
}

.container_responsive {
  display: none;
}

.videoTablet {
  display: none;
}
.containerError {
  margin-top: 16px;
  padding: 8px;
  border-radius: 12px;
  background: #ffc8b9;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 12px;
  color: #000;
  animation: growAnimation 300ms forwards;
}

.containerErroIcon {
  display: flex;
  align-items: center; /* Centraliza verticalmente o ícone */
  justify-content: center;
}

.containerError p {
  margin: 0;
}

.container_inputs_title {
  display: none;
}

.titleBox {
  color: var(
    --neutral-colors-gray-gray-900,
    var(--neutral-colors-gray-gray-900, #151515)
  );
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 20px;
  padding-bottom: 32px;
}

@keyframes growAnimation {
  0% {
    width: 100%;
    padding: 0px;
    opacity: 0;
  }
  100% {
    width: 100%;
    padding: 8px;
    opacity: 1;
  }
}

/* @media (max-width: 1286px) {
  .container_responsive {
    display: block;
  }
  .container_title {
    margin-top: 15px;
    margin-bottom: 40px;
  }

  .container_form {
    padding: 0px 25px;
  }

  .container_buttons {
    gap: 8px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .wrapper_form {
    padding: 0;
  }
} */
.container_createYourAccounts span {
  display: inline-block;
  margin-right: 5px;
  color: var(--gray-700);
}

.container_createYourAccounts a {
  text-decoration: none;
  display: inline-block;
  color: var(--green-700);
  font-weight: 700;
}

.container_createYourAccounts div {
  text-decoration: none;
  display: inline-block;
  color: var(--green-700);
  font-weight: 700;
  cursor: pointer;
}
@media (max-width: 1234px) {
  .container_createYourAccounts span {
    display: inline-block;
    margin-right: 5px;
    color: var(--gray-700);
  }

  .container_createYourAccounts a {
    text-decoration: none;
    display: inline-block;
    color: var(--green-700);
    font-weight: 700;
  }
  .container_form {
    width: 100%;
  }
  .videoTablet {
    display: block;
  }

  .container_Form {
    width: 664px;
  }
  .form-header-image {
    margin: 0 auto;
  }

  .container_title p {
    width: 100%;
  }
  .BoxVideoInstitutional {
    display: none;
  }
  .image {
    margin-top: 22px;
    margin-bottom: 24px;
  }
  .containerCheckbox {
    width: auto;
  }
  .container_createYourAccount {
    /* padding-top: 20px; */
    margin-bottom: 10px;
    padding-bottom: 20px;
  }

  .container_title {
    margin-top: 2px;
    margin-bottom: 24px;
  }
}

@media (max-width: 684px) {
  .wrapper {
    justify-content: baseline;
    flex: 0;
  }
  .wrapper_form {
    width: 100%;
  }
  .BoxVideoInstitutionalMobile {
    display: block;
    width: 100%;
    aspect-ratio: 7/4;
    margin-top: -3px;
  }
  .videoTablet {
    display: none;
  }
  .image {
    margin: 0;
  }
  .container_form {
    width: 100%;
    margin-top: -17px;
    border-top-left-radius: 12px;
    border-radius: 20px 20px 0px 0px;
    background: #fff;
    z-index: 99;
    position: relative;
  }

  .container_Form {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .container_title {
    display: none;
  }
  .container_inputs_title {
    display: flex;
    padding: 10px 0 11px 4px;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
  }

  .titleBox {
    display: none;
  }

  .image {
    width: 130px;
    height: 52px;
  }
  .container_inputs {
    gap: 20px;
    padding: 0px;
  }
  .BoxFooter {
    display: none;
  }

  .container_buttons {
    /* padding-top: 50px; */
  }
  .container_createYourAccount {
    font-size: 14px;
  }

  .container_redirectOldSite {
    font-size: 14px;
  }

  .container_responsive_button {
    height: 44px;
  }
}
@media (max-width: 684px) {
  .wrapper {
    flex: 0;
  }
  .BoxFooterrespnsive {
    display: block;
    bottom: 0;
    position: fixed;
    width: 100%;
    text-align: center;
  }
}
