
.wrapperImage {
  position: relative;
  cursor: pointer;
}

.containerImage {
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 16px 16px 0 0;
}

.containerImage img {
  max-width: 270px;
  height: 200px;
  border-radius: 16px 16px 0 0;
  object-fit: contain;
  object-position: center;
}

.containerImage_active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.57) 0%,
    rgba(0, 0, 0, 0.11) 37.19%,
    rgba(0, 0, 0, 0) 80%
  );
  border-radius: 16px;
}

.inputCheckbox {
  position: absolute;
  border: 1px solid #fff;
  width: 22px;
  height: 22px;
  top: 20px;
  left: 20px;
  border-radius: 8px;
  clip-path: circle(62%);
}

.containerContentImage {
  padding: 18px 16px;
  border-top: 1px solid var(--gray-200);
  text-align: center;
  background: #fff;
  border-radius: 0 0 16px 16px;
}

.containerContentImage strong {
  color: var(--gray-800);
}

.containerContentImage p {
  font-size: 0.75rem;
  color: var(--gray-500);
}

.wrapperImage:hover .containerContentImage strong {
    color: var(--green-300);
  }
  
