.wrapper {
  width: 100%;
  height: 100%;
}

.container_video {
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
}

.container_video > .video {
  width: 100%;
  height: 100%;
}

.container_content {
  color: var(--gray-700);
}
