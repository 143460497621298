.wrapper_input {
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 80px;
  padding: 8px 16px;
  display: flex;
  grid-area: "search";
}
.searchIcon {
  z-index: 10;
}
.searchInput {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 80px;
  padding: 0 16px;
  padding-left: 44px;
  position: absolute;
  outline: none;
  border: none;
  background-color: #efefef;
}
.containerInputs {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}
.selectOption {
  z-index: 10;
  border-radius: 80px;
  border: 0;
  outline: none;
}
.createBtn {
  padding: 0;
  background-color: #007e69;
  height: 40px;
  padding: 10px 16px;
  min-width: 200px;
  text-align: center;
}
.createBtnContainer {
  grid-area: "create";
  justify-self: flex-end;
}
.createBtn:hover {
  background-color: #004438 !important;
  transition: all 0.2s;
}
.plusIcon {
  margin: 0;
  padding: 0;
  color: #fff;
}
.containerGrid {
  border: 1px solid #ced5de;
  border-radius: 16px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.2);
  grid-area: card;
}
.cardTitle {
  color: #353535;
}
.cardSubtitle {
  color: #666666;
}
.outerContainer {
  margin: 20px;
}
.containerSelect {
  display: inline;
  grid-area: "select";
}
.playIcon {
  margin: 0 10px;
}
.selectContainer {
  display: flex;
  align-items: center;
}

.examsContainer {
  margin-top: 32px;
}
.examsText {
  color: #7b889c;
  font-weight: 700;
}
.examsBadge {
  display: inline-block;
  padding: 4px 12px;
  font-weight: 600;
  border-radius: 12px;
  margin: 8px 8px 0 0;
}
.tomografiaBadge {
  color: #d72c69;
  background-color: #fddae6;
}
.raioxBadge {
  color: #078295;
  background-color: #bcf0f8;
}
.atmBadge {
  color: #1565c0;
  background-color: #e1e6ef;
}
.iconContainer,
.clockIconContainer {
  display: inline;
  margin-right: 8px;
}
.chartContainer {
  margin-top: 32px;
  border: 1px solid #ced5de;
  border-radius: 16px;
  box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.timeBadge {
  width: 35%;
  padding: 6px 10px;
  color: #465364;
  font-weight: 600;
  border-radius: 0px 0px 8px 8px;
  background: var(--neutral-colors-gray-river-gray-river-50, #ebeef3);
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  margin-right: 2%;
}
@media (min-width: 1500px) and (max-width: 1600px) {
  .timeBadge {
    width: 50%;
    font-size: 1rem;
  }
}
@media (min-width: 1600px) and (max-width: 1700px) {
  .timeBadge {
    width: 45%;
    font-size: 1rem;
  }
}
@media (min-width: 1000px) and (max-width: 1300px) {
  .timeBadge {
    width: 60%;
    font-size: 1rem;
  }
}
@media (min-width: 1300px) and (max-width: 1500px) {
  .timeBadge {
    width: 58%;
    font-size: 1rem;
  }
}

.chartTitle {
  font-weight: 600;
  color: #00523d;
}
.chartTopContainer {
  margin: 0 15px;
  display: flex;
  column-gap: 8px;
}
.chartSubtitle {
  color: #353535;
}
.chartLineUpContainer {
  background-color: #def3f1;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
.chartTopTextContainer {
  display: inline-block;
}
.graphContainer {
  display: flex;
  justify-content: center;
  column-gap: 40px;
  margin-top: 30px;
  width: 100%;
  min-width: 0;
}
.link {
  text-decoration: none;
}
.meioDeCaptacao {
  color: #151515;
}
.chartLabel {
  color: #151515;
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
  gap: 0 5px;
}
.meioDeCaptacaoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.chartLabelPercentage {
  color: #009c86;
  font-weight: 600;
}
.chartAmount {
  font-weight: 700;
  color: #535353;
}
.dateAdded {
  color: #666666;
  font-weight: 600;
  margin: 20px 0 15px 15px;
}
.graphWrapper {
  width: 30%;
}
.cardsContainer {
  display: grid;
  grid-template-areas:
    "search select create"
    "card card card"
    "card card card"
    "card card card";
  gap: 16px;
}
.avatar {
  border-radius: 16px;
  position: relative;
  border: 0.5px solid #f8f8f8;
}
.avatar1 {
  margin-left: -3px;
}
.avatar2 {
  margin-left: -5px;
}
.patientsContainer {
  display: flex;
  align-items: center;
}
.avatars {
  display: flex;
  flex-direction: row;
  gap: -10px;
}
.chartType {
  color: #151515;
}

@media (max-width: 375px){
  .cardsContainer{
    grid-template-areas:
        "search"
        "create"
        "card"
        "card"
        "card"
        "card"
        "card";
  }
  .wrapper_input{
    display: none;
  }
  .selectOption{
    display: none;
  }
  .createBtn{
    display: none;
  }
  .containerGrid{
    width: 100%;
    margin-top: -40px;
    margin-bottom: 40px;
  }
  .graphWrapper{
    padding-left: 10px;
    width: 35%;
    padding-bottom: 5%;
  }
  .chartTopContainer p{
    font-size: 15px;
  }
  .meioDeCaptacaoContainer{
    font-size: 13px;
  }
  .meioDeCaptacaoContainer p{
    margin: 2px;
  }
  .dateAdded{
    display: none;
  }
  .examsContainer{
    margin-top: 10px;
  }
  .chartContainer{
    margin-top: 18px;
  }
  .timeBadge{
    width: auto;
    margin-right: 0px;
    border-radius: 0px 8px 0px 8px;
  }
}

@media only screen and (max-width: 414px) and (min-width:412px){
  .cardsContainer{
    grid-template-areas:
        "search"
        "create"
        "card"
        "card"
        "card"
        "card"
        "card";
  }
  .wrapper_input{
    display: none;
  }
  .selectOption{
    display: none;
  }
  .createBtn{
    display: none;
  }
  .containerGrid{
    width: 100%;
    margin-top: -40px;
    margin-bottom: 40px;
  }
  .graphWrapper{
    padding-left: 10px;
    width: 35%;
    padding-bottom: 5%; 
  }
  .chartTopContainer p{
    font-size: 15px;
  }
  .meioDeCaptacaoContainer{
    font-size: 15px;
  }
  .meioDeCaptacaoContainer p{
    margin: 2px;
  }
  .dateAdded{
    display: none;
  }
  .examsContainer{
    margin-top: 10px;
  }
  .chartContainer{
    margin-top: 18px;
  }
  .timeBadge{
    width: auto;
    margin-right: 0px;
    border-radius: 0px 8px 0px 8px;
  }
}

@media only screen and (max-width: 393px) and (min-width:390px){
  .cardsContainer{
    grid-template-areas:
        "search"
        "create"
        "card"
        "card"
        "card"
        "card"
        "card";
  }
  .wrapper_input{
    display: none;
  }
  .selectOption{
    display: none;
  }
  .createBtn{
    display: none;
  }
  .containerGrid{
    width: 100%;
    margin-top: -40px;
    margin-bottom: 40px;
  }
  .graphWrapper{
    padding-left: 10px;
    width: 35%;
    padding-bottom: 5%;
  }
  .chartTopContainer p{
    font-size: 15px;
  }
  .meioDeCaptacaoContainer{
    font-size: 14px;
  }
  .meioDeCaptacaoContainer p{
    margin: 2px;
  }
  .dateAdded{
    display: none;
  }
  .examsContainer{
    margin-top: 10px;
  }
  .chartContainer{
    margin-top: 18px;
  }
  .timeBadge{
    width: auto;
    margin-right: 0px;
    border-radius: 0px 8px 0px 8px;
  }
  }

  @media only screen and (max-width: 370px) and (min-width:360px){
    .cardsContainer{
      grid-template-areas:
          "search"
          "create"
          "card"
          "card"
          "card"
          "card"
          "card";
    }
    .wrapper_input{
      display: none;
    }
    .selectOption{
      display: none;
    }
    .createBtn{
      display: none;
    }
    .containerGrid{
      width: 100%;
      margin-top: -40px;
      margin-bottom: 40px;
      margin-right: -12px
    }
    .graphWrapper{
      padding-left: 10px;
      width: 35%;
      padding-bottom: 5%;
    }
    .chartTopContainer p{
      font-size: 15px;
    }
    .meioDeCaptacaoContainer{
      font-size: 13px;
    }
    .meioDeCaptacaoContainer p{
      margin: 2px;
      margin-right: 15px;
    }
    .dateAdded{
      display: none;
    }
    .examsContainer{
      margin-top: 10px;
    }
    .chartContainer{
      margin-top: 18px;
    }
    .timeBadge{
      width: auto;
      margin-right: 0px;
      border-radius: 0px 8px 0px 8px;
    }
  }

  @media only screen and (max-width: 320px) and (min-width:300px){
    .cardsContainer{
      grid-template-areas:
          "search"
          "create"
          "card"
          "card"
          "card"
          "card"
          "card";
    }
    .wrapper_input{
      display: none;
    }
    .selectOption{
      display: none;
    }
    .createBtn{
      display: none;
    }
    .containerGrid{
      margin-right: -12px
    }
    .graphWrapper{
      margin-left: 3%;
      padding-top: 10%;
      width: 25%;
      padding-bottom: 10%;

    }
    .chartTopContainer p{
      font-size: 13px;
    }
    .meioDeCaptacaoContainer{
      font-size: 13px;
    }
    .meioDeCaptacaoContainer p{
      margin: 2px;
      margin-right: 15px;
    }
    .dateAdded{
      display: none;
    }
    .examsContainer{
      margin-top: 10px;
    }
    .chartContainer{
      margin-top: 18px;
    }
    .timeBadge{
      width: auto;
      margin-right: 0px;
      border-radius: 0px 8px 0px 8px;
      height: 25px;
      font-size: 13px;
    }
    
  }

  @media only screen and (max-width: 280px) and (min-width:280px){
    .cardsContainer{
      grid-template-areas:
          "search"
          "create"
          "card"
          "card"
          "card"
          "card"
          "card";
    }
    .wrapper_input{
      display: none;
    }
    .selectOption{
      display: none;
    }
    .createBtn{
      display: none;
    }
    .containerGrid{
      margin-right: -12px
    }
    .graphWrapper{
      margin-left: 5%;
      padding-top: 10%;
      width: 20%;
      padding-bottom: 10%;
    }
    .chartTopContainer p{
      font-size: 13px;
      display: inline;
    }
    .meioDeCaptacaoContainer{
      font-size: 10px;
    }
    .meioDeCaptacaoContainer p{
      margin: 2px;
      margin-right: 15px;
    }
    .dateAdded{
      display: none;
    }
    .examsContainer{
      margin-top: 10px;
    }
    .chartContainer{
      margin-top: 18px;
    }
    .timeBadge{
      width: auto;
      margin-right: 0px;
      border-radius: 0px 8px 0px 8px;
      height: 25px;
      font-size: 13px;
    }
    .patientsContainer p {
      font-size: 14px;
    }
    
  }

  @media only screen and (max-width: 1280px) and (min-width:1210px){
    .cardsContainer{
      grid-template-areas:
      "search create"
      "card card"
      "card card"
      "card card"
      "card card";
    }
    .wrapper_input{
      display: none;
    }
    .selectOption{
      display: none;
    }
    .createBtn{
      display: none;
    }
    .containerGrid{
      margin-right: -12px
    }
    .graphWrapper{
      margin-left: 5%;
      padding-top: 10%;
      width: 30%;
      padding-bottom: 10%;
    }
    .chartTopContainer p{
      font-size: 15px;
    }
    .meioDeCaptacaoContainer{
      font-size: 15px;
    }
    .meioDeCaptacaoContainer p{
      margin: 2px;
      margin-right: 15px;
    }
    .dateAdded{
      display: none;
    }
    .examsContainer{
      margin-top: 10px;
    }
    .chartContainer{
      margin-top: 18px;
    }
    .timeBadge{
      width: auto;
      margin-right: 0px;
      border-radius: 0px 8px 0px 8px;
      height: 25px;
      font-size: 13px;
    }
    .patientsContainer p {
      font-size: 18px;
    }
    .chartTopContainer{
      margin-top: -25px;
    }
    
  }

  @media only screen and (max-width: 1024px) and (min-width:1020px){
    .cardsContainer{
      grid-template-areas:
      "search create"
      "card card"
      "card card"
      "card card"
      "card card";
    }

    .containerSelect {
      display: none !important;
    }

    .wrapper_input{
      display: none;
    }
    .selectOption{
      display: none;
    }
    .createBtn{
      display: none;
    }
    .containerGrid{
      margin-right: -12px
    }
    .graphWrapper{
      margin-left: 5%;
      padding-top: 10%;
      width: 23%;
      padding-bottom: 10%;
    }
    .meioDeCaptacaoContainer{
      font-size: 15px;
    }
    .meioDeCaptacaoContainer p{
      margin: 2px;
      margin-right: 15px;
    }
    .dateAdded{
      display: none;
    }
    .examsContainer{
      margin-top: 10px;
    }
    .chartContainer{
      margin-top: 18px;
    }
    .timeBadge{
      width: 28%;
      margin-right: 0px;
      border-radius: 0px 8px 0px 8px;
      height: 35px;
      font-size: 13px;
    }
    .patientsContainer p {
      font-size: 18px;
    }
    .chartTopContainer{
      margin-top: -33px;
      font-size: 12px;
    }
    
  }

  @media only screen and (max-width: 820px) and (min-width:800px){
    .cardsContainer{
      grid-template-areas:
      "search create"
      "card card"
      "card card"
      "card card"
      "card card";
    }

    .containerSelect {
      display: none !important;
    }

    .wrapper_input{
      display: none;
    }
    .selectOption{
      display: none;
    }
    .createBtn{
      display: none;
    }
    .containerGrid{
      margin-right: -12px
    }
    .graphWrapper{
      margin-left: 5%;
      padding-top: 10%;
      width: 25%;
      padding-bottom: 10%;
    }
    .meioDeCaptacaoContainer{
      font-size: 15px;
    }
    .meioDeCaptacaoContainer p{
      margin: 2px;
      margin-right: 15px;
    }
    .dateAdded{
      display: none;
    }
    .examsContainer{
      margin-top: 10px;
    }
    .chartContainer{
      margin-top: 18px;
    }
    .timeBadge{
      width: 25%;
      margin-right: 0px;
      border-radius: 0px 8px 0px 8px;
      height: 35px;
      font-size: 11px;
    }
    .patientsContainer p {
      font-size: 18px;
    }
    .chartTopContainer{
      margin-top: -33px;
      font-size: 11px;
    }
    
  }

  @media only screen and (max-width: 768px) and (min-width:750px){
    .cardsContainer{
      grid-template-areas:
      "search create"
      "card card"
      "card card"
      "card card"
      "card card";
    }
    .wrapper_input{
      display: none;
    }
    .selectOption{
      display: none;
    }
    .createBtn{
      display: none;
    }
    .containerGrid{
      margin-right: -12px
    }
    .graphWrapper{
      margin-left: 5%;
      padding-top: 10%;
      width: 25%;
      padding-bottom: 10%;
    }

    .containerSelect {
      display: none !important;
    }

    .meioDeCaptacaoContainer{
      font-size: 15px;
    }
    .meioDeCaptacaoContainer p{
      margin: 2px;
      margin-right: 15px;
    }
    .dateAdded{
      display: none;
    }
    .examsContainer{
      margin-top: 10px;
    }
    .chartContainer{
      margin-top: 18px;
    }
    .timeBadge{
      width: 25%;
      margin-right: 0px;
      border-radius: 0px 8px 0px 8px;
      height: 35px;
      font-size: 11px;
    }
    .patientsContainer p {
      font-size: 18px;
    }
    .chartTopContainer{
      margin-top: -33px;
      font-size: 11px;
    }
    
  }

  @media only screen and (max-width: 912px) and (min-width:900px){
    .cardsContainer{
      grid-template-areas:
      "search create"
      "card card"
      "card card"
      "card card"
      "card card";
    }
    .wrapper_input{
      display: none;
    }
    .selectOption{
      display: none;
    }
    .createBtn{
      display: none;
    }

    .containerSelect {
      display: none !important;
    }

    .containerGrid{
      margin-right: -12px
    }
    .graphWrapper{
      margin-left: 5%;
      padding-top: 10%;
      width: 25%;
      padding-bottom: 10%;
    }
    .meioDeCaptacaoContainer{
      font-size: 15px;
    }
    .meioDeCaptacaoContainer p{
      margin: 2px;
      margin-right: 15px;
    }
    .dateAdded{
      display: none;
    }
    .examsContainer{
      margin-top: 10px;
    }
    .chartContainer{
      margin-top: 18px;
    }
    .timeBadge{
      width: 25%;
      margin-right: 0px;
      border-radius: 0px 8px 0px 8px;
      height: 35px;
      font-size: 11px;
    }
    .patientsContainer p {
      font-size: 18px;
    }
    .chartTopContainer{
      margin-top: -33px;
      font-size: 11px;
    }
    
  }

  @media only screen and (max-width: 540px) and (min-width:500px){
    .cardsContainer{
      grid-template-areas:
      "search create"
      "card card"
      "card card"
      "card card"
      "card card";
    }
    .wrapper_input{
      display: none;
    }
    .selectOption{
      display: none;
    }
    .createBtn{
      display: none;
    }
    .containerGrid{
      margin-right: -12px
    }

    .containerSelect {
      display: none !important;
    }
    .graphWrapper{
      margin-left: 5%;
      padding-top: 10%;
      width: 25%;
      padding-bottom: 10%;
    }
    .meioDeCaptacaoContainer{
      font-size: 15px;
    }
    .meioDeCaptacaoContainer p{
      margin: 2px;
      margin-right: 15px;
    }
    .dateAdded{
      display: none;
    }
    .examsContainer{
      margin-top: 10px;
    }
    .chartContainer{
      margin-top: 18px;
    }
    .timeBadge{
      width: 25%;
      margin-right: 0px;
      border-radius: 0px 8px 0px 8px;
      height: 35px;
      font-size: 11px;
    }
    .patientsContainer p {
      font-size: 18px;
    }
    .chartTopContainer{
      margin-top: -33px;
      font-size: 11px;
    }
    
  }