.react_modal_SendMessage {
  width: 400px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  padding: 24px;
}
.error_message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  padding-left: 10px;
}
.testes {
  justify-content: center;
  padding-top: 20px;
  justify-content: center;
}

.react_modal_AddIndicator {
  width: 545px;

  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  padding: 24px;
}

@media (max-width: 600px) {
  .react_modal_AddIndicator {
    width: 92vw;
  }

  .label_multi_select {
    bottom: 92px !important;
  }

  .selected_options {
    flex-wrap: wrap;
  }

  .container_buttons {
    flex-direction: column;
  }

  .container_buttons button {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .react_modal_SendMessage {
    max-width: 250px;
  }
}

@media (max-width: 300px) {
  .react_modal_AddIndicator {
    width: 92vw;
  }
}

.fields_wrapper {
  margin: 35px 0;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 1.5rem;
}

.headerModalSucesss {
  text-align: center;
  position: relative;
}

.headerModalSucesss .containerButtons {
  display: flex;
  justify-content: center;
  margin-top: 2rem;  
}

.headerModalSucesss .containerButtons > button {
  min-width: 300px !important;
  background: var(--gray-100) !important;
}

.headerModalSucesss .containerButtons > button:hover {
  background: var(--gray-200) !important;
  color: #000 !important;
}

.success {
  display: flex;
  width: 56px;
  height: 56px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 28px;
  border: 8px solid #e3f7e7;
  background: #bdecc4;
  margin-bottom: 1rem !important;
  margin: 0 auto;
}

.icon_wrapper {
  width: 30px;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
  margin-bottom: 1rem;
  z-index: 10;
}

.icon_wrapper:hover {
  opacity: 0.5;
}

/* .icon_wrapper svg {
  width: 20px;
  height: 20px;
  color: #8e8e8e;
}

.icon_wrapper:hover svg {
  color: #000000;
  transform: scale(1.1);
} */

.selected_options {
  display: flex;
  gap: 10px;
}

.examsText {
  display: flex;
  color: #7b889c;
  font-weight: 700;
}
.examsBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  padding: 4px 12px;
  font-weight: 600;
  border-radius: 12px;
  font-size: 12px;
}

.react_modal_AddIndicator .examsBadge img {
  transform: translateY(2px);
}

.tomografiaBadge {
  color: #d72c69;
  background-color: #fddae6;
}
.raioxBadge {
  color: #078295;
  background-color: #bcf0f8;
}
.atmBadge {
  color: #b83d9e;
  background-color: #f2dfee;
}
.iconContainer,
.clockIconContainer {
  display: inline;
  margin-right: 8px;
}

.container_input {
  padding: 50px 24px 45px;
}

.button {
  border-radius: 56px !important;
  color: #fff !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "Segoe UI Variable" !important;
  border: none;
  cursor: pointer;
}

.button:hover:not([disabled]) {
  opacity: 0.95;
}

.button:disabled {
  cursor: not-allowed;
}

.submitSendMessages {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-top: 40px;
}
.submitSendMessageNext {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.submitSendMessage {
  margin-top: 40px;
  width: 289px;
  height: 48px;
}
.submitSendMessage2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 40px;
  width: 289px;
  height: 48px;
  background-color: #007e69 !important;
}
.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid rgb(30, 199, 98);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
}
.submitSendMessage2:hover {
  background-color: #007e69 !important;
}

.submitSendMessage svg {
  height: 20px;
  width: 20px;
  padding-top: 5px;
}

.radioGroup {
  display: grid;
  gap: 24px;
  font-size: 18px;
  margin-bottom: 40px;
  --checked-clr: #007e69;
}

.label {
  display: flex;
  align-items: center;
  gap: 8px;
}

input[type="radio"] {
  width: 16px;
  height: 16px;
  accent-color: var(--checked-clr);
}

.fields_wrapper {
  display: grid;
  gap: 16px;
}

.container_select {
  position: relative;
}

.label_select {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--gray-700);
  position: absolute;
  bottom: 41px;
  left: 16px;
  background: #fff;
  z-index: 1;
}

.select:valid ~ .label_select {
  font-size: 0.875rem;
  font-weight: 600;
  background: #ffffff;
}

.select:focus ~ .label_select {
  color: red;
  font-size: 1rem;
  font-weight: 600;
  background: #ffffff;
}

.select:placeholder-shown ~ .label_select {
  font-size: 0.875rem;
  font-weight: 600;
  background: #ffffff;
}

.container_buttons {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}
.selectClincs {
  padding-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.selected {
  display: inline-block;
  color: #37404e;
  background: var(--neutral-colors-gray-river-gray-river-100, #ced5de);
  padding: 10px 14px;
  font-weight: 600;
  border-radius: 12px;
}
.inputForm {
  display: grid;
  gap: 22px;
}
.cardselected {
  display: flex;
  flex-wrap: wrap;

  gap: 16px;
}
.selectUser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 10px 0;
}

.selectUser img {
  width: 50px;
  border-radius: 50%;
}

.unselect {
  display: inline-block;
  color: #919daf;
  background: var(--neutral-colors-gray-river-gray-river-100, #ffffff);
  padding: 10px 14px;
  font-weight: 600;
  border-radius: 12px;
  border: 1px solid var(--neutral-colors-gray-river-gray-river-300, #919daf);
}
.container_buttons > button {
  min-width: 176px;
  max-height: 40px;
}

.buttonMore {
  color: #8e8e8e;
  border: 1px solid #007e69;
}
.buttonMore:hover {
  border: 1px solid #007e69;
}

.selectClinicText {
  color: var(--neutral-colors-gray-river-gray-river-400, #7b889c);
  font-size: 14px;
  font-weight: 700;
  text-align: left;
}

@media (max-width: 720px) {
  .container_input {
    padding-bottom: 30px;
  }
  .submitSendMessage {
    margin-top: 40px;

    width: 180px;
    height: 42px;
  }
  .submitSendMessage2 {
    margin-top: 40px;

    width: 180px;
    height: 42px;

    background-color: #007e69;
  }
}

.container_button {
  padding: 0 24px;
}

.react_modal_SendMessageWhats {
  width: 60%;
  height: 70%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  padding: 24px;
  position: relative;
}

.headerWhats {
  margin-top: 15px;
  gap: 8px;
  padding-bottom: 15px;
}

.headerWhats h2 {
  color: var(--neutral-colors-black, #000);
  gap: 5px;
  font-family: Segoe UI Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.headerWhats p {
  color: var(--neutral-colors-gray-gray-600, #666);
  font-family: Segoe UI Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cardContentWhats {
  height: 112px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--neutral-colors-gray-gray-200, #e4e4e4);
  background: var(--neutral-colors-white, #fff);
  position: relative;

  /* button */
  box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.2);
}

.cardContentWhats h2 {
  color: var(--neutral-colors-gray-gray-800, #353535);
  padding-top: 8px;
  padding-left: 10px;
  font-family: Segoe UI Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.clockIconContainer {
  border-radius: 0px 0px 8px 8px;
  background: var(--neutral-colors-gray-river-gray-river-50, #ebeef3);
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;
  margin-top: -32px;
  margin-left: 668px;
  display: flex;
  width: fit-content;
}

.clockIconContainer p {
  color: var(--neutral-colors-gray-river-gray-river-700, #465364);

  font-family: Segoe UI Variable;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: sticky;
}

.pacientesContainer {
  display: flex;
}

.pacientesContainer p {
  color: var(--neutral-colors-gray-gray-700, #535353);
  padding-top: 35px;
  padding-left: 15px;
  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
  margin-left: 50px;
}

.avatars {
  position: absolute;
  margin-top: 33px;
  margin-left: 15px;
}

.avatar {
  border-radius: 50%;
  margin-left: 7px;
  margin-bottom: 15px;
}
.avatar1 {
  position: absolute;
  margin-right: 7px;
  border-radius: 50%;
}
.avatar2 {
  position: absolute;
  margin-left: 8px;
  border-radius: 50%;
}

.pacientesContainer p {
  position: absolute;
  padding-left: 6%;
}

.numeroPacientes p {
  color: var(--neutral-colors-gray-gray-500, #8e8e8e);
  font-family: Segoe UI Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 68%;
}

.linha {
  stroke-width: 1px;
  stroke: var(--neutral-colors-gray-river-gray-river-50, #ebeef3);
  padding-left: 20px;
}

.mensagemPaciente {
  padding-top: 15px;
}
.mensagemPaciente p {
  color: var(
    --neutral-colors-gray-gray-700,
    var(--neutral-colors-gray-gray-700, #535353)
  );

  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;

  position: absolute;
  margin-left: 25px;
  margin-top: -9px;
  background-color: white;
}

.mensagemPaciente textarea {
  display: flex;
  padding: 20px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-colors-gray-gray-400, #afafaf);
  background: var(--neutral-colors-white, #fff);
  width: 100%;
  height: 200px;
  resize: none;
}

.whatsBtn {
  display: flex;
  justify-content: end;
}

.whatsBtn button {
  display: flex;
  height: 50px;
  padding: 10px 48px;
  justify-content: end;
  align-items: center;
  gap: 8px;
  border-radius: 56px;
  background: var(--serodonto-primary-color-main-green-700, #007e69);
  border-color: white;
  box-shadow: none;
  cursor: pointer;

  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
  color: white;
  position: absolute;
  margin-top: 15px;
}

.mensagemPacienteCRM {
  padding-top: 15px;
}
.mensagemPacienteCRM p {
  color: var(
    --neutral-colors-gray-gray-700,
    var(--neutral-colors-gray-gray-700, #535353)
  );

  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;

  position: absolute;
  margin-left: 25px;
  margin-top: -9px;
  background-color: white;
}

.mensagemPacienteCRM textarea {
  display: flex;
  padding: 20px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-colors-gray-gray-400, #afafaf);
  background: var(--neutral-colors-white, #fff);
  width: 100%;
  height: 70px;
  resize: none;
}

.react_modal_SendCRM {
  width: 60%;
  height: 56%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  padding: 24px;
}

.Assunto {
  padding-top: 15px;
}
.Assunto p {
  color: var(
    --neutral-colors-gray-gray-700,
    var(--neutral-colors-gray-gray-700, #535353)
  );

  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;

  position: absolute;
  margin-left: 25px;
  margin-top: -9px;
  background-color: white;
}

.Assunto textarea {
  display: flex;
  padding: 20px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-colors-gray-gray-400, #afafaf);
  background: var(--neutral-colors-white, #fff);
  width: 100%;
  height: 70px;
  resize: none;
}

.react_modal_SendEmail {
  width: 60%;
  height: 75%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  padding: 24px;
}

.btnEmail button {
  display: flex;
  height: 50px;
  padding: 10px 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 56px;
  background: var(--serodonto-primary-color-main-green-700, #007e69);
  border-color: white;
  box-shadow: none;

  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
  color: white;
  position: absolute;
  margin-left: 70%;
  margin-top: 15px;
}

.switchField {
  display: flex;
  gap: 13px;
  flex-wrap: wrap;
  height: auto;
  overflow: auto;
  margin-top: 1rem;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
.switchField input[type="radio"] {
  display: none;
}
.switchField label {
  display: inline-block;
  color: #919daf;
  background: var(--neutral-colors-gray-river-gray-river-100, #ffffff);
  padding: 10px 14px;
  font-weight: 600;
  border-radius: 12px !important;
  border: 1px solid var(--neutral-colors-gray-river-gray-river-300, #919daf);
  transition: all 0.1s ease-in-out;
}

.switchField label:hover {
  cursor: pointer;
  color: #00523d;
  background: #ade1da;
}

.switchField input:checked + label {
  display: inline-block;
  color: #37404e;
  background: var(--neutral-colors-gray-river-gray-river-100, #ced5de);
  padding: 10px 14px;
  font-weight: 600;
  border-radius: 12px;
}

.switchField label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.switchField label:last-of-type {
  border-radius: 0 4px 4px 0;
}

@media (max-width: 1629px) {
  .react_modal_SendMessageWhats {
    width: 60%;
    height: 60%;
  }
}
@media (max-width: 1508px) {
  .react_modal_SendMessageWhats {
    width: 60%;
    height: 60%;
  }
  .clockIconContainer {
    margin-left: 500px;
    width: fit-content;
  }
}
@media (max-width: 1561px) {
  .clockIconContainer {
    margin-left: 640px;
    width: fit-content;
  }
}
@media (max-width: 1442px) {
  .react_modal_SendMessageWhats {
    width: 60%;
    height: 60%;
  }
  .avatars {
    margin-left: 7px;
  }
  .clockIconContainer {
    margin-left: 600px;
    width: fit-content;
  }
}
@media (max-width: 1340px) {
  .clockIconContainer {
    margin-left: 557px;
    width: fit-content;
  }
}
@media (max-width: 1281px) {
  .clockIconContainer {
    margin-left: 535px;
    width: fit-content;
  }
}
@media (max-width: 1219px) {
  .clockIconContainer {
    margin-left: 500px;
    width: fit-content;
  }
}
@media (max-width: 1155px) {
  .clockIconContainer {
    margin-left: 480px;
    width: fit-content;
  }
  .pacientesContainer {
    margin-left: 5px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1109px) {
  .clockIconContainer {
    margin-left: 450px;
    width: fit-content;
  }
}
@media (max-width: 1049px) {
  .clockIconContainer {
    margin-left: 430px;
    width: fit-content;
  }
  .react_modal_SendCRM {
    width: 70%;
    height: 56%;
  }
}
@media (max-width: 945px) {
  .clockIconContainer {
    margin-left: 350px;
    width: fit-content;
  }
  .avatars {
    margin-left: 2px;
  }
  .react_modal_SendCRM {
    width: 70%;
    height: 56%;
  }
}
@media (max-width: 821px) {
  .submitSendMessage2 {
    min-width: 200px;
  }
  .submitSendMessage {
    min-width: 200px;
  }
  .clockIconContainer {
    margin-left: 280px;
    width: fit-content;
  }
}
@media (max-width: 800px) {
  .react_modal_SendCRM {
    width: 70%;
    height: 56%;
  }
  .clockIconContainer {
    margin-left: 268px;
    width: fit-content;
  }
  .pacientesContainer p {
    margin-top: 10px;
    margin-left: 0px;
  }
  .avatars {
    margin-top: 10px;
    margin-left: 60px;
  }
}

@media (max-width: 701px) {
  .react_modal_SendCRM {
    width: 70%;
    height: 56%;
  }
  .clockIconContainer {
    margin-left: 268px;
    width: fit-content;
  }
  .pacientesContainer {
    margin-top: 0px;
    margin-left: 0px;
  }
  .avatars {
    margin-top: 5px;
    margin-left: 60px;
  }
  .numeroPacientes p {
    margin-left: 60px;
  }
  .numeroPacientesCRM p {
    margin-left: 60px;
  }
}
@media (max-width: 500px) {
  .react_modal_SendMessageWhats {
    width: 70%;
    height: 60%;
  }
  .avatars {
    margin-top: 3px;
    margin-left: 0px;
  }
  .numeroPacientesCRM {
    position: absolute;
    margin-left: 0px;
    margin-bottom: 50px;
  }
  .pacientesContainer p {
    position: absolute;
    margin-bottom: 50px;
  }
  .clockIconContainer {
    display: none;
  }

  .numeroPacientes p {
    height: 700px;
  }
}

.mensagemPacienteCRM {
  padding-top: 15px;
}
.mensagemPacienteCRM p {
  color: var(
    --neutral-colors-gray-gray-700,
    var(--neutral-colors-gray-gray-700, #535353)
  );

  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;

  position: absolute;
  margin-left: 25px;
  margin-top: -9px;
  background-color: white;
}

.mensagemPacienteCRM textarea {
  display: flex;
  padding: 20px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-colors-gray-gray-400, #afafaf);
  background: var(--neutral-colors-white, #fff);
  width: 100%;
  height: 70px;
  resize: none;
}

.react_modal_SendCRM {
  width: 60%;
  height: 50%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  padding: 24px;
}

.headerEmail {
  margin-top: 15px;
  gap: 8px;
  padding-bottom: 15px;
}

.headerEmail h2 {
  color: var(--neutral-colors-black, #000);
  gap: 5px;
  font-family: Segoe UI Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.headerEmail p {
  color: var(--neutral-colors-gray-gray-600, #666);

  font-family: Segoe UI Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Assunto {
  padding-top: 15px;
}
.Assunto p {
  color: var(
    --neutral-colors-gray-gray-700,
    var(--neutral-colors-gray-gray-700, #535353)
  );

  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;

  position: absolute;
  margin-left: 25px;
  margin-top: -9px;
  background-color: white;
}

.Assunto textarea {
  display: flex;
  padding: 20px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-colors-gray-gray-400, #afafaf);
  background: var(--neutral-colors-white, #fff);
  width: 100%;
  height: 70px;
  resize: none;
}

.react_modal_SendEmail {
  width: 60%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  padding: 24px;
}

.btnEmail {
  display: flex;
  justify-content: end;
  margin-top: 48px;
}

.btnEmail button {
  display: flex;
  height: 50px;
  padding: 10px 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 56px;
  background: var(--serodonto-primary-color-main-green-700, #007e69);
  border-color: white;
  box-shadow: none;

  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
  color: white;
}

.hr {
  margin-top: 20px;
  color: #ebeef3;
}
.inputContainer {
  position: relative;
  display: inline-block;
}

.inputIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.inputButton {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.notSelectClinic {
  padding: 20px;
  display: none;
}
.notsubmitSendMessage {
  display: flex;
  justify-content: center;
  align-items: center;
}
.input_test {
  padding-left: 30px !important;
}
.containerButtons {
  width: 100%;
  display: grid;

  gap: 16px;
  margin-top: 60px;
}

.containerButtons > button {
  padding: 16px 0;

  border-radius: 24px;
  background: #e4e4e4;
  border: 1px solid transparent;
  color: #fff;
  outline: none;
  border: 0;
  align-items: center;
  cursor: pointer;
  display: grid;
  justify-content: center;
}

.containerButtons > button:first-child {
  background: #e4e4e4;
  border: 1px solid transparent;
  color: var(--gray-900);
}

.containerButtons > button:last-child {
  background: #007e69;
}

.containerButtons > button:last-child:hover {
  opacity: 0.8;
  color: #fff;
}

.container_buttons_loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

.container_content p {
  text-align: left;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 400px) {
  .inputForm {
    max-width: 274px;
  }
  .button {
    min-width: 265px !important;
  }
  .submitSendMessage2 {
    min-width: 265px !important;
  }
}

@media (max-width: 880px) {
  .react_modal_AddIndicator {
    width: 92vw;
  }

  .container_content h2 {
    font-size: 1.25rem;
  }

  .container_content p {
    font-size: 0.875rem;
    margin-top: 5px;
    text-align: left;
  }

  .selectUser {
    width: 100% !important;
  }

  .selectClinicText {
    font-size: 0.75rem !important;
  }

  .inputForm {
    min-width: 100% !important;
    gap: 20px !important;
  }

  .button {
    min-width: 100% !important;
  }

  .switchField {
    overflow: auto;
  }

  .selected {
    background: var(--neutral-colors-gray-river-gray-river-100, #ced5de);
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 0.625rem;
  }

  .submitSendMessageNext {
    margin-top: 20px;
  }

  .submitSendMessageNext > button {
    margin: 0 !important;
  }
}
