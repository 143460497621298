.textInputContainer {
    position: relative;
    height: 50px;
    min-height: 50px;
    width: 100%;
  }
  
  .label {
    position: absolute;
    left: 16px;
    top: 0%;
    background-color: white;
    transform: translate(0px, -50%);
    color: var(--gray-700);
    pointer-events: none;
    transition: 0.2s;
    font-weight: 600;
    line-height: 0.9rem;
    z-index: 2;
  }