.title {
  font-size: 14px;
  color: rgb(123, 136, 156);
  font-weight: 700;
  margin-bottom: 12px;
  text-transform: uppercase;
  text-align: left;
}

.addItensToScreenWrapper {
  width: 100%;
  padding: 1rem 0;
}

.addItensToScreenCards {
  display: flex;
  gap: 12px;
}

.addItensToScreenCard {
  display: grid;
  gap: 16px;
  padding: 16px 16px 32px 16px;
  place-content: center;
  border-radius: 12px;
  border: 1px solid var(--neutral-colors-gray-gray-300, #d3d3d3);
  width: 170px;
  max-height: 142px;
  cursor: pointer;
}

.addItensToScreenCard:hover {
  background-color: #d3d3d3;
}

.selected {
  background-color: #d3d3d3;
}

.addItensToScreenCard p {
  color: var(--neutral-colors-gray-gray-900, #151515);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.addItensToScreenCard svg {
  margin: 0 auto;
}
