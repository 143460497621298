@font-face {
  font-family: "Segoe UI Variable";
  src: url(../../../../fonts/SegoeUi/SegoeUIVF.ttf);
}
.react_modal_SendMessage {
  width: 400px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  padding: 24px;
}



.react_modal_AddIndicator {
  width: 800px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  padding: 24px;
}

@media (max-width: 800px) {
  .react_modal_AddIndicator {
    max-width: 600px;
  }
}

@media (max-width: 600px) {
  .react_modal_AddIndicator {
    max-width: 300px;
  }

  .label_multi_select {
    bottom: 92px !important;
  }

  .selected_options {
    flex-wrap: wrap;
  }

  .container_buttons {
    flex-direction: column;
  }

  .container_buttons button {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .react_modal_SendMessage {
    max-width: 250px;
  }
}

@media (max-width: 300px) {
  .react_modal_AddIndicator {
    max-width: 250px;
  }

  .selected_options {
    gap: 2px !important;
  }
}

.fields_wrapper {
  margin: 40px 0;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.icon_wrapper {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 6px;
  top: 14px;
}

.selected_options {
  display: flex;
  gap: 10px;
}

.examsText {
  color: #9c7d7b;
  font-weight: 700;
}
.examsBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  padding: 4px 12px;
  font-weight: 600;
  border-radius: 12px;
  font-size: 12px;
}

.react_modal_AddIndicator .examsBadge img {
  transform: translateY(2px);
}

.tomografiaBadge {
  color: #d72c69;
  background-color: #fddae6;
}
.raioxBadge {
  color: #078295;
  background-color: #bcf0f8;
}
.atmBadge {
  color: #b83d9e;
  background-color: #f2dfee;
}
.iconContainer,
.clockIconContainer {
  display: inline;
  margin-right: 8px;
}

.icon_wrapper svg {
  display: flex;
  width: 20px;
  height: 20px;
  color: #8e8e8e;
}

.icon_wrapper svg:hover {
  color: #000000;
  transform: scale(1.1);
}

.container_content {
  text-align: left;
}

.container_content h2 {
  font-size: 1.125rem;
}

.container_content p {
  font-size: 0.875rem;
  color: var(--gray-river);
  margin-top: 5px;
}

.container_input {
  padding: 50px 24px 45px;
}

.submitSendMessage {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.submitSendMessage svg {
  height: 20px;
  width: 20px;
  padding-top: 5px;
}

.radioGroup {
  display: grid;
  gap: 24px;
  font-size: 18px;
  margin-bottom: 40px;
  --checked-clr: #007e69;
}

.label {
  display: flex;
  align-items: center;
  gap: 8px;
}

input[type="radio"] {
  width: 16px;
  height: 16px;
  accent-color: var(--checked-clr);
}

.fields_wrapper {
  display: grid;
  gap: 16px;
}

.container_select {
  position: relative;
}

.label_select {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--gray-700);
  position: absolute;
  bottom: 41px;
  left: 16px;
  background: #fff;
  z-index: 1;
}

.select:valid ~ .label_select {
  font-size: 0.875rem;
  font-weight: 600;
  background: #ffffff;
}

.select:focus ~ .label_select {
  color: red;
  font-size: 1rem;
  font-weight: 600;
  background: #ffffff;
}

.select:placeholder-shown ~ .label_select {
  font-size: 0.875rem;
  font-weight: 600;
  background: #ffffff;
}

.container_buttons {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.container_buttons > button {
  min-width: 176px;
  max-height: 40px;
}

.buttonMore {
  color: #8e8e8e;
  border: 1px solid #007e69;
}
.buttonMore:hover {
  border: 1px solid #007e69;
}

@media (max-width: 720px) {
  .container_input {
    padding-bottom: 30px;
  }
}

.container_button {
  padding: 0 24px;
}

.react_modal_SendMessageWhats {
  width: 800px;
  height: 595px;
  background: #fff;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  padding: 24px;
}

.headerWhats {
  margin-top: 15px;
  padding-bottom: 15px;
  gap: 32px;
}

.headerWhats h2 {
  color: var(--neutral-colors-black, #000);
  gap: 5px;
  font-family: Segoe UI Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.headerWhats p {
  color: var(--neutral-colors-gray-gray-600, #666);
  font-family: Segoe UI Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 5px;
}

.cardContentWhatsHeader h2 {
  color: var(--neutral-colors-gray-river-gray-river-400, #7b889c);

  font-family: Segoe UI Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 3px;
}

.cardContentWhats {
  height: 100px;
  width: 752px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--neutral-colors-gray-gray-200, #e4e4e4);
  background: var(--neutral-colors-white, #fff);
  position: relative;
  padding-left: 12px;
  padding-top: 8px;

  /* button */
  box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.2);
}

.cardContentWhats h2 {
  color: var(--neutral-colors-gray-gray-800, #353535);
  padding-top: 8px;
  padding-left: 15px;
  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.clockIconContainerwhats {
  border-radius: 0px 0px 8px 8px;
  background: var(--neutral-colors-gray-river-gray-river-50, #ebeef3);
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  left: 79%;
  margin-top: -40px;
  display: flex;
  width: fit-content;
}

.clockIconContainerwhats p {
  color: var(--neutral-colors-gray-river-gray-river-700, #465364);

  font-family: Segoe UI Variable;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: sticky;
}

.pacientesContainerwhats {
  display: flex;
}

.pacientesContainerwhats p {
  color: var(--neutral-colors-gray-gray-700, #535353);
  margin-top: 18px;
  padding-left: 15px;
  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
  margin-left: 40px;
}

.avatarswhats {
  position: absolute;
  margin-top: 15px;
  margin-left: -2px;
}

.avatarwhats {
  border-radius: 50%;
  margin-left: 7px;
  margin-bottom: 15px;
}
.avatar1whats {
  position: absolute;
  margin-right: 7px;
  border-radius: 50%;
}
.avatar2whats {
  position: absolute;
  margin-left: 8px;
  border-radius: 50%;
}

.pacientesContainerwhats p {
  position: absolute;
  padding-left: 6%;
}

.numeroPacienteswhats p {
  color: var(--neutral-colors-gray-gray-500, #8e8e8e);
  font-family: Segoe UI Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 73%;
}

.linhawhats {
  stroke-width: 1px;
  stroke: var(--neutral-colors-gray-river-gray-river-50, #ebeef3);
  padding-left: 20px;
}

.mensagemPacientewhats {
  padding-top: 15px;
  padding-bottom: 45px;
}
.mensagemPacientewhats p {
  color: var(
    --neutral-colors-gray-gray-700,
    var(--neutral-colors-gray-gray-700, #535353)
  );

  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;

  position: absolute;
  margin-left: 25px;
  margin-top: -9px;
  background-color: white;
}

.mensagemPacientewhats textarea {
  display: flex;
  padding: 20px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-colors-gray-gray-400, #afafaf);
  background: var(--neutral-colors-white, #fff);
  width: 100%;
  height: 207px;
  resize: none;
  overflow: hidden;
}

.whatsBtn {
  display: flex;
  justify-content: end;
}

.whatsBtn button {
  display: flex;
  height: 40px;
  padding: 10px 48px;
  justify-content: end;
  align-items: center;
  gap: 8px;
  border-radius: 56px;
  background: var(--serodonto-primary-color-main-green-700, #007e69);
  border-color: white;
  box-shadow: none;
  cursor: pointer;
  border: none;

  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
  color: white;
  position: absolute;
  margin-top: 5px;
}
@media (max-width: 800px) {
  .avatarswhats {
    margin-left: -5px;
    position: absolute;
  }
  .clockIconContainerwhats {
    max-width: 90px;
    left: 75%;
  }
  .react_modal_SendMessageWhats {
    height: 600px;
  }
  .mensagemPacientewhats textarea {
    height: 300px;
  }
}
@media (max-width: 600px) {
  .clockIconContainerwhats {
    display: none;
  }
  .pacientesContainerwhats {
    margin-top: -20px;
    padding-right: 5px;
  }
  .pacientesContainerwhats p {
    padding-left: 30px;
  }
  .numeroPacienteswhats p {
    padding-left: 65px;
    position: absolute;
    margin-top: 30px;
  }
  .cardContentwhats {
    height: 90px;
  }
  .react_modal_Sendwhats {
    height: 35%;
  }
  .btnwhats button {
    margin-right: 26px;
    margin-top: 30px;
  }
  .cardContentwhats h2 {
    font-size: 20px;
    padding-left: 5px;
  }
  .whatsBtn button {
    padding-left: 70px;
  }
}
@media (max-width: 400px) {
  .react_modal_SendMessageWhats {
    height: 650px;
    width: 350px;
  }
  .whatsBtn button {
    padding-left: 70px;
    margin-right: -7px;
    margin-left: 20px;
    margin-top: 30px;
  }
  .cardContentwhats {
    height: 140px;
  }
  .pacientesContainerwhats p {
    padding-left: 28px;
  }
  .numeroPacienteswhats {
    padding-left: 20px;
  }
  .avatarswhats {
    padding-top: 20px;
  }
}

.react_modal_SendCRM {
  width: 800px;
  height: 424px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  padding: 24px;
}

.headerCRM {
  margin-top: 15px;
  gap: 8px;
  padding-bottom: 15px;
}

.headerCRMs h2 {
  color: var(--neutral-colors-black, #000);
  gap: 5px;
  font-family: Segoe UI Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.headerCRM p {
  color: var(--neutral-colors-gray-gray-600, #666);
  font-family: Segoe UI Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cardContentCRM {
  height: 100px;
  width: 752px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--neutral-colors-gray-gray-200, #e4e4e4);
  background: var(--neutral-colors-white, #fff);
  position: relative;
  padding-left: 12px;
  padding-top: 8px;

  /* button */
  box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.2);
}

.cardContentCRM h2 {
  color: var(--neutral-colors-gray-gray-800, #353535);
  padding-top: 8px;
  padding-left: 10px;
  font-family: Segoe UI Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.clockIconContainerCRM {
  border-radius: 0px 0px 8px 8px;
  background: var(--neutral-colors-gray-river-gray-river-50, #ebeef3);
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  left: 78%;
  margin-top: -45px;
  display: flex;
  width: fit-content;
}

.clockIconContainerCRM p {
  color: var(--neutral-colors-gray-river-gray-river-700, #465364);

  font-family: Segoe UI Variable;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: sticky;
}

.pacientesContainerCRM {
  display: flex;
}

.pacientesContainerCRM p {
  color: var(--neutral-colors-gray-gray-700, #535353);
  padding-top: 15px;
  padding-left: 15px;
  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
  margin-left: 50px;
}

.avatarsCRM {
  position: absolute;
  margin-top: 12px;
  margin-left: 5px;
}

.avatarCRM {
  border-radius: 50%;
  margin-left: 7px;
  margin-bottom: 15px;
}
.avatar1CRM {
  position: absolute;
  margin-right: 7px;
  border-radius: 50%;
}
.avatar2CRM {
  position: absolute;
  margin-left: 8px;
  border-radius: 50%;
}

.pacientesContainerCRM p {
  position: absolute;
  padding-left: 6%;
}

.numeroPacientesCRM p {
  color: var(--neutral-colors-gray-gray-500, #8e8e8e);
  font-family: Segoe UI Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 68%;
}

.linhaCRM {
  stroke-width: 1px;
  stroke: var(--neutral-colors-gray-river-gray-river-50, #ebeef3);
  padding-left: 20px;
  padding-bottom: 20px;
}

.btnCRM {
  display: flex;
  justify-content: end;
  padding-top: 20px;
}

.btnCRM button {
  display: flex;
  height: 40px;
  padding: 10px 48px;
  justify-content: end;
  align-items: center;
  gap: 8px;
  border-radius: 56px;
  background: var(--serodonto-primary-color-main-green-700, #007e69);
  border: none;
  border-color: white;
  box-shadow: none;
  cursor: pointer;

  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
  color: white;
  position: absolute;
  margin-top: 15px;
}
@media (max-width: 1920px) {
  .react_modal_SendCRM {
    height: 400px;
  }
}
@media (max-width: 1400px) {
  .react_modal_SendCRM {
    height: 400px;
  }
}
@media (max-width: 920px) {
  .avatarsCRM {
    margin-left: -4px;
  }
  .clockIconContainerCRM {
    left: 75%;
  }
}
@media (max-width: 800px) {
  .avatarsCRM {
    margin-left: -4px;
  }
  .clockIconContainerCRM {
    max-width: 90px;
    left: 75%;
  }
}

@media (max-width: 600px) {
  .clockIconContainerCRM {
    display: none;
  }
  .pacientesContainerCRM {
    margin-top: -30px;
    padding-right: 5px;
  }
  .pacientesContainerCRM p {
    padding-left: 30px;
  }
  .numeroPacientesCRM p {
    padding-left: 65px;
    position: absolute;
    margin-top: 30px;
  }
  .cardContentCRM {
    height: 90px;
  }
  .react_modal_SendCRM {
    height: 35%;
  }
  .btnCRM button {
    margin-right: 26px;
    margin-top: 30px;
  }
  .cardContentCRM h2 {
    font-size: 20px;
    padding-left: 5px;
  }
  .react_modal_SendCRM {
    height: 350px;
  }
}
@media (max-width: 414px) {
  .react_modal_SendCRM {
    height: 450px;
  }
  .btnCRM button {
    padding-left: 50px;
    margin-left: 10px;
    margin-right: -10px;
    margin-top: 30px;
  }
  .cardContentCRM {
    height: 140px;
  }
  .pacientesContainerCRM p {
    padding-left: 28px;
  }
  .numeroPacientesCRM {
    margin-top: 10px;
    padding-left: 20px;
  }
  .avatarsCRM {
    padding-top: 20px;
  }
}
@media (max-width: 375px) {
  .react_modal_SendCRM {
    height: 450px;
    width: 250px;
  }
  .btnCRM button {
    padding-left: 50px;
    margin-left: 10px;
    margin-right: -10px;
    margin-top: 30px;
  }
  .cardContentCRM {
    height: 140px;
  }
  .pacientesContainerCRM p {
    padding-left: 28px;
  }
  .numeroPacientesCRM {
    margin-top: 10px;
    padding-left: 20px;
  }
  .avatarsCRM {
    padding-top: 20px;
  }
}

.react_modal_SendEmail {
  width: 800px;
  height: 671px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  outline: none;
  padding: 24px;
  position: relative;
}
.icon_wrapper {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 6px;
  top: 14px;
  cursor: pointer;
}

.headerEmail {
  margin-top: 15px;
  gap: 8px;
  padding-bottom: 15px;
}

.headerEmail h2 {
  color: var(--neutral-colors-black, #000);
  gap: 5px;
  font-family: Segoe UI Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.headerEmail p {
  color: var(--neutral-colors-gray-gray-600, #666);
  font-family: Segoe UI Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cardContentEmail {
  height: 112px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--neutral-colors-gray-gray-200, #e4e4e4);
  background: var(--neutral-colors-white, #fff);
  position: relative;

  /* button */
  box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.2);
}

.cardContentEmail h2 {
  color: var(--neutral-colors-gray-gray-800, #353535);
  padding-top: 8px;
  padding-left: 10px;
  font-family: Segoe UI Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.clockIconContainerEmail {
  border-radius: 0px 0px 8px 8px;
  background: var(--neutral-colors-gray-river-gray-river-50, #ebeef3);
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  left: 76%;
  margin-top: -37px;
  display: flex;
  width: fit-content;
}

.clockIconContainerEmail p {
  color: var(--neutral-colors-gray-river-gray-river-700, #465364);

  font-family: Segoe UI Variable;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: sticky;
}

.pacientesContainerEmail {
  display: flex;
}

.pacientesContainerEmail p {
  color: var(--neutral-colors-gray-gray-700, #535353);
  padding-top: 17px;
  padding-left: 15px;
  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
  margin-left: 50px;
}

.avatarsEmail {
  position: absolute;
  padding-top: 28px;
  margin-left: 5px;
}

.avatarEmail {
  border-radius: 50%;
  margin-left: 7px;
  margin-bottom: 15px;
}
.avatar1Email {
  position: absolute;
  margin-right: 7px;
  border-radius: 50%;
}
.avatar2Email {
  position: absolute;
  margin-left: 8px;
  border-radius: 50%;
}

.pacientesContainerEmail p {
  position: absolute;
  padding-left: 6%;
  padding-top: 30px;
}

.numeroPacientesEmail p {
  color: var(--neutral-colors-gray-gray-500, #8e8e8e);
  font-family: Segoe UI Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 70%;
  padding-top: 30px;
}

.linhaCRM {
  stroke-width: 1px;
  stroke: var(--neutral-colors-gray-river-gray-river-50, #ebeef3);
  padding-left: 20px;
  padding-bottom: 20px;
}
.mensagemPacienteEmail {
  padding-top: 15px;
}
.mensagemPacienteEmail p {
  color: var(
    --neutral-colors-gray-gray-700,
    var(--neutral-colors-gray-gray-700, #535353)
  );

  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;

  position: absolute;
  margin-left: 35px;
  margin-top: -9px;
  background-color: white;
}

.mensagemPacienteEmail textarea {
  display: flex;
  padding: 16px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-colors-gray-gray-400, #afafaf);
  background: var(--neutral-colors-white, #fff);
  width: 100%;
  height: 200px;
  resize: none;
  overflow: hidden;
}

.btnEmail {
  display: flex;
  justify-content: end;
  padding-top: 30px;
}

.btnEmail button {
  display: flex;
  height: 40px;
  padding: 10px 48px;
  justify-content: end;
  align-items: center;
  gap: 8px;
  border-radius: 56px;
  background: var(--serodonto-primary-color-main-green-700, #007e69);
  border-color: white;
  box-shadow: none;
  cursor: pointer;
  border: none;

  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
  color: white;
  position: absolute;
  margin-top: 15px;
}
.AssuntoEmail {
  padding-top: 15px;
}
.AssuntoEmail p {
  color: var(
    --neutral-colors-gray-gray-700,
    var(--neutral-colors-gray-gray-700, #535353)
  );

  font-family: Segoe UI Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;

  position: absolute;
  margin-left: 25px;
  margin-top: -9px;
  background-color: white;
}

.AssuntoEmail textarea {
  display: flex;
  padding: 15px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-colors-gray-gray-400, #afafaf);
  background: var(--neutral-colors-white, #fff);
  width: 100%;
  height: 50px;
  resize: none;
  overflow: hidden;
}

@media (max-width: 1920px) {
  .react_modal_SendEmail {
    height: 671px;
  }
}
@media (max-width: 1024px) {
  .avatarsEmail {
    padding-top: -5px;
    margin-left: 5px;
  }
  .mensagemPacienteEmail p {
    font-size: 14px;
    padding-left: -7px;
  }
}
@media (max-width: 800px) {
  .avatarsEmail {
    margin-left: -4px;
  }
  .clockIconContainerEmail {
    max-width: 90px;
    left: 75%;
  }
}
@media (max-width: 600px) {
  .clockIconContainerEmail {
    display: none;
  }
  .pacientesContainerEmail {
    margin-top: -30px;
    padding-right: 5px;
  }
  .pacientesContainerEmail p {
    padding-left: 30px;
  }
  .numeroPacientesEmail p {
    padding-left: 65px;
    position: absolute;
    margin-top: 30px;
  }
  .cardContentEmail {
    height: 90px;
  }
  .react_modal_SendEmail {
    height: 35%;
  }
  .btnEmail button {
    margin-right: 10px;
    margin-top: 30px;
  }
  .cardContentEmail h2 {
    font-size: 20px;
    padding-left: 5px;
  }
  .react_modal_SendEmail {
    height: 580px;
  }
}
@media (max-width: 414px) {
  .react_modal_SendEmail {
    height: 600px;
    width: 330px;
  }
  .btnEmail button {
    padding-left: 50px;
    margin-left: 0px;
    margin-right: 8px;
    margin-top: 30px;
  }
  .cardContentEmail {
    height: 100px;
  }
  .pacientesContainerEmail p {
    padding-left: 28px;
  }
  .numeroPacientesEmail {
    margin-top: 10px;
    padding-left: 20px;
  }
  .avatarsEmail {
    padding-top: 20px;
  }
}
@media (max-width: 375px) {
  .react_modal_SendEmail {
    height: 590px;
    width: 250px;
  }
  .btnEmail button {
    padding-left: 50px;
    margin-left: 10px;
    margin-right: -10px;
    margin-top: 30px;
  }
  .cardContentEmail {
    height: 140px;
  }
  .pacientesContainerEmail p {
    padding-left: 28px;
  }
  .numeroPacientesEmail {
    margin-top: 10px;
    padding-left: 20px;
  }
  .avatarsEmail {
    padding-top: 20px;
  }
  .mensagemPacienteEmail textarea {
    height: 160px;
  }
  .mensagemPacienteEmail p {
    font-size: 14px;
    padding-left: -7px;
  }
}
