.wrapperDicon {
  overflow-y: hidden;
  width: calc(100vw - 380px) !important;
  height: calc(100vh - 140px) !important;
}

/*1 quadrado */
.container3dV1 {
  padding-left: 0px;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.divContainer3dV1 {
  position: relative;
  height: 100%;
  border-radius: 12px;
  width: calc(100vw - 380px) !important;
  height: 100% !important;
  overflow: hidden;
}

.divContainer3dV1::before {
  content: "";
  display: block;
  height: 100%;
  vertical-align: middle;
}

.divContainer3dV1 > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container3dV2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 10px 0;
  object-fit: cover;
}

.divContainer3dV2 {
  width: 400px;
  max-width: 680px;
  height: 420px;
  border-radius: 12px;
  border: 2px solid #353535;
  position: relative;
  overflow: hidden;
}

.divContainer3dV2 * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.divContainer3dV2::before {
  content: "";
  display: block;
  height: 100%;
  vertical-align: middle;
}

.divContainer3dV2 > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 2frente */
.container3dV3 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  height: 100%;
}

.divContainer3dV3 {
  width: 100% !important;
  height: 100% !important;

  border-radius: 12px;
  border: 2px solid #353535;
  position: relative;

  flex-grow: 1;
  flex-basis: 100px;
  overflow: hidden;
}

.divContainer3dV3 > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.divContainer3dV3::before {
  content: "";
  display: block;
  height: 100%;
  vertical-align: middle;
}

.divContainer3dV3 * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*4 quadrados frente */
.container3dV4 {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.divContainer3dV4 {
  /* width: 291px !important; */
  height: 380px !important;
  border-radius: 12px;
  border: 2px solid #353535;
  position: relative;

  flex-grow: 1;
  flex-basis: 100px;
  overflow: hidden;
}

.divContainer3dV4::before {
  content: "";
  display: block;
  height: 100%;
  vertical-align: middle;
}

.divContainer3dV4 > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.divContainer3dV4 * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*4 quadrados quadrado */
.container3dV5 {
  height: 100%;

  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  gap: 10px !important;
}

.divContainer3dV5 {
  border-radius: 12px;
  border: 2px solid #353535;
  position: relative;
  overflow: hidden;
}

.divContainer3dV5::before {
  content: "";
  display: block;
  height: 100%;
  vertical-align: middle;
}

.divContainer3dV5 > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
