.wrapper {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;

  background: none;
  border-radius: 8px;
  padding: 16px 0;
  transition: all 300ms;
}

.wrapper_active {
  display: flex;
  align-items: center;
  background: none;
  height: auto;
  border-radius: 8px;
  transition: all 300ms;
  padding-bottom: 16px;
}

.form {
  width: 100%;
}

.loading_container {
  position: relative;
}

.wrapper_input {
  position: relative;
  width: 100%;
  min-width: 190px;
  max-width: 256px;
  height: 40px;
  background-color: #fff;
  border-radius: 80px;
  padding: 8px 16px;
}

.wrapper_inputDate svg {
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  z-index: 999;
  position: absolute;
  color: #cccccc;
}

.containerInputPatientAndButton {
  width: 100%;
  max-width: 353px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--gray-river-300);
  padding: 3px;
  border-radius: 20px;
}

.containerSelectsAndButtonUpdate {
  display: flex;
  align-items: center;
  gap: 10px;
}

.buttonUpdate {
  width: 132px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 20px;
  border: none;
  outline: none;
  background: #bbdefb;
  color: #0d47a1;
  cursor: pointer;
}

.buttonUpdate:hover:not([disabled]) {
  opacity: 0.8;
}

.inputDate {
  width: 100%;
  height: 41px;
  border-radius: 80px;
  border: none;
  outline: none;
  padding: 16px;
  cursor: pointer;
}

.wrapper_input svg {
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  position: relative;
}

.wrapper_input .container_iconX {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.wrapper_input .container_iconX:hover .iconX {
  opacity: 0.7;
}

.wrapper_input .container_iconX .iconX {
  color: #c80000;
}

.container_buttons_loading {
  position: absolute;
  top: 33%;

  transform: translateY(-50%);
  left: 16px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}


.loading_spinne {
  position: absolute;
  top: 27%;
  left: 74%;
  transform: translate(-50%, -50%);
  z-index: 999;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid rgb(30, 199, 98);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
}

.loading_spinnes {
  position: absolute;
  top: 34%;
  left: 74%;
  transform: translate(-50%, -50%);
  z-index: 999;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid rgb(30, 199, 98);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.input {
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 80px;
  padding: 0 16px;
  padding-left: 44px;
  position: absolute;
  outline: none;
  border: 0;
  color: var(--gray-river-500);
}

.input::placeholder {
  color: var(--gray-river-500);
}

.select {
  width: 100%;
  max-width: 230px;
  min-height: 41px;
  max-height: auto;
  background-color: #fff;
  border-radius: 80px;
  outline: none;
  border: 1px solid red !important;
}

.selectOption {
  z-index: 10;
}

.containerInputs {
  display: flex;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  align-items: center;
  gap: 16px;
  transition: all 1s;
}

.containerInputs_active {
  height: 0px;
  opacity: 0;
  display: none;
  transition: all 1s;
}

.buttonFilter {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 0;
  color: #fff;
  background: var(--gray-river-600);
  cursor: pointer;
  transition: background 150ms;
}

.buttonFilter svg {
  width: 16px;
  height: 16px;
}

.buttonFilter:hover {
  background: var(--gray-river-900);
}

.OptionSituation {
  display: flex;
  gap: 8px;
}

.containerButtonSubmit {
  width: 137px;
  height: 40px;
}

.containerButtonSubmit > button {
  min-width: 100%;
  height: 100%;
}

.inputSelect {
  width: 100%;
  max-width: 230px;
  min-height: 41px;
  max-height: auto;
  background-color: #fff;
  border-radius: 80px;
  outline: none;
}

/*calendar*/
.container_buttons {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container_buttons > button {
  height: 100%;
  min-height: 45px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--gray-river-700);
}

.container_buttons > button:hover {
  background: var(--green-50);
}

/*------ select age ------ */

.container_inputRange {
  position: relative;
  width: 100%;
  max-width: 230px;
  display: flex;
  flex-direction: column;
}

.container_inputRange span {
  font-size: 0.875rem;
}

.container_inputRange input {
  margin-bottom: 10px;
}

.container_inputRange .rangeContainer {
  position: absolute;
  min-width: 412px;
  top: 40px;
  left: 0;
  border-radius: 12px;
  border: 0.5px solid #efefef;
  padding: 32px 24px;
  background: #fff;
  display: flex;
  box-shadow: 2px 2px 16px -4px rgba(34, 34, 34, 0.2);
  display: flex;
  flex-direction: column;
}

.rangeContainer p {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 40px;
  color: var(--gray-800);
}

.rangeContainer input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  width: 100%;
  height: 0.5em;
  position: relative;
  border-radius: 5px;
  background-color: var(--green-50);
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  background-color: #007e69;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  background-color: #007e69;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-webkit-progress-value {
  background-color: #007e69;
}

input[type="range"]::-moz-range-progress {
  background-color: #007e69;
}

.container_values {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 0.875rem;
  color: var(--gray-river-400);
}

.continerInputs {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 35px;
}

.continerInputs > input {
  width: 100%;
  height: 50px;
  padding: 0 16px;
  border-radius: 12px;
  border: 1px solid #afafaf;
  outline: none;
}

.containerButtons {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 35px;
  gap: 16px;
}

.containerButtons > button {
  width: 151px;
  height: 40px;
  border-radius: 56px;
  cursor: pointer;
  outline: none;
  border: none;
}

.containerButtons > .buttonCancel {
  background: none;
  color: var(--gray-500);
  transition: all 150ms;
}

.containerButtons > .buttonCancel:hover {
  background: var(--gray-200);
  color: #000;
}

.containerButtons > .buttonToApply {
  background: var(--green-700);
  color: #fff;
  transition: all 150ms;
}

.containerButtons > .buttonToApply:hover {
  background: var(--green-800);
}

.containerbuttonPesquisa {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 0 16px;
  width: 120px;
  height: 39px;
  border-radius: 80px;
  border: 0;
  color: #fff;
  background: var(--gray-river-400);
  cursor: pointer;
  transition: background 150ms;
}

.containerbuttonPesquisa:hover {
  background: var(--gray-river-500);
}

@media (max-width: 1500px) {
  .containerInputswidth {
    max-width: 60%;
  }
}

@media (max-width: 768px) {
  .wrapper_input {
    max-width: 80% !important;
  }
  .containerInputswidth {
    max-width: 60%;
  }

  .wrapper_active {
    min-height: 72px;
    padding-bottom: 0;
  }

  .containerInputs {
    gap: 0px;
  }

  .containerbuttonPesquisa {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 0;
    width: 45px;
    height: 40px;
    border-radius: 50%;
    border: 0;
    color: #fff;
    background: var(--gray-river-600);
    cursor: pointer;
    transition: background 150ms;
    font-size: 12px;
  }

  .buttonFilter {
    display: none;
  }
}

@media (max-width: 280px) {
  .wrapper_input {
    max-width: 0;
    width: 62%;
  }
}
