.wrapper_input {
  position: relative;
  width: 100%;
  height: 40px;
  background-color: var(--gray-100);
  border-radius: 80px;
  padding: 8px 16px;
  text-align: center;
  margin: 0 auto;
}

.wrapper_input svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  z-index: 1;
  color: var(--gray-500);
}

.input {
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 80px;
  padding: 0 16px;
  padding-left: 44px;
  position: absolute;
  outline: none;
  border: 0;
  color: var(--gray-500);
  background-color: var(--gray-100);
}
.wrapper_input .buttonDeleteSearch {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
