.dashboard-layout {
  position: relative;
  display: flex;
}

.dashboard-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  /*overflow criado apra corrigir espaçamento entre cards do carrossel do academy */
  overflow-x: auto;
}