.wrapper {
  height: 100%;
  margin-top: 16px;
}

.wrapper nav ul {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
}

.wrapper nav ul li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding: 8px 12px;
}

.wrapper nav ul li svg {
  color: #fff;
}

.wrapper nav ul li.linkActive {
  display: flex;
  align-items: center;
  gap: 2px;
  border-radius: 14px 14px 0px 0px;
  background: var(--gray-800);
}

.wrapper nav ul li.linkActive span {
  font-size: 0.875rem;
  font-weight: 600;
}

.wrapper nav ul li svg {
  width: 24px;
  height: 24px;
  opacity: 0.6;
}

.wrapper_renderContent {
  position: relative;
  height: calc(100vh - 145px);
  overflow-y: hidden;
  background: var(--gray-800);
  border-radius: 12px 12px 0px 0px;
}

.renderContent {
  /* height: 100%; */
  padding: 16px;
  background: var(--gray-800);
  border-radius: 12px 12px 0px 0px;
}

.wrapper_renderContent .footerSidebar {
  position: absolute;
  width: 100%;
  bottom: 2px;
  left: 0;
  z-index: 1px;
  background: var(--gray-800);
}

.footerSidebar p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 0.75rem;
  color: var(--gray-300);
  padding-bottom: 5px;
}

.footerSidebar > div {
  display: flex;
  align: center;
  justify-content: center;
  gap: 30px;
}

.footerSidebar > div img {
  width: 30%;
}

.footerSidebar > div .border {
  width: 1px;
  height: 40px;
  background: var(--gray-700);
}
