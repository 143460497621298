.aside {
  width: 380px;
  min-height: 120vh;
  height: auto;
  background-color: #f8f8f8;
  padding: 16px 0;
  transition: width ease-out 300ms;

  padding-bottom: 0px;
}

.aside_collapse {
  position: absolute;

  background-color: #fff;
  padding: 16px 0;
  transition: width ease-out 300ms;
}

.aside nav {
  width: 100%;
}

.aside nav ul {
  list-style: none;
  padding: 0;
}

.containerHeaderCollapse {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9px;
  padding: 0px 11px;
  width: 40px;
}

.containerHeaderCollapse svg {
  width: 22px;
  color: #298e79;
}

.containerHeaderCollapse img {
  width: 44px;
  height: 44px;
}

.buttonHamburguer {
  cursor: pointer;
}

.containerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0 19px;
}

.containerHeader > a {
  text-decoration: none;
}

.containerHeader div {
  display: flex;
  align-items: center;
  gap: 9px;
}

.containerHeader strong {
  text-transform: uppercase;
  color: #151515;
  font-weight: 700;
  font-size: 0.75rem;
}

.containerHeader svg {
  width: 22px;
  color: #298e79;
}

.sectionTitle {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  flex: 2;
}

.li {
  position: relative;
  list-style-type: none;
  cursor: pointer;
  padding-bottom: 20px;
  padding-right: 20px;
  color: #57667a;
  font-size: 0.875rem;
}

.containerTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 11px;
  margin-left: 8px;
  color: "#298e79";
  transition: all ease-out 100ms;
}
.sectionTimeEnd {
  display: flex;
  align-items: center;
  gap: 6px;
}
.containerTitle_open {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 8px;

  padding: 0 11px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 12px;
  transition: all ease-out 100ms;
}

.containerIcon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.containerIcon_open {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #298e79;
}

.containerChildren {
  display: none;
  position: relative;
  height: 100%;
}

.childBorder {
  position: absolute;
  width: 1px;

  top: 0;
  left: 0;
}

.containerChildren > a {
  position: relative;
  color: #57667a;
  text-decoration: none;
}

.containerChildren > a:hover {
  color: #1565c0;
}

.border {
  position: absolute;
  left: -20px;
  top: 0;
  width: 12px;
  height: 10px;
  border-left: 2px solid #cfdaec;
  border-bottom: 2px solid #cfdaec;
  border-radius: 0 0 0 11px;
}

.containerChildren_open {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 18px;

  padding-left: 20px;
}

.containerIconCollapse {
  position: relative;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 47px;
  padding: 0 27px;
  transition: background ease-out 150ms;
  border-radius: 12px;
  cursor: pointer;
  background: transparent;
}

.containerIconCollapse_showLinkCollapse {
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 27px;
  transition: background ease-out 150ms;
  border-radius: 12px;
  cursor: pointer;
  background-color: var(--green-50);
  margin: 47px auto 0;
}

.containerIconCollapse:hover {
  background-color: var(--green-50);
  border-radius: 12px;
}

.containerIconCollapse svg {
  width: 20px;
  height: 20px;
}

.containerLinkCollapse {
  position: absolute;
  display: block;
  width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  right: -215px;
  padding: 0;
  background-color: #fafafa;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.containerLinkCollapse > a {
  display: block;
  position: relative;
  color: #57667a;
  text-decoration: none;
  font-size: 1rem;
  padding: 10px 15px;
  transition: background 100ms;
}

.containerLinkCollapse > a:hover {
  background-color: var(--green-100);
}

.containerLinkCollapse_showLinkCollapse {
  display: none;
}

.TitleNav {
  color: #535353;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
}
.TitleProgress {
  padding: 0px 20px;
  color: var(--neutral-colors-black, #000);
  font-family: Segoe UI;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;
}
.ContentProgress {
  align-items: center;
  justify-content: inherit;
  padding: 10px 20px;
  position: relative;
  height: 100%;
  display: flex;
  gap: 6px;
  flex-direction: row;
}

.TextGreen {
  color: var(--serodonto-primary-color-main-green-700, #007e69);
  font-family: Segoe UI;
  font-size: 1rem;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.sectionTime {
  font-family: Segoe UI;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.subSection {
  display: flex;
  justify-content: space-between;
}
.subSectionContent {
  display: flex;
  gap: 10px;
}
.subSectionTime {
  color: var(--neutral-colors-gray-gray-700, #535353);
  align-items: flex-end;
  font-size: 0.875rem;
  padding-right: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}
.subSectionText {
  color: var(--neutral-colors-gray-gray-700, #535353);
  font-family: Segoe UI;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.1px;
}
