.mainContainer {
  padding: 0 1rem;
}
.bodyContainer {
  margin-top: 1.55rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
@media (max-width: 1000px) {
  .bodyContainer {
    flex-wrap: wrap;
    gap: 1rem;
  }
}
