.wrapper {
  height: calc(100vh - 144px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapperFullScreen {
  height: calc(100vh - 144px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapperNotication {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapperNotication .containerContent .containerText {
  font-size: 2rem;
}

.wrapperNotication .containerContent .containerButton .buttonMore {
  min-width: 200px !important;
}

.containerContent {
  padding-bottom: 2rem;
}

.containerText {
  padding-top: 0px;
  color: var(--neutral-colors-gray-river-gray-river-800, #37404e);
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 2.25rem;
  font-weight: 700;
  line-height: normal;
}

.containerButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerSubText {
  color: var(--neutral-colors-gray-river-gray-river-800, #37404e);
  text-align: center;
  padding-top: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}
.containerImg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
}

.ContainerButton {
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.buttonMore {
  align-items: center;
  background-color: #ebeef3;
  border: none;
}
.buttonMore:hover {
  border: 1px solid #007e69;
}

@media (max-width: 880px) {
  .wrapper {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .wrapperFullScreen {
    height: calc(100vh - 119px);
  }

  .containerContent {
    padding-bottom: 0;
  }

  .containerButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .containerButton > button {
    min-width: 100% !important;
  }

  .containerText {
    font-size: 1.7rem;
  }

  .containerSubText {
    font-size: 0.8rem;
    max-width: 300px;
  }

  .ContainerButton {
    max-width: 270px;
  }

  .containerImg {
    min-width: 50vw !important;
    height: 240px;
  }

  .containerImg img {
    height: 200px !important;
  }

  .buttonMore {
    min-width: 200px !important;
  }
}
