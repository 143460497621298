.container {
  padding: 10px;
  display: grid;
  justify-content: center;
}
.containerHeaderTitle {
  padding-left: 9px;
  min-width: 1200px;
  max-width: 1200px;
}
.containerIMG {
  padding-top: 10px;
  width: 100%;
  height: 685px;
}

.video {
  width: 100%;
  height: 100%;
}
.containerButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.containerButton > button {
  font-size: 1rem;
  min-width: 163px;
  max-height: 40px;
  padding: 0;
  margin-top: 10px;
}

.buttons {
  margin-top: 10px;
}
.containertexton {
  color: #007e69;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
}
.containerInfoTeacher {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 89px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #e4e4e4;
}
.containerSelect {
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
  padding-left: 50px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
}
.containerLine {
  height: 2px;
  flex-shrink: 0;
  align-self: stretch;
  background: var(--serodonto-primary-color-main-green-700, #007e69);
  margin-bottom: 20px;
}

.containerTitle {
  color: var(--neutral-colors-gray-gray-600, #666);
  font-size: 2rem;
  font-weight: 600;
  line-height: normal;
}
.buttonMore {
  color: #007e69;
  background-color: #ffffff;
  border: 1px solid #007e69;
}

.buttonMore:hover {
  background-color: #ffffff;
  border: 1px solid #007e69;
}
.containerAnimationOn {
  color: var(
    --serodonto-primary-color-main-green-700,
    var(--serodonto-primary-color-main-green-700, #007e69)
  );
  width: 200px;
  cursor: pointer;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.containerAnimationOff {
  width: 200px;
  cursor: pointer;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: var(
    --neutral-colors-gray-gray-500,
    var(--neutral-colors-gray-gray-500, #8e8e8e)
  );
  text-align: center;

  font-size: 16px;

  font-weight: 400;
}
.containerTraco {
  border: none;
  border-top: 1px dashed #666;
  color: #333;
  overflow: visible;
  text-align: center;
  height: 5px;
  width: 39%; /* Defina a largura desejada aqui */
}
.containerTracoText {
  display: flex;
  flex-direction: row;
  padding-right: 15px;
  padding-left: 15px;
}
.containerInfoExtra {
  margin-right: 10px;
  padding: 16px;
  align-items: center;
  gap: 89px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #e4e4e4;
  width: 400px;
  height: 90px;
  flex-shrink: 0;
  justify-content: center;
}
.containerInfoExtraContent {
  align-items: center;

  display: flex;
  justify-content: space-between;
}

.containerSubtitle {
  color: var(--neutral-colors-gray-gray-600, #666);
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.containerHeaderCollapse {
  padding: 30px;
}
.academyMobile{
  display: none;
}
@media (max-width: 1740px) {
  .containerHeaderTitle {
    padding-left: 9px;
    min-width: 1000px;
    max-width: 1000px;
  }
  .containerTitle {
    font-size: 1.6rem;
  }
  .containerIMG {
    width: 877px;
    height: 487px;
  }
}

@media (max-width: 1580px) {
  .containerHeaderTitle {
    padding-left: 9px;
    min-width: 800px;
    max-width: 800px;
  }
  .containerIMG {
    width: 877px;
    height: 487px;
  }
}
@media (max-width: 1380px) {
  .containerHeaderTitle {
    padding-left: 9px;
    min-width: 700px;
    max-width: 700px;
  }
  .containerIMG {
    width: 100%;
    height: 487px;
  }
}

@media (max-width: 1220px) {
  .containerHeaderTitle {
    padding-left: 9px;
    min-width: 600px;
    max-width: 600px;
  }
  .containerIMG {
    width: 100%;
    height: 387px;
  }
}
@media (max-width: 800px) {
.academy{
  display: none;
}
.academyMobile{
  display: block;
}
}
