.loading_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: calc((100vw - 600px) - 39%);
  top: 40%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
}

.loading_container p {
  font-size: 0.875rem;
  margin-top: 10px;
  color: var(--gray-600);
  font-weight: 600;
}

.loading_spinne {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #007e69;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1300px) {
  .loading_container {
    left: 50%;
    top: 40%;
    transform: translate(-50%, -40%);
  }

  .loading_container p {
    font-size: 0.75rem;
    text-align: center;
  }

  .loading_spinne {
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-left: 3px solid #007e69;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
}
