.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

/* @media (max-width: 1024px) {
  .container {
    padding: 50px 193px;
  }
}

@media (max-width: 720px) {
  .container {
    padding: 50px 20px;
  }
} */
