.wrapper {
  width: 681px;
  min-height: 511px;
}

.container_video {
  width: 100%;
  height: 384px;
  margin-bottom: 30px;
  border-radius: 12px;
}

.container_video > .video {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.container_content {
  color: var(--gray-700);
}

.container_content h2 {
  font-size: 2rem;
  margin-bottom: 16px;
}

.container_content p {
    font-size: 1.125rem;
}
