.wrapper {
  width: 100%;
  height: calc(100vh - 130px);
  /* height: 100%; */
  /* border: 1px solid var(--gray-200); */
  border-radius: 10px;
}

@media (max-width: 1784px) {
  .wrapper {
    width: 380px !important;
  }
}

@media (min-width: 1366px) {
  .wrapper {
    width: 445px;
  }
}

.container_video {
  width: 100%;
  /* height: 249px; */
  margin-bottom: 24px;
}

.video {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
}

.content {
  max-height: 55%;
  padding: 0 24px 24px;
  overflow: auto;
}

.content::-webkit-scrollbar {
  width: 6px;
  background-color: none;
}

.content::-webkit-scrollbar-thumb {
  background-color: var(--gray-200);
  border-radius: 5px;
}

.content h2 {
  font-size: 1.125rem;
  color: var(--gray-800);
}

.content p {
  margin-top: 24px;
  text-align: justify;
  leading-trim: both;
  text-edge: cap;
  color: var(--gray-800);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  margin-top: 40px;
  padding: 10px 16px;
  width: 100%;
  border-radius: 56px;
  background: var(--green-600);
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  transition: background 150ms;
}

.button:hover {
  background: var(--green-700);
}
@media screen and (max-width: 424px) {
  .wrapper {
    display: none;
  }
}
@media screen and (max-width: 1368px) {
  .wrapper {
    display: none;
  }
}
