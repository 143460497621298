.wrapper {
  width: 100%;
  padding: 10px 40px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonIcon {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 10px;
  text-align: center;
  transition: color 150ms;
  border: 0;
  outline: none;
  background: none;
}

.containerButtonsPage {
  display: flex;
  margin: 0 16px;
  gap: 2px;
}

.buttonPage {
  width: 40px;
  height: 40px;
  background: var(--green-100);
  color: #00523d;
  outline: none;
  border: 0;
  border-radius: 8px;
}

.buttonPage_active {
  width: 40px;
  height: 40px;
  outline: none;
  border: 0;
  border-radius: 8px;
  background: transparent;
}

.buttonPage_active:hover {
  background: var(--green-200);
  border: 1px solid var(--green-200);
  color: #fff;
  cursor: pointer;
}

@media (max-width: 880px) {
  /* .wrapper {
    max-width: 92vw !important;
    padding: 0;
  } */

  .wrapper {
    max-width: 92vw !important;
    padding: 0;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    background: #fff;
    padding: 10px 0 !important;
    padding-top: 10px !important;
  }
}

@media (max-width: 280px) {
  .wrapper {
    padding: 0px;
  }
}

.container_buttons_loading {
  /* position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px; */
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #298e79;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
