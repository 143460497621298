.wrapper {
  width: 100%;
  height: calc(100vh - 105px);
  overflow: auto;
}

.wrapper::-webkit-scrollbar {
  width: 11px;
  background-color: none;
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: var(--gray-300);
  border-radius: 5px;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background-color: var(--gray-400);
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 16px;
  padding: 0 16px;
}

.container_filterAndVideo {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 118px);
}

.container_filterAndVideo .containerPagination {
  margin-top: auto !important;
}

.wrapperTableAndNavigation {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 105px);
}

/* .wrapperTableAndNavigation {
  height: calc(100vh - 105px);
  border: 1px solid green;
  overflow: auto;
}

.wrapperTableAndNavigation::-webkit-scrollbar {
  width: 11px;
  background-color: none;
}

.wrapperTableAndNavigation::-webkit-scrollbar-thumb {
  background-color: var(--gray-300);
  border-radius: 5px;
}

.wrapperTableAndNavigation::-webkit-scrollbar-thumb:hover {
  background-color: var(--gray-400);
} */

.containerButtonsChecklist {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  padding: 0 16px;
  gap: 16px;
}

.containerButtonAtualizarNotCefx {
  position: relative;
  margin-top: 25px;
}

.containerButtonAtualizar {
  width: 100%;
  height: 100%;
  padding-top: 10px;
}

.containerButtonAtualizar > button {
  width: 120px;
  height: 38px;
  border-radius: 14px;
  background: red;
  border: 0;
  color: #fff;
  background: var(--gray-river-300);
  text-transform: uppercase;
  font-size: 0.75rem;
  cursor: pointer;
}

.containerButtonsChecklist > button {
  width: 120px;
  height: 38px;
  border-radius: 14px;
  background: red;
  border: 0;
  color: #fff;
  background: var(--gray-river-300);
  text-transform: uppercase;
  font-size: 0.75rem;
  cursor: pointer;
}

.containerButtonsChecklist > button:hover {
  background: var(--gray-river-400);
}

@media screen and (max-width: 800px) {
  .containerButtonsChecklist > button {
    width: 80px;
    height: 30px;
    border-radius: 7px;
    font-size: 0.5rem;
  }
}

.buttonAtualizar {
  position: absolute;
  background: var(--green-200) !important;
  left: 0;
  bottom: -10px;
  height: 22px !important;
  width: 100px !important;
  border-radius: 8px !important;
}

.buttonAtualizar:hover:not([disabled]) {
  background: var(--green-300) !important;
}

.buttonAtualizarNotCefx {
  position: absolute;
  background: var(--green-200) !important;
  left: 0;
  bottom: -10px;
  height: 22px !important;
  width: 100px !important;
  border-radius: 8px !important;
  outline: none;
  border: none;
  color: #fff;
  font-size: 0.75rem;
  cursor: pointer;
}

.buttonAtualizarNotCefx:hover:not([disabled]) {
  background: var(--green-300) !important;
}

.container_emptyList {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 10rem; */
  color: var(--green-500);
}

.containerAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid var(--gray-100);
}

/* .containerAvatar img {
  height: 117%;
  object-fit: contain !important;
} */

.textLoadingCefx {
  text-align: center;
  margin-top: 30px;
  opacity: 0.9;
  color: #535353;
  height: auto;
  animation: loading 2s infinite linear;
}

@keyframes loading {
  0% {
    color: #fff;
  }
  50% {
    color: #666;
  }
  100% {
    color: #fff;
  }
}

.containerTextUserFalse {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  opacity: 0.8;
  margin-top: 30px;
}

.containerTextUserFalse p {
  margin-top: 0;
  color: #c80000;
}

.ListMobile {
  display: none;
}

.ModalVideo {
  display: none !important;
}

.container_buttons_loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.opcaoSelect {
  width: 250px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}

.opcaoSelect select {
  width: 250px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.opcaoSelect select:hover,
.opcaoSelect select:focus {
  border-color: #7fcec3;
  box-shadow: 0 0 2px #7fcec3;
}

.opcaoSelect select option {
  padding: 10px;
}

.opcaoSelect select:hover {
  border-color: #888; /* Cor da borda ao passar o mouse */
}

.opcaoSelect select::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

.opcaoSelect select::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.opcaoSelect select::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.opcaoSelect select::-webkit-scrollbar-thumb:hover {
  background: #7fcec3;
}

.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-left: 2px solid #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* @media screen and (max-width: 424px ) {
  .container_filterAndVideo{
    display: none;
  }
  .ListMobile{
    display: block !important;
  }
}
@media screen and (max-width: 1368px ) {
  .container_filterAndVideo{
    display: none;
  }
  .ListMobile{
    display: block !important;
  }
} */

@media (max-width: 880px) {
  .wrapper {
    margin-top: 83px;
    overflow: hidden;
  }

  .wrapperTableAndNavigation {
    height: calc(100vh - 105px);
    overflow: auto;
  }

  .wrapperTableAndNavigation::-webkit-scrollbar {
    width: 1px;
    background-color: none;
  }

  .wrapperTableAndNavigation::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 5px;
  }

  .wrapperTableAndNavigation::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
  }

  .containerButtonsChecklist {
    margin-bottom: 1.5rem;
  }

  .opcaoSelect {
    display: none;
  }

  .containerTextUserFalse {
    margin-top: 2rem;
    font-size: 1rem;
  }

  .buttonAtualizar {
    bottom: -40px;
    height: 22px !important;
  }

  .containerButtonAtualizar {
    padding-top: 0px;
  }

  .buttonAtualizarNotCefx {
    margin-top: 1rem !imp;
  }
}

@media (max-width: 600px) {
  /* .containerAvatar img {
    width: 40px !important;
    height: 50px !important;
  } */
  .control {
    display: none;
  }
}

@media (max-width: 280px) {
  .container {
    padding: 0px !important;
  }
}
