.header {
  position: relative;
  display: flex;
  gap: 12px;
  padding: 20px;
  border-bottom: 1px solid var(--gray-600);
}

.containerIcon {
  width: 44px;
  height: 44px;
  background: var(--gray-600);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerTitle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.containerTitle h2 {
  font-size: 1.25rem;
}

.containerTitle span {
  font-size: 0.875rem;
}

.iconClose {
  position: absolute;
  top: 20px;
  right: 20px;
  color: var(--gray-400);
  cursor: pointer;
}

.iconClose:hover {
  color: red;
}

.containerButtons {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  margin-top: auto;
  width: 100%;
  align-self: flex-end;
}

.containerButtons > button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 48px;
  border-radius: 24px;
  color: #fff;
  outline: none;
  border: 0;
  cursor: pointer;
}

.containerButtons > button:first-child {
  background: transparent;
  outline: none;
  border: 1px solid var(--gray-200);
}

.containerButtons > button:first-child:hover {
  background: var(--gray-200);
  border: none;
  color: #000;
}

.containerButtons > button:last-child {
  background: var(--green-500);
}

.containerButtons > button:last-child:hover {
  background: var(--green-600);
}

