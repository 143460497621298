.mainContainer {
  top: 5.25rem;
  right: 1%;

  background: #fff;
}

.topContainer {
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.topTitle {
  color: var(--neutral-colors-black, var(--neutral-colors-black, #000));
  font-size: 20px;
  font-weight: 600;
  padding: 00px 10px 20px 0px;
}
.alignSelfEnd {
  align-self: flex-end;
}
.topCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: var(
    --neutral-colors-gray-gray-500,
    var(--neutral-colors-gray-gray-500, #8e8e8e)
  );
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.topDiv {
  display: flex;
  gap: 24px;
  cursor: pointer;
}
.topAllMore {
  color: #007e69;
  padding-bottom: 24px;
  text-align: right;
  font-size: 16px;
  font-weight: 600;
}

.cardContainer {
  border-radius: 16px;

  gap: 50px;
  padding: 14px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 0.1rem 0;
}
.cardContainer:hover {
  background-color: #f8f8f8;
  transition: all 0.3s;
}
.cardContainerflex {
  display: flex;
  gap: 50px;
  padding: 14px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 0.1rem 0;
}
.cardMainText {
  font-size: 1rem !important;
  font-weight: 600;
  color: #37404e;
}
.cardSubText {
  color: #7b889c;
  font-size: 0.75rem !important;
}
.iconContainer {
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 56px;
  width: 40px;
  height: 40px;
}
.cardLeftContainer {
  border-radius: 16px;
  display: flex;
  gap: 10px;

  align-items: center;
}
.textsubtitle {
  color: #666;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
