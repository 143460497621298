.react_modal_Send_Request {
  max-width: 500px;
  width: 100%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  border: none;
  outline: none;
  padding: 20px;
}

.xIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 10%;
  left: 90%;
  cursor: pointer;
}

.xIconWrapper svg {
  display: flex;
  width: 20px;
  height: 20px;
  color: #8e8e8e;
}

.xIconWrapper svg:hover {
  color: #000000;
  transform: scale(1.1);
}

.shareIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.shareIconWrapper .circle {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 100%;
  border: 8px solid var(--neutral-colors-gray-river-gray-river-50, #ebeef3);
  background: var(--neutral-colors-gray-river-gray-river-100, #ced5de);
}

.textContainer {
  display: grid;
  place-content: center;
  text-align: center;
  gap: 12px;
  margin-top: 16px;
  margin-bottom: 40px;
  color: var(
    --neutral-colors-gray-river-gray-river-600,
    var(--neutral-colors-gray-river-gray-river-600, #57667a)
  );
  font-size: 14px;
}

.textContainer h2 {
  color: var(
    --neutral-colors-gray-gray-900,
    var(--neutral-colors-gray-gray-900, #151515)
  );
  font-size: 18px;
  font-weight: 600;
}

.wrapper_input {
  position: relative;
  width: 100%;
  padding-bottom: 32px;
  display: grid;
}

.error {
  position: absolute;
  bottom: 15px;
  left: 6px;
  color: var(--red-900);
  display: inline-block;
  font-size: 0.65rem;
  text-align: left;
}

.label {
  color: #535353;
  font-weight: 600;
  letter-spacing: 0.64px;
  position: absolute;
  margin-left: 1.5rem;
  margin-top: -0.625rem;
  background-color: white;
  z-index: 10;
}

.label span {
  color: #8e8e8e;
}

.requestName {
  border-radius: 12px;
  border: 1px solid #afafaf;
  background-color: #fff;
  padding: 1.25rem 1rem;
  width: 100%;
  color: #535353;
  font-family: "SegoeUi";
  font-size: 1rem;
}

.requestName:focus-within {
  border: 1px solid #afafaf;
  box-shadow: none;
  outline: none;
}

.buttonsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.containerButtonEmail,
.containerButtonWhatsapp {
  width: 100%;
}
.containerButtonWhatsapp > button,
.containerButtonEmail > button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  border-radius: 20px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "Segoe UI Variable" !important;
  font-weight: 600 !important;
  height: 100% !important;
  transition: 200ms !important;
  cursor: pointer !important;
  padding: 10px 20px !important;
  width: 100% !important;
}

.containerButtonWhatsapp > button {
  background: var(--serodonto-primary-color-green-800, #006e5a) !important;
  color: #fff !important;
  border: none;
}

.containerButtonWhatsapp > button:hover {
  background-color: var(--colors-green800) !important;
  opacity: 0.9 !important;
}

.containerButtonEmail > button {
  color: #007e69 !important;
  background-color: var(
    --serodonto-primary-color-green-100,
    #ade1da
  ) !important;
  border: none !important;
}

.containerButtonEmail > button:hover {
  background-color: var(--colors-green200) !important;
}
