.react_modal_deleteModelo {
  max-width: 400px;
  height: 236px;
  width: 100%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  border: none;
  outline: none;
  padding: 20px;
}

.xIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 10%;
  left: 90%;
  cursor: pointer;
}

.xIconWrapper svg {
  display: flex;
  width: 20px;
  height: 20px;
  color: #8e8e8e;
}

.xIconWrapper svg:hover {
  color: #000000;
  transform: scale(1.1);
}

.trashIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.trashIconWrapper .circle {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 100%;
  border: 8px solid var(--support-colors-error-red-50, #fde7e6);
  background: var(--support-colors-error-red-100, #ffc8b9);
}

.textContainer {
  display: grid;
  place-content: center;
  text-align: center;
  gap: 5px;
  margin-top: 16px;
  margin-bottom: 20px;
  color: var(
    --neutral-colors-gray-river-gray-river-600,
    var(--neutral-colors-gray-river-gray-river-600, #57667a)
  );
  font-size: 14px;
}

.textContainer h2 {
  color: var(
    --neutral-colors-gray-gray-900,
    var(--neutral-colors-gray-gray-900, #151515)
  );
  font-size: 18px;
  font-weight: 600;
}

.buttonsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.containerButtonCancel,
.containerButtonDelete {
  width: 100%;
}
.containerButtonCancel > button,
.containerButtonDelete > button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  border-radius: 20px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "Segoe UI Variable" !important;
  font-weight: 600 !important;
  height: 100% !important;
  transition: 200ms !important;
  cursor: pointer !important;
  padding: 10px 20px !important;
  width: 100% !important;
}

.containerButtonCancel > button {
  border: 1px solid var(--neutral-colors-gray-gray-500, #8e8e8e) !important;
  background: var(--neutral-colors-white, #fff) !important;
  color: var(--neutral-colors-gray-gray-500, #8e8e8e) !important;
}

.containerButtonCancel > button:hover:not([disabeld]) {
  background-color: var(--neutral-colors-gray-gray-500, #8e8e8e) !important;
  color: #fff !important;
}

.containerButtonDelete > button {
  background: var(--support-colors-error-red-50, #fde7e6) !important;
  border: none !important;
  color: #c80000 !important;
}

.containerButtonDelete > button:hover:not([disabled]) {
  background-color: #c80000 !important;
  color: white !important;
}

.containerButtonDelete > button:disabled {
  cursor: not-allowed !important;
}
