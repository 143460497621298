.wrapper {
  margin-top: 24px;
}

.wrapper .tableAssessment {
  width: 100%;
  border-collapse: collapse;
}

.wrapper .tableAssessment th {
  width: 100%;
  background-color: var(--gray-50);
  padding: 1rem;
  text-align: left;
  font-size: 0.875rem;
  color: var(--gray-river-700);
}

.wrapper .tableAssessment th:first-child {
  border-radius: 12px 0 0 12px;
}

.wrapper .tableAssessment th:last-child {
  border-radius: 0 12px 12px 0;
}

.wrapper .tableAssessment td {
  width: 100%;
  background-color: #fff;
  padding: 1rem 1rem;
  text-align: left;
  font-size: 0.875rem;
  color: var(--gray-700);
  border-bottom: 0.5px solid var(--gray-100);
}

.tableAssessment .tableAssessment_thead tr {
  display: grid;
  grid-template-columns: 2fr 0.5fr;
}

.tableAssessment .tableAssessment_tbody tr {
  display: grid;
  grid-template-columns: 2fr 0.5fr;
}
