.badgeContainer {
  padding: 0.25rem 0.625rem;
  display: flex;
  gap: 0.5rem;
  width: fit-content;
  border-radius: 1.5rem;
  align-items: center;
}
.XMark {
  cursor: pointer;
}
.subject {
  font-weight: 600;
}
.badge1 {
  background-color: #efefef;
}
.badge2 {
  background-color: #f8c393;
}
.title1 {
  color: #151515;
}
.title2 {
  color: #854d0b;
}
