.containerImagensCefx {
  max-height: calc(100vh - 240px);
  overflow-y: auto;
  padding-right: 12px;
  padding-bottom: 50px;
}

.containerImagensCefx::-webkit-scrollbar {
  width: 7px;
  background-color: var(--gray-600);
  border-radius: 5px;
}

.containerImagensCefx::-webkit-scrollbar-thumb {
  background-color: var(--gray-200);
  border-radius: 5px;
}

.containerImagensCefx p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.containerImagescefx {
  width: 100%;
  max-height: calc(100vh - 370px) !important;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  gap: 5px;
  padding-bottom: 10px;
  padding-right: 5px;
  overflow-y: scroll;
}

.containerImagescefx::-webkit-scrollbar {
  width: 4px;
  background-color: var(--gray-600);
  border-radius: 5px;
}

.containerImagescefx::-webkit-scrollbar-thumb {
  background-color: var(--gray-200);
  border-radius: 5px;
}

.containerImagescefx > div {
  position: relative;
  height: 129px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--gray-700);
  overflow: hidden;
}

.containerImagescefx > div:hover {
  border: 1px solid #2ebbaa;
}

.containerImagescefx > div .inputCheckbox {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 16px;
  height: 16px;
  clip-path: circle(62%);
  z-index: 2;
}

.containerImagescefx > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.linearGradient {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0)
  );
  display: flex;
  padding-left: 14px;
  padding-bottom: 14px;
  align-items: flex-end;
  font-size: 0.875rem;
  border-radius: 0 0 5px 5px;
}

.linearGradient span {
  position: absolute;
  width: 100%;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  color: var(--gray-100);
  font-weight: 600;
  font-size: 0.75rem;
}

.containerInputOpcoesBasicas {
  display: flex;
  flex-direction: column;
}

.containerInputOpcoesBasicas > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.containerInputOpcoesBasicas span {
  display: block;
  margin-bottom: 12px;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  aling-items: center;
  justify-content: space-between;
}

.containerInputOpcoesBasicas span span {
  color: white;
}

.containerInputOpcoesBasicas {
  margin-bottom: 10px;
}

.containerInputOpcoesBasicas:last-child {
  margin-bottom: 5px;
}

.containerInputOpcoesBasicas input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  width: 100%;
  height: 0.5em;
  position: relative;
  border-radius: 5px;
  background-color: var(--gray-700);
  outline: none;
}

.containerInputOpcoesBasicas > input::-webkit-slider-thumb {
  background: #fff !important;
}

.containerSettings {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--gray-600);
}

.containerSettings > p {
  text-align: left;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

.containerSettings .containerOnOff {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
}

.containerSettings .containerOnOff button {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: var(--gray-700);
  color: #fff;
  outline: none;
  border: 0;
  cursor: pointer;
}

.containerSettings .containerOnOff button:hover:not([disabled]) {
  background-color: var(--gray-600);
}

.containerSettings .containerOnOff button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.containerSettings .containerOnOff div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.containerButtonSicronizarImagens {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin-top: 16px;
}

.containerButtonSicronizarImagens > button {
  width: 100%;
  height: 35px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
}

.containerButtonSicronizarImagens > button:hover {
  opacity: 0.9;
}
