.wraperNavigation {
  margin-top: 10px;
}

.wraperNavigation nav ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: space-between;
  list-style: none;
  /* padding-bottom: 10px; */
}

.wraperNavigation nav ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-bottom: 2px solid var(--gray-200);
  cursor: pointer;
  color: #8e8e8e;
}

.wraperNavigation nav ul li:hover {
  color: #007e69 !important;
  font-weight: 600;
}

.active {
  font-weight: 600;
  color: #007e69 !important;
  border-bottom: 2px solid #007e69 !important;
}

@media (max-width: 880px) {
  .wraperNavigation nav ul li {
    font-size: 0.75rem;
  }
}
