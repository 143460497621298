.wrapper {
  padding-top: 1rem;
}

.title {
  font-size: 14px;
  color: rgb(123, 136, 156);
  font-weight: 700;
  margin-bottom: 12px;
  text-transform: uppercase;
  text-align: left;
}

.containerModelsCard {
  display: grid;
  gap: 12px;
  margin-top: 12px;
}

.titleNoModel {
  width: 80%;
  font-size: 0.875rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 6px;
  color: rgb(175, 175, 175);
}

.modelCard {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid var(--neutral-colors-gray-gray-200, #e4e4e4);
  box-shadow: 2px 2px 16px -4px rgba(34, 34, 34, 0.2);

  color: var(--neutral-colors-gray-river-gray-river-600, #57667a);
  font-family: "Segoe UI Variable", sans-serif;
  font-size: 14px;
  font-weight: 600;

  position: relative;
}

.modelCardIcons {
  display: flex;
  gap: 8px;
}

.modelCard:hover {
  cursor: pointer;
  background-color: #e4e4e4;
}

.favoriteCard {
  border: 1px solid var(--serodonto-primary-color-green-300-main, #2ebbaa);
  background: var(--serodonto-primary-color-green-50, #def3f1);
}

.modelCardIcons > svg:hover {
  cursor: pointer;
}

.modelCardIcons > svg:first-child:hover {
  fill: #007e69;
}

.containerButtonViewModels {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 16px;
}

.containerButtonViewModels > button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  border-radius: 20px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "Segoe UI Variable" !important;
  font-weight: 600 !important;
  height: 100% !important;
  color: #007e69 !important;
  background-color: transparent !important;
  border: 1px solid var(--green-700) !important;
  transition: 200ms !important;
  cursor: pointer !important;
  padding: 10px 20px !important;
}

.containerButtonViewModels > button:hover {
  background-color: var(--green-900) !important;
  border: 1px solid white !important;
  color: #fff !important;
}

.optionCard {
  position: absolute;
  top: -50%;
  left: 60%;
  max-width: 220px;
  width: 100%;
  z-index: 1;
  background-color: white;
  border-radius: 16px 0px 16px 16px;
  overflow: hidden;
}

.optionCard > div {
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 5px 24px;
  cursor: pointer;
}

.optionCard > div:hover {
  background: var(--neutral-colors-gray-gray-50, #f8f8f8);
}

.optionCard svg {
  fill: #64748b;
}
