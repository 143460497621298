.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.wrapper svg {
  position: absolute;
  top: -16px;
  right: -5px;
  width: 18px;
  color: var(--gray-400);
}

.containerTitleTooltip {
  padding: 8px;
}

.containerTitleTooltip p {
    display: block;
    color: var(--gray-700);
    text-align: center;
}

.loading_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.loading_container h2 {
  font-size: 1.125rem;
  margin-top: 10px;
}

p {
  font-size: 0.875rem;
  margin-top: 5px;
  text-align: center;
}

.loading_spinne {
  border: 5px solid var(--gray-500);
  border-left: 5px solid #fff;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
