.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 20px;
  border-bottom: 1px solid var(--gray-100);
  -webkit-box-shadow: 0px 0px 20px 3px rgba(1, 1, 1, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(1, 1, 1, 0.2);
}

.header h2 {
  font-size: 1.25rem;
  color: var(--gray-800);
}

.header svg {
  width: 1.5rem;
  cursor: pointer;
  color: var(--gray-400);
}

.header svg:hover {
  color: var(--gray-500);
}

.containerIconClose {
  position: absolute;
  top: 16px;
  right: 16px;
}

.containerSelect {
  display: flex;
  justify-content: space-between;
}

.containerButtons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 16px;
  padding-right: 140px;
  padding-right: 20px;
}

.containerButtons > button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 110px;
  height: 40px;
  padding: 5px 12px;
  outline: none;
  border: 0;
  border-radius: 14px;
  background: var(--green-100);
  color: var(--green-700);
  font-size: 14px;
  cursor: pointer;
}

.containerButtons button:not(:disabled):hover {
  opacity: 0.8;
}

.containerButtons button svg {
  width: 16px;
  color: var(--green-700);
}

.container_content {
  position: relative;
  min-width: 100%;
  height: calc(100vh - 330px);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  overflow-y: auto;
  padding-bottom: 15px;
  padding-right: 14px;
}

.container_content::-webkit-scrollbar {
  width: 4px;
  background-color: var(--gray-600);
  border-radius: 5px;
}

.container_content::-webkit-scrollbar-thumb {
  background-color: #2ebbaa;
  border-radius: 5px;
}

.wrapperImg {
  margin-top: 24px;
}

.wrapper_imagens {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.wrapper_active {
  padding: 12px;
}

.iconClose {
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 1;
  cursor: pointer;
  z-index: 2;
  border-radius: 6px;
  display: block;
  align-items: center;
  justify-content: center;
  background: var(--gray-500);
}

.iconClose:hover svg {
  color: red;
}

.containerContentImage {
  padding: 18px 16px;
  border-top: 1px solid var(--gray-200);
  text-align: center;
  background: #fff;
  border-radius: 0 0 16px 16px;
}

.containerContentImage strong {
  color: var(--gray-800);
}

.containerContentImage p {
  font-size: 0.75rem;
  color: var(--gray-500);
}

.container_buttons_loading_imprimir {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinne_imprimir {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.wrapper_text {
  display: flex;
  align-items: center;
  gap: 12px;
}

.wrapper_text > div p {
  color: var(--gray-400);
}

.wrapper_text > div h2 {
  color: #fff;
}

.containerAnaliseIcon {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #666;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.containerAnalyze {
  width: 220px;
  margin-top: 70px;
  border-left: 2px solid var(--gray-600);
}

.containerAnalyze p {
  font-size: 1.1rem;
  color: #fff;
  margin-top: -40px;
  padding-bottom: 14px;
  font-weight: 600;
  text-align: center;
}

.containerCheckbox {
  margin-top: 0px;
  padding-left: 10px;
  max-height: 300px; 
  overflow-y: auto;
  margin-right: 5px;
}

.containerCheckbox::-webkit-scrollbar {
  width: 4px;
  background-color: var(--gray-600);
  border-radius: 5px;
}

.containerCheckbox::-webkit-scrollbar-thumb {
  background-color: #2ebbaa;
  border-radius: 5px;
}

.containerSearchResult {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  gap: 12px;
}

.containerSearchResult span {
  font-size: 0.75rem;
  color: #fff;
}

.inputCheckbox:checked {
  background-color: green;
}

.spanNotResult {
  display: block;
  font-size: 0.85rem;
  text-align: center;
}

.noResult {
  position: absolute;
  color: var(--gray-200);
}

.wrapperImage {
  position: relative;
  cursor: pointer;
}

.wrapperImage:hover .iconClose {
  display: flex;
}

.wrapperImage:hover .containerContentImage strong {
  color: var(--green-300);
}

.containerImage_active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 270px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.57) 0%,
    rgba(0, 0, 0, 0.11) 37.19%,
    rgba(0, 0, 0, 0) 80%
  );
  border-radius: 16px;
}

.containerImage {
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 16px 16px 0 0;
}

.containerImage .imgPdf {
  height: 200px;
  border: 1px solid var(--gray-200);
  border-radius: 4px;
  object-fit: contain;
  object-position: top;
}

.textPdfsGerados {
  position: relative;
  text-align: center;
  margin-bottom: 1.5rem;
  color: var(--gray-300);
  font-size: 0.875rem;
}

.textPdfsGerados::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -8px;
  transform: translateX(-50%);
  height: 1.5px;
  width: 100px;
  background-color: var(--gray-500);
}

.loading_container {
  position: absolute;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading_spinne {
  border: 3px solid #fff;
  border-left: 3px solid #007e69;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.loading_container p {
  font-size: 0.875rem;
  margin-top: 10px;
  color: var(--gray-200);
  font-weight: 600;
  border: 1px solid transparent;
}

.buttonAnalyzes {
  width: 130px;
  height: 35px;
  border-radius: 56px;
  background: var(--green-100);
  color: var(--green-700);
  font-weight: 600;
  cursor: pointer;
  border: 0;
  font-size: 0.75rem;

  margin: 20px auto;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.buttonAnalyzes:hover {
  opacity: 0.9;
}

.buttonAnalyzes svg {
  width: 15px;
  height: 15px;
}
