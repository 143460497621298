.points {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
}

.switch {
  display: flex;
  aling-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}

.switch p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
}

.switch > div {
  display: flex;
  aling-items: center;
  justify-content: center;
  gap: 12px;
}

.wrapper {
  max-height: calc(100vh - 330px);
  overflow-y: auto;
  padding-right: 12px;
  padding-bottom: 50px;
}

.wrapper::-webkit-scrollbar {
  width: 7px;
  background-color: var(--gray-600);
  border-radius: 5px;
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: var(--gray-200);
  border-radius: 5px;
}

.containerSettings p {
  text-align: left;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
}

.containerSettings .containerOnOff {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
}

.containerSettings .containerOnOff button {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: var(--gray-700);
  color: #fff;
  outline: none;
  border: 0;
  cursor: pointer;
}

.containerSettings .containerOnOff button:hover:not([disabled]) {
  background-color: var(--gray-600);
}

.containerSettings .containerOnOff button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.containerSettings .containerOnOff div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.containerSettings .containerButtonsPoints {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  border-bottom: 1px solid var(--gray-600);
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.containerSettings .containerButtonsPoints > button {
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 12px 0;
  outline: none;
  border: 0;
  background-color: var(--gray-700);
  color: #fff;
  border-radius: 12px;
  cursor: pointer;
}

.containerSettings .containerButtonsPoints > button:hover {
  background-color: var(--gray-600);
}

.containerInputOpcoesBasicas {
  display: flex;
  flex-direction: column;
}

.containerInputOpcoesBasicas > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.containerInputOpcoesBasicas span {
  display: block;
  margin-bottom: 12px;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  aling-items: center;
  justify-content: space-between;
}

.containerInputOpcoesBasicas span span {
  color: white;
}

.containerInputOpcoesBasicas {
  margin-bottom: 10px;
}

.containerInputOpcoesBasicas:last-child {
  margin-bottom: 5px;
}

.containerInputOpcoesBasicas input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  width: 100%;
  height: 0.5em;
  position: relative;
  border-radius: 5px;
  background-color: var(--gray-700);
  outline: none;
}

.containerInputOpcoesBasicas > input::-webkit-slider-thumb {
  background: #fff !important;
}

.wrapper_pontoSelecionado {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 8px 20px;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 16px;
  background: #535353;
}

.semPontoText {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;
  opacity: 0.7;
}

.containerIconsArrows {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-right: 18px;
}

.containerIconsArrows > button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5px 0;
  border-radius: 16px;
  outline: none;
  border: 2px solid transparent;
  cursor: pointer;
  font-size: 0.875rem;
  color: #000;
  background: #fff;
  transition: all 200ms;
}

.containerIconsArrows > button:hover:not([disabled]) {
  background: transparent;
  color: #ffc107;
  border: 2px solid #ffc107;
}

.containerIconsArrows > button:disabled {
  cursor: not-allowed;
}

.point {
  position: relative;
  width: 100%;
  height: 23px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.point:hover {
  background: rgb(211, 119, 48) !important;
}

.point span {
  font-size: 0.75rem;
}

.pointSelected {
  padding: 16px 10px;
}

.pointSelected header {
  width: 100%;
  display: flex;
  grid-template-columns: repeat(3, 1r);
  gap: 30px;
}

.pointSelected header > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pointSelected header > div span {
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.pointSelected header > div > .pointTooltip {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #ff6f00; */
  font-size: 10px;
  color: #fff;
}

.tableContainer {
  width: 100%;
  overflo-w: hidden;
}

.tableContainer::-webkit-scrollbar {
  width: 4px;
  background-color: var(--gray-600);
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: var(--gray-200);
  border-radius: 5px;
}

.table {
  border-collapse: collapse;
}

.table th {
  width: 100%;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 700;
}

.table thead tr {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 1fr;
}

.table tbody tr {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 1fr;
  padding-top: 1rem;
  font-size: 0.75rem;
}

.table tbody tr td {
  padding-bottom: 12px;
}

.table .tdID {
  display: flex;
  gap: 4px;
}

.table .tdID .point {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.containerExampleOfPoints {
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  gap: 5px;
  justify-items: start;
  padding: 10px 0;
  margin: 10px 0;
  border-top: 1px solid var(--gray-600);
  border-bottom: 1px solid var(--gray-600);
}

.wrapper_tiposDePontos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.wrapper_tiposDePontos span {
  font-size: 0.75rem;
}

.pointExample {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
