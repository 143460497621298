.wrapper {
  padding-top: 2rem;
}

.title {
  font-size: 14px;
  color: rgb(123, 136, 156);
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  padding-left: 7px;
}

.wrapper_input {
  position: relative;
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--gray-200);
  padding-bottom: 2rem;
}

.label {
  color: #535353;
  font-weight: 600;
  letter-spacing: 0.64px;
  position: absolute;
  margin-left: 1.5rem;
  margin-top: -0.625rem;
  background-color: white;
  z-index: 10;
}

.searchInput {
  border-radius: 12px;
  border: 1px solid #afafaf;
  background-color: #fff;
  padding: 1.25rem 1rem;
  width: 100%;
  color: #535353;
  font-family: SegoeUi;
  font-size: 1rem;
}
.searchInput::placeholder {
  color: #535353;
  font-weight: 600;
  display: flex;
}

.containerPacient {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formatOptionLabel {
  display: flex;
  align-items: center;
  gap: 8px;

  font-family: "Segoe UI Variable", sans-serif;
  font-size: 16px;
  color: var(
    --neutral-colors-gray-gray-700,
    var(--neutral-colors-gray-gray-700, #535353)
  );
}

.formatOptionLabel.patient img {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  object-fit: contain;
}

.patientDetails {
  padding: 0px 8px;
  width: 100%;
  /* border: 1px solid red; */
}

.patientDetailsHeader {
  display: flex;
  gap: 21px;
  align-items: center;
  padding-bottom: 1.2rem;
  border-bottom: 1px solid var(--gray-200);
  position: relative;
}

.patientDetailsHeader span {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 58.297px;
  height: 58.297px;
  border-radius: 50%;
  overflow: hidden;
  background: #fff;
  border: 1px solid #ccc;
}

.patientDetailsHeader span img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover !important;
}

.patientDetailsHeader > div {
  display: grid;
  gap: 5px;
}

.patientDetailsHeader h3 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: var(
    --neutral-colors-gray-river-gray-river-900,
    var(--neutral-colors-gray-river-gray-river-900, #252c37)
  );
}

.patientDetailsHeader h4 {
  color: var(
    --neutral-colors-gray-river-gray-river-300,
    var(--neutral-colors-gray-river-gray-river-300, #919daf)
  );
}

.patientDetailsHeader p {
  text-align: left;
}

.patientDetailsBody {
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
}

.patientDetailsBody div {
  display: grid;
  gap: 8px;
}

.patientDetailsBody h6 {
  color: var(
    --neutral-colors-gray-gray-500,
    var(--neutral-colors-gray-gray-500, #8e8e8e)
  );
  font-size: 16px;
}

.patientDetailsBody h5 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.64px;
  color: var(
    --neutral-colors-gray-gray-800,
    var(--neutral-colors-gray-gray-800, #353535)
  );
}

.circle {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 10.139px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 16.475px;
  background-color: var(--gray-river-50);
  position: absolute;
  bottom: 28%;
  left: -2%;
}

.circle:hover {
  background-color: #def3f1;
}

.containerButtonAddSignature {
  display: flex;
  align-items: center;
}

.containerButtonAddSignature > button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  border-radius: 20px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "Segoe UI Variable" !important;
  font-weight: 600 !important;
  height: 100% !important;
  color: #007e69 !important;
  background-color: transparent !important;
  border: 1px solid var(--green-700) !important;
  transition: 200ms !important;
  cursor: pointer !important;
  padding: 10px 20px !important;
}

.containerButtonAddSignature > button:hover {
  background-color: var(--green-900) !important;
  border: 1px solid white !important;
  color: #fff !important;
}

@media (max-width: 1024px) {
  .wrapper_input {
    border: none;
  }

  .title {
    display: none;
  }
}
.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  padding: 0px 16px;
}

.containerButtonPrint > button,
.containerButtonSave > button,
.containerButtonSend > button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  border-radius: 20px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "Segoe UI Variable" !important;
  font-weight: 600 !important;
  height: 100% !important;
  transition: 200ms !important;
  cursor: pointer !important;
  padding: 10px 20px !important;
}

.containerButtonPrint > button {
  color: #007e69 !important;
  background-color: transparent !important;
  border: 1px solid var(--green-700) !important;
}

.containerButtonPrint > button:hover {
  background-color: var(--green-900) !important;
  border: none !important;
  color: #fff !important;
}

.containerButtonSave > button {
  color: #007e69 !important;
  background-color: var(
    --serodonto-primary-color-green-100,
    #ade1da
  ) !important;
  border: none !important;
}

.containerButtonSave > button:hover {
  background-color: var(--colors-green200) !important;
}

.containerButtonSend > button {
  background: var(--serodonto-primary-color-green-800, #006e5a) !important;
  color: #fff !important;
  border: none;
}

.containerButtonSend > button:hover {
  background-color: var(--colors-green800) !important;
}

.containerLastButtons {
  display: flex;
  gap: 11px;
}
