.containerLoading {
  width: 100%;
  height: 100vh;
  padding: 20px;
  position: relative;
}

.loading_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading_spinne {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #007e69;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  /* margin-top: -200px; */
}

.loading_container p {
  font-size: 0.875rem;
  margin-top: 10px;
  color: var(--gray-600);
  font-weight: 600;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.containerImage {
  width: 100%;
  height: 72px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  justify-content: left;
}

@media (max-width: 720px) {
  .containerImage {
    position: absolute;
    justify-content: center;
    top: 16px;
  }
}
