.aboutCard {
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 0.875rem;
  border: 1px solid #d3d3d3;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: space-between;
  width: 100%;
}
.aboutMe {
  font-weight: 600;
  color: #151515;
}
.othersTitle {
  color: #535353;
  font-weight: 600;
  font-size: 1rem;
}
.aboutMeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}
.badgeContainer {
  display: flex;
  gap: 0.75rem;
  margin-top: 0.75rem;
}
.descriptionContainer {
  flex: 1;
}
.others {
  margin-top: 1rem;
}
