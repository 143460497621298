.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 20px;
  border-bottom: 1px solid var(--gray-100);
  -webkit-box-shadow: 0px 0px 20px 3px rgba(1, 1, 1, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(1, 1, 1, 0.2);
}

.header h2 {
  font-size: 1.25rem;
  color: var(--gray-800);
}

.header svg {
  cursor: pointer;
  color: var(--gray-400);
}

.header svg:hover {
  color: var(--gray-500);
}

.containerIconDragAndDrop {
  position: absolute;
  top: 5px;
  left: 50%;
  width: 25px;
}

.containerSelect {
  position: relative;
  margin-bottom: 2px;
}

.containerSelect h2 {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.25rem;
  color: #fff;
}

.containerSelect h2:hover {
  color: var(--gray-900);
  cursor: pointer;
}

.containerSelect p {
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--gray-400);
}

.wrapperHeader {
  display: flex;
  align-items: center;
  gap: 12px;
}

.containerAnaliseIcon {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #666;
}

.showSelect {
  width: 200px;
  height: auto;
  max-height: 250px;
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 8px;
  top: 15px;
  padding: 8px;
  border-radius: 12px;
  background: var(--gray-600);
  background: var(--Neutral-Colors-Gray-Gray-600, #666);
}

.showSelect::-webkit-scrollbar {
  width: 4px;
  background-color: none;
  border-radius: 5px;
}

.showSelect::-webkit-scrollbar-thumb {
  background-color: var(--gray-200);;
  border-radius: 5px;
}

.showSelect p {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 0;
  padding-left: 16px;
  border-radius: 12px;
  cursor: pointer;
}

.showSelect p:hover {
  color: #2ebbaa;
}

.showSelect p.analyzeSelected {
  background: var(--gray-500);
  color: #fff !important;
}

/* table */
.tableContainer {
  max-height: calc(100vh - 220px);
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 2px;
  margin-right: 2px;
  margin-top: 2px;
}

.tableContainer::-webkit-scrollbar {
  width: 6px;
  background-color: none;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: var(--gray-200);
  border-radius: 5px;
}

.tableModalOfFactors {
  width: 100%;
  height: 90% !important;
  margin-top: 16px;
  border-collapse: collapse;
}

.tableModalOfFactors th {
  width: 100%;
  background: var(--gray-700);
  border-bottom: 1px solid #666;
  padding: 1rem;
  padding-top: 0;
  text-align: left;
  font-size: 0.875rem;
  color: #fff;
}

.tableModalOfFactors td {
  width: 100%;
  background: var(--gray-700);
  padding: 8px 1rem;
  text-align: left;
  font-size: 0.875rem;
  color: #fff;
  border-bottom: 0.5px solid var(--gray-100);
}

.tableModalOfFactors thead tr {
  display: grid;
  grid-template-columns: 1.2fr repeat(3, 0.5fr) 0.8fr;
}

.tableModalOfFactors tbody tr {
  display: grid;
  grid-template-columns: 1.2fr repeat(3, 0.5fr) 0.8fr;
}

.react_modal_overlay_2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.loading_container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading_spinne {
  border: 3px solid #fff;
  border-left: 3px solid #007e69;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-top: 20%;
}

.loading_container p {
  font-size: 0.875rem;
  margin-top: 10px;
  color: var(--gray-200);
  font-weight: 600;
  border: 1px solid transparent;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
