.container {
    width: 100%;
}

.textButtons {
    display: grid;
}

.textButton, .textButtonHeader {
    display: grid;
    gap: 12px;
}

.textButtonHeader {
    padding: 0px 20px;
}

.textButton {
    border-bottom: 0.6px solid #E4E4E4;
    padding: 21px 20px;
    overflow-x: scroll;
}

.Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.textContent {
    display: flex;
    align-items: center;
    gap: 4px;
}

.textButton h3 {
    font-size: 14px;
    font-weight: 600;
    color: #919DAF;
}

.textButtonHeader h2 {
    font-size: 18px;
    font-weight: 600;
}

.textButton p {
    font-size: 16px;
    color: #353535;
}

.textButtonHeader small {
    font-size: 12px;
    color: #666666;
}

footer {
    height: 32px;
}

form {
    display: grid;
    gap: 18px;
}

.profile {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid #e4e4e4;
    box-shadow: 2px 2px 4px 0px rgba(176, 183, 208, 0.2);
    padding: 14px;
}

.profile > div {
    display: flex;
    flex-direction: column;
}

.profile > div strong {
    font-size: 1rem;
    color: var(--gray-river-800);
    font-weight: 600;
}

.profile > div span {
    font-size: 0.75rem;
    color: var(--gray-river-400);
    font-weight: 700;
}

.imagePerfilPaciente {
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    object-fit: cover;
}

.input_group {
    display: grid;
    gap: 18px;
    padding: 24px 0px;
    border-bottom: 0.6px solid #E4E4E4;
}

.input_wrapper {
    padding: 0 20px;
}

.container_buttons {
    display: grid;
    padding: 21px 20px;
    gap: 12px;
}

.container_buttons button {
    padding: 16px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.container_buttons button:nth-child(2) {
    color: #007E69 !important;
    border: 1px solid #007E69;
}

.shareCard {
    display: grid;
    gap: 19px;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid var(--neutral-colors-gray-gray-200, #E4E4E4);
}

.shareCardInfo {
    display: flex;
    align-items: center;
    gap: 8px;
}

.shareCardtext {
    font-weight: 600;
    width: 60%;
}

.shareCardtext h4 {
    font-size: 12px;
}

.shareCardtext p {
    font-size: 10px;
}

.select:valid ~ .label_select {
    font-size: 0.875rem;
    font-weight: 600;
    background: #ffffff;
}

.select:focus ~ .label_select {
    color: red;
    font-size: 1rem;
    font-weight: 600;
    background: #ffffff;
}

.select:placeholder-shown ~ .label_select {
    font-size: 0.875rem;
    font-weight: 600;
    background: #ffffff;
}

.containerImages {
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
}

.containerImages .imagem {
    width: 85px;
    height: 85px;
    border-radius: 12px;
    position: relative;
    background: #000;
}

.containerImages .imagem img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 12px;
    object-fit: contain;
}

.imagePfd {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 12px;
    border: 1px solid var(--gray-100);
    display: flex;
    align-items: center;
    justify-content: center;
}

.imagePfd svg {
    width: 50%;
    height: 50%;
}

.errorImage {
    position: absolute;
    left: 100px;
    color: red;
    font-weight: 600;
    font-size: 0.625rem;
}

.containerImages .imagem .containerIconClose {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: #FFC8B9;
    padding: 3px;
    cursor: pointer;
}

.containerImages .imagem .containerIconClose svg {
    color: #C80000;
}

.circle {
    display: flex;
    align-items: center;
    border-radius: 56px;
    background: #F8F8F8;
    padding: 8px;
}

.addImagesButton {
    width: 85px;
    height: 85px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #00AC98;
    background: #DEF3F1;
    cursor: pointer;
}

.addImagesButton:hover {
    opacity: .6;
}