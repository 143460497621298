.buttonsContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  margin-top: 50px;
}

.containerButtonPrint > button,
.containerButtonSave > button,
.containerButtonSend > button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  border-radius: 20px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "Segoe UI Variable" !important;
  font-weight: 600 !important;
  height: 100% !important;
  transition: 200ms !important;
  cursor: pointer !important;
  padding: 10px 20px !important;
}

.containerButtonPrint > button {
  min-width: 130px;
  position: absolute;
  left: 0;
  top: 0;
  color: #007e69 !important;
  background-color: transparent !important;
  border: 1px solid var(--green-700) !important;
}

button[disabled="disabled"] {
  opacity: 0.8 !important;
  cursor: not-allowed !important;
}

.containerButtonPrint > button:hover:not([disabled]) {
  background-color: var(--green-900) !important;
  border: 1px solid white !important;
  color: #fff !important;
}

.containerButtonSave > button {
  color: #007e69 !important;
  background-color: var(
    --serodonto-primary-color-green-100,
    #ade1da
  ) !important;
  border: none !important;
}

.containerButtonSave > button:hover {
  background-color: var(--colors-green200) !important;
}

.containerButtonSend > button {
  background: var(--green-800) !important;
  color: #fff !important;
  border: none;
  margin-left: 11px;
}

.containerButtonSend > button:hover:not([disabled]) {
  background-color: var(--green-900) !important;
}

.containerLastButtons {
  display: flex;
  /* gap: 11px; */
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #006e5a;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
