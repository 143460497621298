.wrapperNav {
  margin-top: 16px;
}

.wrapperNav nav ul {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-content: space-between;
}

.wrapperNav nav ul li {
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid var(--gray-200);
  transition: 200ms;
  white-space: nowrap;
}

.wrapperNav nav ul li:hover {
  cursor: pointer;
  border-bottom: 2px solid var(--green-700);
  color: var(--green-700);
}

.wrapperNav nav ul li.active {
  color: var(--green-700);
  font-weight: bold;
  border-bottom: 2px solid var(--green-700);
}

.wrapperNav nav ul li {
  color: var(--gray-700);
}

.inputCheckbox {
  border: 1px solid #ccc;
  width: 22px;
  height: 22px;
  border-radius: 8px;
  clip-path: circle(62%);
}

.wrapper_input {
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 80px;
  padding: 8px 16px;
  display: flex;
  max-width: 539px;
}

.searchIcon {
  z-index: 10;
}
.searchInput {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 80px;
  padding: 0 16px;
  padding-left: 44px;
  position: absolute;
  outline: none;
  border: none;
  background-color: #efefef;
}