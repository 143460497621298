.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.containerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.containerImage {
  position: absolute;
  top: 16px;
  left: 16px;
}

.containerText {
  padding-top: 30px;
  color: var(--neutral-colors-gray-river-gray-river-800, #37404e);

  font-size: 2.25rem;
  font-weight: 700;
  line-height: normal;
}
.containerSubText {
  color: var(--neutral-colors-gray-river-gray-river-800, #37404e);
  text-align: center;
  padding-top: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}
.containerImg {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
}

.ContainerButton {
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.buttonMore {
  align-items: center;
  background-color: #ebeef3;
  border: none;
}
.buttonMore:hover {
  border: 1px solid #007e69;
}

@media (max-width: 880px) {
  .containerImage {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  .containerContent {
    padding: 0;
    gap: 0rem;
  }

  .containerText {
    font-size: 1.5rem;
  }

  .containerSubText {
    font-size: 0.8rem;
  }

  .containerImg {
    display: flex;
    padding-top: 0px;
    padding-bottom: 0px;
    align-items: center;
    justify-content: center;
  }

  .containerImg img {
    max-width: 80%;
  }

  .containerButton > button {
    min-width: 200px;
  }
}
