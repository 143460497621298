.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.header h1 {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--gray-river-900);
}

.content {
  display: flex;
  align-items: center;
  gap: 24px;
}

.content img {
  max-height: 70px;
}
.avatarContainer {
  cursor: pointer;
}

.logoImage {
  max-width: 190px;
}

.heading img {
  display: none;
}

@media (max-width: 880px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 999;
    background: #fff;
    border-bottom: 1px solid var(--gray-200);
  }

  .containerNotification {
    display: none;
  }
  
  .logoImage {
    display: none;
  }

  .mobileMenu {
    display: flex !important;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 8px;
  }

  .heading span {
    display: none;
  }

  .heading img {
    height: 30px;
    display: block;
  }
}
.mobileMenu {
  display: none;
}

@media print {
  .header {
    display: none;
  }
}
