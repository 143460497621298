.footer {
  display: flex;
  padding: 24px;
  justify-content: space-between;
}

.footer_background {
  display: flex;
  padding: 24px;
  justify-content: space-between;
  background-color: var(--green-50);
  padding: 24px 132px;
}

.content {
  display: flex;
  align-items: center;
  gap: 24px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.logo img {
  width: 120px;
}

.contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: normal;
  gap: 7px;
}

.contact svg {
  width: 30px;
  height: 30px;
}

.contactAbout {
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
}

.contactNumber {
  color: #666;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: normal;
}

.footerBar {
  width: 1px;
  height: 90%;
  background-color: #919daf;
}

.containerLogos {
  /* display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid red; */
}

@media (max-width: 1024px) {
  .footer {
    flex-direction: column;
    padding: 11px;
    gap: 9px;
  }

  .footer_background {
    height: auto;
    flex-direction: column;
    padding: 16px 24px;
    gap: 16px;
  }

  .contactAbout,
  .contactNumber {
    font-size: 0.75rem;
  }

  .contact svg {
    width: 15px;
    height: 15px;
  }

  /* .containerLogos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    border: 1px solid red;
  } */
}
