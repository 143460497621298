.footerWrapper {
  width: 100%;
  position: relative;
  margin-top: auto;
  z-index: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.footerWrapper_cefx {
  width: 100%;
  position: relative;
  margin-top: auto;
  z-index: 2px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding-bottom: 4px;
  gap: 18px;
  background: var(--gray-800);
}

.footerWrapper p,
.footerWrapper_cefx p {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.75rem;
  color: var(--gray-300);
}

.containerLogos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.containerLogos img {
  display: flex;
  align-items: center;
  width: 80px;
  height: auto;
}

