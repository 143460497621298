.wrapper {
  width: 100% !important;
  height: calc(100vh - 110px) !important;
  padding-bottom: 32px;
}

.container {
  width: 100% !important;
  height: 100%;
  display: flex;
  gap: 16px;
  padding: 0 16px;
}

.containerPaginationExames {
  position: fixed;
  padding: 10px 0;
  bottom: 0;
  left: 50%;
  background-color: white;
}

.containerPaginationExames > ul {
  padding: 0 !important;
  margin: 0 !important;
}

.title {
  padding-left: -44px;
}

.container_filterAndVideo {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.container_filterAndVideo .containerPagination {
  margin-top: auto;
}

.container_emptyList {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 10rem; */
  color: var(--green-500);
}

.containerAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid var(--gray-100);
}

.containerAvatar img {
  height: 117%;
  object-fit: contain !important;
}

.containerTextUserFalse {
  height: 100%;
  height: calc(100vh - 234px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  color: #afafaf;
}

.pagination {
  width: 100%;
  padding: 0 40px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  list-style: none;
  gap: 2px;
}

.pagination li {
  width: 40px;
  height: 40px;
}

.pagination a {
  width: 100%;
  height: 100%;
  outline: none;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pagination .active {
  background: var(--green-600);
  color: #fff;
  border-radius: 8px;
}

.loading_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  justify-content: center;
}

.loading_container p {
  font-size: 0.875rem;
  margin-top: 10px;
  color: var(--gray-600);
  font-weight: 600;
}

.loading_spinne {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #007e69;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* @media screen and (max-width: 424px ) {
    .container_filterAndVideo{
      display: none;
    }
    .ListMobile{
      display: block !important;
    }
  }
  @media screen and (max-width: 1368px ) {
    .container_filterAndVideo{
      display: none;
    }
    .ListMobile{
      display: block !important;
    }
  } */
@media (max-width: 600px) {
  .containerAvatar img {
    width: 40px !important;
    height: 50px !important;
  }
  .control {
    display: none;
  }
}

@media (max-width: 280px) {
  .container {
    padding: 0px !important;
  }
}
