.wrapper {
  margin-top: 24px;
}

.wrapper .tableShare {
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
}

.wrapper .tableShare th {
  width: 100%;
  background-color: var(--gray-50);
  padding: 1rem;
  text-align: left;
  font-size: 0.875rem;
  color: var(--gray-river-700);
}

.wrapper .tableShare th:first-child {
  border-radius: 12px 0 0 12px;
}

.wrapper .tableShare th:last-child {
  border-radius: 0 12px 12px 0;
}

.wrapper .tableShare td {
  width: 100%;
  background-color: #fff;
  padding: 1rem 1rem;
  text-align: left;
  font-size: 0.875rem;
  color: var(--gray-700);
  border-bottom: 0.5px solid var(--gray-100);
}

.wrapper .tableShare th:last-child {
  text-align: center;
}

.wrapper .tableShare td:last-child {
  text-align: center;
}

.tableDataOption {
  display: flex;
  flex-direction: columns;
}

.tableShare .tableShare_thead tr {
  display: grid;
  grid-template-columns: 0.8fr 1fr 1.4fr 0.8fr;
}

.tableShare .tableShare_tbody tr {
  display: grid;
  grid-template-columns: 0.8fr 1fr 1.4fr 0.8fr;
}

.containerButton_end {
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
}

.iconButton {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--green-600);
  color: white; /* Define a cor do texto como branco. */

  cursor: pointer; /* Adiciona um cursor de mão para indicar que é clicável. */
  display: flex; /* Torna o conteúdo flexível para alinhar verticalmente. */
  align-items: center; /* Alinha verticalmente o conteúdo no centro. */
}

.allButton {
  display: flex;
  justify-content: end;
}
.containerButton_share {
  padding-bottom: 10px;
  height: 40px;
}
.containerIcons_icon {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--green-600);
}
.containerButton_share > button {
  width: 100%;
  height: 100%;
  background-color: var(--green-700);
  color: #fff !important;
}

.containerButton_share > button:hover {
  background-color: var(--green-900) !important;
  color: #fff !important;
}

.text {
  text-align: center;
  margin-top: 2rem;
  font-size: 0.875rem;
  color: var(--gray-400);
}

@media (max-width: 684px) {
  .tableShare tr {
    grid-template-columns: 1fr 1fr 1fr !important;
    place-content: center;
  }

  .tableShare_thead th {
    display: flex;
    align-self: center;
    justify-content: center;
  }

  .tableShare_tbody td:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #353535;
    font-weight: 600;
  }

  .tableShare_tbody td:last-child {
    text-align: left !important;
  }

  .tableShare td {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
