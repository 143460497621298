.wrapper {
  width: 100%;
  height: 100%;
  min-height: auto;
  max-height: auto;
  display: flex;
  justify-content: space-between;
  background: #000;
  color: #fff;
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.main {
  width: 100%;
  height: auto;
  min-height: auto;
  background: #000;
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.containerImage {
  width: 100%;
  min-height: calc(100vh - 88px);
  height: auto;
}

.containerImage .canvas {
  width: 100%;
  height: 100%;
  display: block;
}

.containerSelectedImagens {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 88px);
  max-height: calc(100% - 88px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 40px;
  padding: 40px;
}

.containerSelectedImagens img {
  width: 100%;
  height: auto;
  max-height: 600px;
  object-fit: cover;
}

.singleImage {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: auto;
  min-height: calc(100vh - 88px);
  max-height: calc(100vh - 88px);
  justify-content: center;
  gap: 40px;
  padding: 40px;
}

.singleImage img {
  display: block;
  width: 100%;
  height: auto;
  min-height: 100%;
  max-height: calc(100vh - 155px);
  max-height: auto;
  object-fit: contain;
}

.imageSelectedToView {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

@media (max-width: 1280px) {
  .canvasteste {
    top: calc(100% - 60px);
  }
}
