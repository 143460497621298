.title {
  font-size: 1.25rem;
  color: #000;
}

.icon_close {
  width: 20px;
  position: absolute;
  right: 16px;
  top: 16px;
  color: #8e8e8e;
  cursor: pointer;
}

.text_error {
  position: absolute;
  display: inline-block;
  text-align: left;
  margin-left: 1px;
  margin-top: 2px;
  font-size: 0.75rem;
  color: var(--red-900);
}

.container_inputs {
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.container_select {
  position: relative;
}

.label_select {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--gray-700);
  position: absolute;
  bottom: 41px;
  left: 16px;
  background: #fff;
  z-index: 999;
}

.select:valid ~ .label_select {
  font-size: 0.875rem;
  font-weight: 600;
  background: #ffffff;
}

.select:focus ~ .label_select {
  color: red;
  font-size: 1rem;
  font-weight: 600;
  background: #ffffff;
}

.select:placeholder-shown ~ .label_select {
  font-size: 0.875rem;
  font-weight: 600;
  background: #ffffff;
}

.container_buttons {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 60px;
  gap: 20px;
}

.container_buttons > button {
  min-width: 176px;
  max-height: 40px;
}
.subTitle {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.buttonMore {
  color: #8e8e8e;
  border: 1px solid #007e69;
}
.buttonMore:hover {
  border: 1px solid #007e69;
}
.progress {
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
