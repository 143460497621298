.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #535353;
  margin-top: 1.5rem;
  text-align: center;
}
.subtitle {
  color: #535353;
  font-size: 1.25rem;
  margin-top: 1rem;
}
.vid {
  cursor: pointer;
  width: 720px;
  height: 360px;
}
@media (max-width: 1000px) {
  .vidContainer {
    width: 100%;
  }
  .vid {
    width: 100%;
    height: 20rem;
  }
}
