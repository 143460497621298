.containerNoResults span {
  font-size: 0.75rem;
  color: var(--gray-500);
}

.containerAnalyzes p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.containerAnalyzes .analyzes {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.containerAnalyzes .analyzes .container_analyzes_data {
  height: auto;
  min-height: auto;
  max-height: calc(100vh - 405px) !important;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 5px;
  padding-right: 5px;
}

.containerAnalyzes .analyzes .container_analyzes_data::-webkit-scrollbar {
  width: 4px;
  background-color: var(--gray-600);
  border-radius: 5px;
}

.containerAnalyzes .analyzes .container_analyzes_data::-webkit-scrollbar-thumb {
  background-color: var(--gray-200);
  border-radius: 5px;
}

.containerAnalyzes .analyzes .analyzes_data {
  width: 100%;
  border: 2px solid var(--gray-800);
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  background: rgba(83, 83, 83, 0.16);
  color: #fff;

  display: flex;
  alingn-items: center;
  justify-content: space-between;
}

.containerAnalyzes .analyzes .analyzes_data:hover {
  background: rgba(83, 83, 83, 0.32);
}

.containerAnalyzes .analyzes .analyzes_data svg {
  width: 20px;
  height: 20px;
}

.containerTextTracado {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 40px;
  margin-bottom: 16px;
}

.containerTextTracado span {
  font-size: 0.875rem;
  text-align: center;
  color: var(--gray-400);
  color: #f09500;
}

.containerTextTracado > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
}

.containerTextTracado > div button {
  padding: 5px 10px;
  border: 0;
  outline: nome;
  border-radius: 8px;
  font-size: 0.875rem;
  cursor: pointer;
  background: var(--green-100);
  color: var(--green-700);
  font-weight: 600;
}

.containerTextTracado > div button:hover {
  opacity: 0.9;
}

.containerIconDragAndDrop {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.containerIconDragAndDrop svg {
  transform: rotate(-0.25turn);
  cursor: grab;
}

.containerAnalyzes .containerButtonAnalyzes {
  position: relative;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
}

.containerAnalyzes .containerButtonAnalyzes .buttonAnalyzes {
  width: 240px;
  height: 40px;
  border-radius: 56px;
  background: var(--green-100);
  color: var(--green-700);
  font-weight: 600;
  cursor: pointer;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.containerAnalyzes .containerButtonAnalyzes .buttonAnalyzes:hover {
  opacity: 0.9;
}

.containerAnalyzes .containerButtonAnalyzes .buttonAnalyzes svg {
  width: 20px;
  height: 20px;
}

.container_buttons_loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
