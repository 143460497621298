.wrapper {
  width: 100%;
  padding-left: 250px;
  padding-right: 150px;
}

.userHeader {
  display: flex;
  align-items: center;
  gap: 31px;
  width: 100%;
  margin-bottom: 52px;
}

.container_form {
  margin: 0 auto;
  max-width: 1300px;
}

.userText p {
  text-align: left;
}

.userProfileImage {
  width: 200px;
  height: 200px;
  border-radius: 100%;
}

.containerInput {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.containerCpf {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 10px;
}

.loading_container {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinne {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #007e69;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container_buttons_loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_buttons_loading .loading_spinne {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.headerStep1 {
  display: flex;
  height: 5vh;
  gap: 15px;
}

.headerStep1 > div {
  width: 200px;
}

.formStep1 {
  position: absolute;
  margin-top: 5%;
  width: 88vh;
  height: 60vh;
}

.formStep1 p {
  font-size: 0.875rem;
}

.containerUserSenha {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.container_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  padding-bottom: 24px;
}

.buttonCancelar {
  width: 172px;
  height: 52px;
}

.buttonCancelar > button {
  width: 100%;
  height: 100%;
  color: #8e8e8e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonCancelar > button svg {
  width: 14px;
}

.buttonContinuar {
  width: 172px;
  height: 52px;
}

.buttonContinuar > button {
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonContinuar > button svg {
  width: 14px;
}

.buttonToJump {
  max-width: 75%;
  margin: 0 auto;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

@media (max-width: 1440px) {
  .formStep1 {
    margin-top: 7%;
  }
}

@media (max-width: 880px) {
  .wrapper {
    width: 100%;
    padding: 0 20px;
    margin-top: 70px;
  }

  .userHeader {
    gap: 15px;
    margin-top: 38px;
  }

  .userHeader p {
    font-size: 0.75rem;
  }

  .iconClose {
    top: 0.5rem;
    right: 0.5rem;
    max-width: 25px;
    cursor: pointer;
    color: var(--gray-500);
  }

  .headerForm svg {
    display: none;
  }

  .headerStep1 {
    gap: 5px;
  }

  .headerStep1 > div {
    width: auto;
  }

  .headerStep1 > div p {
    font-size: 0.625rem;
    width: 100px;
  }

  .containerInput {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .container_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0px;
    padding-bottom: 24px;
    gap: 5px;
  }

  .buttonCancelar > button,
  .buttonContinuar > button {
    display: flex;
    aling-items: center;
    justify: center;
    /* border-radius: 30px !important; */
  }

  .buttonCancelar > button svg,
  .buttonContinuar > button svg {
    display: block;
    width: 16px;
  }

  .container_buttons {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    max-width: 90vw !important;
  }
}

.containerInputs {
  display: flex;
  gap: 10px;
  margin-bottom: 26px;
}

.containerInputs > .wrapperInput {
  display: grid;
  grid-template-columns: 200px 200px;
  gap: 8px;
}

.formStep2 {
  position: absolute;
  margin-top: 5%;
  width: 88vh;
  height: auto;
}

.containerUserSenha {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.container_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  padding-bottom: 24px;
}

.buttonCancelar {
  width: 172px;
  height: 52px;
}

.buttonCancelar > button {
  width: 100%;
  height: 100%;
  color: #8e8e8e;
  display: flex;
  aling-items: center;
  justify-content: center;
}

.buttonCancelar > button svg {
  width: 14px;
}

.buttonContinuar {
  width: 172px;
  height: 52px;
}

.buttonContinuar > button {
  width: 100%;
  height: 100%;
  color: #fff;
}

.buttonContinuar > button svg {
  width: 14px;
}

@media (max-width: 640px) {
  .iconClose {
    top: 0.5rem;
    right: 0.5rem;
    max-width: 25px;
    cursor: pointer;
    color: var(--gray-500);
  }

  .headerForm svg {
    display: none;
  }

  .headerStep2 {
    gap: 5px;
  }

  .headerStep2 > div {
    width: auto;
  }

  .headerStep2 > div p {
    font-size: 0.625rem;
    width: 100px;
  }

  .formStep2 {
    position: absolute;
    margin-top: 60px;
    width: 100%;
    height: 60vh;
    padding: 0 10px;
  }

  .formStep2 p {
    font-size: 0.875rem;
  }

  .containerInputs {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 26px;
  }

  .containerInputs > .wrapperInput {
    display: flex;
    gap: 8px;
  }

  .container_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0px;
    padding-bottom: 24px;
    gap: 5px;
  }

  .buttonCancelar > button,
  .buttonContinuar > button {
    display: flex;
    aling-items: center;
    justify: center;
    /* border-radius: 30px !important; */
  }

  .buttonCancelar > button svg,
  .buttonContinuar > button svg {
    display: block;
    width: 16px;
  }
}
