.containerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 90px;
  gap: 3rem;
}
.containerText {
  padding-top: 30px;
  color: var(--neutral-colors-gray-river-gray-river-800, #37404e);

  font-size: 2.25rem;
  font-weight: 700;
  line-height: normal;
}
.containerSubText {
  color: var(--neutral-colors-gray-river-gray-river-800, #37404e);
  text-align: center;
  padding-top: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}
.containerImg {
  display: flex;
  padding-top: 48px;
  padding-bottom: 48px;
  align-items: center;
  justify-content: center;
}
.ContainerButton {
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.buttonMore {
  align-items: center;
  background-color: #ebeef3;
  border: none;
}
.buttonMore:hover {
  border: 1px solid #007e69;
}
