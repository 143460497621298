.wrapper {
  width: 100%;
  margin-top: 16px;
}

.wrapper nav ul {
  width: 100.8%;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
  padding-left: 5px;
}

.wrapper nav ul li {
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid var(--gray-600);
  transition: 200ms;
}

.wrapper nav ul li:hover {
  cursor: pointer;
  border-bottom: 2px solid var(--green-100);
}

.wrapper nav ul li.active {
  color: red;
  font-weight: bold;
  border-bottom: 2px solid var(--green-100);
}

.wrapper nav ul li span {
  color: var(--gray-400);
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  transition: 200ms;
}

.wrapper nav ul li:hover span {
  color: var(--green-100);
}

.wrapper nav ul li.active span {
  color: var(--green-100);
}

.active {
  color: green;
  font-weight: bold;
  border-bottom: 2px solid var(--green-700);
}

.content {
  padding: 16px;
}
