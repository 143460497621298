.wrapper {
  margin-top: 24px;
  padding-bottom: 42px;
}

.containerTop {
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.image {
  object-fit: contain;
  background: #000;
  border-radius: 100%;
}

.containerTop > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.containerTop > div span {
  color: var(--gray-800);
}

.container_inputSelectAll {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputSelectAll {
  width: 28px;
  height: 28px;
}

.containerButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.containerButtons_select_show {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: end;
  gap: 16px;
  animation: fadeInAndScaleUp 300ms;
}

@keyframes fadeInAndScaleUp {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.containerButton_delete > button,
.containerButton_download > button,
.containerButton_share > button,
.containerButton_addImage > button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  border-radius: 20px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: "Segoe UI Variable" !important;
  font-weight: 600 !important;
}

.containerButton_delete {
  width: 140px;
  height: 40px;
}

.containerButton_delete > button {
  width: 100%;
  height: 100%;
  border: 1px solid var(--red-900);
  color: var(--red-900) !important;
}

.containerButton_delete > button:hover {
  background: var(--red-900) !important;
  border: o;
  color: #fff !important;
}

.containerButton_download {
  width: 140px;
  height: 40px;
}

.containerButton_download > button {
  width: 100%;
  height: 100%;
  border: 1px solid var(--blue-800);
  color: var(--blue-800) !important;
}

.containerButton_download > button:hover {
  border: 0;
  color: #fff !important;
  background: var(--blue-800) !important;
}

.containerButton_share {
  width: 195px;
  height: 40px;
}

.containerButton_share > button {
  width: 100%;
  height: 100%;
  background-color: var(--green-700);
  color: #fff !important;
}

.containerButton_share > button:hover {
  background-color: var(--green-900) !important;
  color: #fff !important;
}

.containerButton_addImage {
  width: 235px;
  height: 40px;
}

.containerButton_addImage > button {
  width: 100% !important;
  height: 100% !important;
  color: #007e69 !important;
  background-color: transparent !important;
  border: 1px solid var(--green-700) !important;
  transition: 200ms !important;
}

.containerButton_addImage > button:hover {
  background-color: var(--green-900) !important;
  border: none !important;
  color: #fff !important;
}

.containerButton_addImage svg {
  width: 20px !important;
  height: 20px !important;
}

.wrapperImg {
  margin-top: 24px;
}

.wrapper_imagens {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.containerImage {
  position: relative;
  border-radius: 16px;
  padding: 12px;
}

.containerImage_active {
  background-color: var(--green-50);
}

.containerImage img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 16px;
  object-fit: contain;
}

.inputCheckbox {
  position: absolute;
  border: 1px solid #fff;
  width: 22px;
  height: 22px;
  top: 20px;
  left: 20px;
  border-radius: 8px;
  clip-path: circle(62%);
  z-index: 2;
}

.imageFloatingText {
  display: grid;
  text-align: left;
  padding-left: 15px;
  border-radius: 10px;
  position: absolute;
  color: white;
  gap: 0px;
  bottom: 10px;
  font-weight: 600;
  z-index: 2;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.imageTitle {
  font-size: 18px;
}

.imageDate {
  font-size: 12px;
  text-align: left;
}

.floatingGlass {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  border-radius: 16px !important;
  height: 100%;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 64px;
  height: 64px;
  margin-top: -32px;
  margin-left: -32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader div {
  width: 9px;
  height: 9px;
  background-color: var(--green-200);
  border-radius: 50%;
  margin: 4px;
  animation: loader-animation 1.2s infinite ease-in-out;
}

.loader div:nth-child(1) {
  animation-delay: -0.24s;
}
.loader div:nth-child(2) {
  animation-delay: -0.12s;
}
.loader div:nth-child(3) {
  animation-delay: 0s;
}
.loader div:nth-child(4) {
  animation-delay: 0.12s;
}

@keyframes loader-animation {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@media (max-width: 684px) {
  .container_inputSelectAll {
    display: flex;
    gap: 8px;
    font-size: 15px;
  }

  .inputSelectAll {
    width: 18px;
    height: 18px;
  }

  .containerButton_addImage,
  .containerButton_delete,
  .containerButton_download,
  .containerButton_share {
    max-width: 40px !important;
    max-height: 40px !important;
  }

  .buttonMobile {
    /* display: flex !important;
    align-items: center !important;
    justify-content: center; */
    padding: 1px !important;
    border-radius: 56px !important;
    font-size: 20px !important;
    min-width: 0px !important;
  }

  .wrapper_imagens {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  .imageFloatingText {
    top: 58%;
  }

  .imageTitle {
    font-size: 16px;
  }

  .imageDate {
    text-align: left;
    font-size: 12px;
  }

  .floatingGlass {
    top: 0;
    width: 100% !important;
  }
}

@media (max-width: 537px) {
  .floatingGlass {
    top: 0;
    width: 100% !important;
  }

  .imageFloatingText {
    backdrop-filter: blur(0.7px);
  }
}

@media (max-width: 400px) {
  .containerImage img {
    max-width: 168px;
  }

  .floatingGlass {
    top: 0;
    width: 100% !important;
  }

  .imageTitle {
    font-size: 14px;
  }
}

@media (max-width: 300px) {
  .floatingGlass {
    top: 0;
    width: 100% !important;
  }

  .imageTitle {
    font-size: 12px;
  }

  .imageDate {
    font-size: 10px !important;
    text-align: left;
  }

  .containerImage img {
    height: 168px;
  }

  .containerTop,
  .containerButtons_select_show,
  .containerButtons {
    gap: 5px !important;
  }

  .container_inputSelectAll {
    font-size: 12px;
  }

  .container_inputSelectAll input {
    height: 14px;
  }
}
