.wrapper {
  position: relative;
  min-height: calc(100vh - 105px);
  padding: 0 16px;
  /* border: 1px solid red; */
}

.containerNopacient {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -90px;
  font-size: 1rem;
  color: #007e69;
  font-weight: 600;
}

.loading_container {
  position: absolute;
  top: calc(50% - 90px);
  left: 50%;
  transform: translateX(-78%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading_spinne {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #007e69;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-top: 0%;
}

.loading_container p {
  font-size: 0.875rem;
  margin-top: 10px;
  color: var(--gray-600);
  font-weight: 600;
}

.loading_containerImagens {
  height: calc(100vh - 330px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading_containerImagens p {
  font-size: 0.875rem;
  margin-top: 10px;
  color: var(--gray-600);
  font-weight: 600;
}

.loading_spinneImagens {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #007e69;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-top: 0%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 880px) {
  .wrapper {
    margin-top: 100px;
  }

  .loading_container {
    position: absolute;
    top: calc(50% - 100px);
    left: 50%;
    transform: translateX(-50%);
    margin-right: 0px;
  }

  .loading_container p {
    font-size: 0.75rem;
    text-align: center;
  }

  .loading_spinne {
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-left: 3px solid #007e69;
    width: 40px;
    height: 40px;
  }

  .loading_containerImagens p {
    font-size: 0.75rem;
    text-align: center;
  }

  .loading_spinneImagens {
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-left: 3px solid #007e69;
    width: 40px;
    height: 40px;
  }
}
